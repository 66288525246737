import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useSortable } from "@dnd-kit/sortable";

import ApprovedIcon from "./ApprovedIconInverted.svg";
import PendingIcon from "./PendingIcon.svg";
import SubmittedIcon from "./SubmittedIcon.svg";
import RejectedIcon from "./RejectedIcon.svg";
import { timeDeltaToHours } from "./helpers";

import ApprovalDialogWindow from "./ApprovalDialogWindow";
import dragIconBlue from "../../../../assets/drag_icon_blue.svg";
import dragIconWhite from "../../../../assets/drag_icon_white.svg";
import { getTimesheetData, getMonthStatus } from "../../helpers";
import parseDate from "../HistoryCard/helpers";

export const useStylesApprovalCard = makeStyles((theme) => ({
  toggleButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    borderRadius: "8px",
    marginBottom: theme.spacing(1),
    transition: "background-color 0.3s",
    color: "#004170",
    border: "3px solid #004170",
    "&.approved": {
      backgroundColor: "#004170",
      color: theme.palette.common.white,
    },
  },
  nonSelectable: {
    userSelect: "none",
  },
}));

const statusIcons = {
  approved: ApprovedIcon,
  submitted: SubmittedIcon,
  rejected: RejectedIcon,
  pending: PendingIcon,
};

export const EmployeeApprovalCard = ({ employeeId, monthYear, fullName }) => {
  const classes = useStylesApprovalCard();
  const [timesheetStatus, setTimesheetStatus] = useState("Pending");
  const [totalHours, setTotalHours] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [timesheetData, setTimesheetData] = useState([]);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: employeeId });
  const style = transform
    ? { transform: `translate(${transform.x}px, ${transform.y}px)`, transition }
    : undefined;

  useEffect(() => {
    getMonthStatus(parseDate(monthYear), employeeId)
      .then((data) => setTimesheetStatus(data.month_status))
      .catch((error) => console.error(error));
  }, [monthYear, employeeId]);

  useEffect(() => {
    const total = timesheetData.reduce(
      (acc, item) => acc + timeDeltaToHours(item.duration),
      0
    );
    setTotalHours(Math.round(total * 100) / 100);
  }, [timesheetData]);

  const openDialogCallback = () => {
    setOpenDialog(true);
    getTimesheetData(setTimesheetData, monthYear, employeeId);
  };

  const closeDialogCallback = async () => {
    const status = await getMonthStatus(parseDate(monthYear), employeeId);
    setOpenDialog(false);
    setTimesheetStatus(status.month_status);
  };

  return (
    <>
      <div
        className={`${classes.toggleButton} ${
          timesheetStatus.toLowerCase() === "approved" ? "approved" : ""
        }`}
        onClick={openDialogCallback}
        style={{ cursor: "pointer", ...style }}
        ref={setNodeRef}
        {...attributes}
      >
        <div className="flexbox-wrapper-employee-card-approver">
          <img
            src={
              timesheetStatus.toLowerCase() === "approved"
                ? dragIconWhite
                : dragIconBlue
            }
            className="drag-icon"
            alt="drag-icon"
            {...listeners}
          />
          <div>
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              className={classes.nonSelectable}
            >
              {fullName}
            </Typography>
            <Typography variant="body2" className={classes.nonSelectable}>
              {monthYear}
            </Typography>
          </div>
        </div>
        <img
          src={statusIcons[timesheetStatus.toLowerCase()] || PendingIcon}
          alt="status-icon"
        />
      </div>

      <ApprovalDialogWindow
        employeeFullName={fullName}
        employeeId={employeeId}
        totalHours={totalHours}
        timesheetStatus={timesheetStatus}
        openDialog={openDialog}
        closeDialogCallback={() => closeDialogCallback()}
        timesheetData={timesheetData}
        setTimesheetData={setTimesheetData}
        monthYear={monthYear}
      />
    </>
  );
};

export default EmployeeApprovalCard;
