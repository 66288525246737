import React from "react";
import WarningIcon from "@mui/icons-material/Warning";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Tooltip } from "@mui/material";

function StatusChip({
  className,
  status,
  rejection_comment = null,
  is_edited = null,
  is_resubmitted = null,
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        gap: "20px",
      }}
    >
      <Tooltip title={rejection_comment}>
        <div className={`status-chip ${className}`}>{status}</div>
      </Tooltip>
      <div>
        {is_edited ? (
          <Tooltip title="This entry was previously rejected and has now been edited.">
            <WarningIcon />
          </Tooltip>
        ) : is_resubmitted ? (
          <Tooltip title="This entry was previously rejected and has now been resubmitted without any change.">
            <WarningAmberIcon />
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
}

export default StatusChip;
