import React from "react";
import binIcon from "../../../../assets/bin_icon_large_24_24.svg";
import arrowUpIcon from "../../../../assets/arrow_up_icon.svg";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import bulletPoint from "../../../../assets/bullet_point_cv.svg";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import {
  changeOtherExperienceObject,
  changeTextAreaFocused,
} from "../../../../features/otherExperience";
import { useDispatch } from "react-redux";
import { SavedInfoIndicator } from "../SavedItemIndicator";
import { saveChangeOtherSkill, saveIsOtherSkillIncluded } from "./saveActions";

function OtherExperience(props) {
  const dispatch = useDispatch();
  const [error, setError] = React.useState(false);
  const [retry, setRetry] = React.useState(null);

  return (
    <div style={{display: "flex", justifyContent:"center", alignItems: "center", marginTop: "8px"}}>
      <img
        src={bulletPoint}
        alt="bullet point"
        style={{
          scale: "0.8",
          marginLeft: "5px",
          marginRight: "12px",
          alignSelf: "flex-start",
          marginTop: "7px",
        }}
      />
      <OutlinedInput
        className="outlinedinput-dynamic-cv"
        value={props.experienceObject.skill}
        onClick={(e) => {
          dispatch(changeTextAreaFocused(false));
          const { target } = e;
          target.focus();
        }}
        onChange={(e) => {
          dispatch(
            changeOtherExperienceObject({
              id: props.experienceObject.id,
              property: "skill",
              value: e.target.value,
            })
          );
        }}
        onBlur={(e) => {
          dispatch(changeTextAreaFocused(true));
          saveChangeOtherSkill({
            id: props.experienceObject.id,
            value: e.target.value,
            savedSkills: props.savedSkills,
            setSavedSkills: props.setSavedSkills,
            dispatch,
            setError,
            setRetry,
          });
        }}
        sx={{ width: "90%" }}
        placeholder="write your other experience here ..."
        multiline
      />
      <div>
        {props.savedSkills.includes(props.experienceObject.id) && (
          <SavedInfoIndicator error={error} retry={retry} />
        )}
      </div>
      <Tooltip title="Move to Key Experiences" arrow>
        <IconButton
          style={{ marginTop: "-8px", maxHeight: "42px" }}
          onClick={() => {
            props.handleMove(props.experienceObject.id, props.experienceObject);
          }}
        >
          <img src={arrowUpIcon} alt="arrow up icon" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Include in CV" arrow>
        <Checkbox
          checked={props.experienceObject.isIncludedInCv}
          onChange={(e) => {
            saveIsOtherSkillIncluded({
              id: props.experienceObject.id,
              checked: e.target.checked,
              savedSkills: props.savedSkills,
              setSavedSkills: props.setSavedSkills,
              dispatch,
              setError,
              setRetry: (retryFunction) => {
                setRetry(() => retryFunction());
              },
            });
          }}
          style={{ maxHeight: "42px", marginTop: "-8px" }}
        />
      </Tooltip>
      <Tooltip title="Delete skill" arrow>
        <IconButton
          style={{ marginTop: "-8px", maxHeight: "42px" }}
          onClick={() => {
            props.handleDelete(props.experienceObject.id);
          }}
        >
          <img src={binIcon} alt="bin icon" />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default OtherExperience;
