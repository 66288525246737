import moment from "moment";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import React from "react";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import fr from "date-fns/locale/fr";
import { parse } from "date-fns";
import axiosInstance from "../../../../axios/axiosConfig";
import { useMaterialReactTable } from "material-react-table";
import { Box, IconButton, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import {
  addCheckedLeaveReq,
  handleApprovedCheckboxChange,
  handlePendingCheckboxChange,
  handleRejectedCheckboxChange,
  setDateValidationError,
  setDurationEditValidationError,
  setTriggerLeaveRequestDataFetch,
} from "../../../../features/leaveRequestSlice";
import StatusChip from "./StatusChip";
import Checkbox from "@mui/material/Checkbox";
import { selectClassName } from "../TimesheetDataView/helpers";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00-00-0000"
      inputRef={ref}
      onAccept={(value) => {
        onChange({ target: { name: props.name, value } });
      }}
      onBlur={() => {}}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const bgColor = (status) => {
  switch (status.trim()) {
    case "Approved":
      console.log("status is approved we return color");
      // return "rgba(0, 171, 80, 0.645)";
      return "#1B7656";
    case "Submitted":
      console.log("we return submitted color");
      // return "rgba(255, 145, 0, 0.795)";
      return "#716F69";
    //   break;
    case "Rejected":
      // return "rgba(246, 13, 13, 0.795)";
      return "#EA4335";
    //   break;
    case "Pending":
      // return "rgba(77, 77, 77, 0.339)";
      return "#FBBC04";
  }
};

export const tableColumns = (
  data,
  dispatch,
  durationValidationError,
  dateValidationError
) => {
  const columnsDefinition = [
    {
      header: "Date",
      accessorKey: "date",
      size: 20,
      sortingFn: "customDateOrdering",
      Edit: ({ cell, column, row, table }) => {
        const handleChange = (e) => {
          // check if error has already been set on date field
          if (dateValidationError) {
            // if date is now valid reset error
            if (moment(e.target.value, "DD-MM-YYYY", true).isValid()) {
              dispatch(setDateValidationError(false));
            }
          }
          row._valuesCache[column.id] = e.target.value;
        };

        return (
          <FormControl variant="standard">
            <InputLabel htmlFor="component-helper">Date</InputLabel>
            <Input
              value={row._valuesCache.date}
              error={dateValidationError}
              onBlur={(e) => {}}
              onChange={handleChange}
              name="textmask"
              id="formatted-text-mask-input"
              inputComponent={TextMaskCustom}
            />
            {dateValidationError && (
              <FormHelperText id="component-helper-text">
                Date must be in format dd-mm-yyyy
              </FormHelperText>
            )}
          </FormControl>
        );
      },
    },
    {
      header: "Status",
      accessorKey: "status",
      size: 20,
      enableEditing: false,
      Cell: ({ cell }) => (
        <StatusChip
          status={
            cell.getValue() === "Pending" ? "Not Submitted" : cell.getValue()
          }
          className={`${selectClassName(cell.getValue())}`}
          bg_color={bgColor(cell.getValue())}
        />
      ),
      Edit: ({ cell, column, row, table }) => {
        return (
          <TextField
            value={
              row._valuesCache.status === "Pending"
                ? "Not Submitted"
                : row._valuesCache.status
            }
            variant="standard"
            label="Status"
            disabled
          />
        );
      },
    },
    {
      header: "Type",
      accessorKey: "activity_type",
      size: 20,
      enableEditing: false,
    },
    {
      header: "Activity",
      accessorKey: "activity_name",
      size: 50,
      enableEditing: false,
    },
    {
      header: "Duration",
      accessorKey: "duration",
      enableEditing: true,
      size: 50,
      Edit: ({ cell, column, row, table }) => {
        const handleDurationChange = (newValue) => {
          let formattedDuration = moment(newValue).format("HH:mm:ss");

          // check if error has already been set on duration field
          if (durationValidationError) {
            // if date is now valid reset error
            if (moment(formattedDuration, "HH:mm:ss", true).isValid()) {
              dispatch(setDurationEditValidationError(false));
            }
          }
          if (formattedDuration !== "Invalid date") {
            // if (newValue) {
            row._valuesCache = {
              ...row._valuesCache,
              duration: formattedDuration,
            };
          } else {
            row._valuesCache = {
              ...row._valuesCache,
              duration: null,
            };
          }
        };

        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
            <TimePicker
              label="Duration"
              value={
                row._valuesCache.duration
                  ? parse(row._valuesCache.duration, "HH:mm:ss", new Date())
                  : null
              }
              onChange={handleDurationChange}
              renderInput={(params) => (
                <FormControl variant="standard" {...params}>
                  <InputLabel htmlFor="component-helper">Duration</InputLabel>
                  <Input {...params} error={durationValidationError} />
                  {durationValidationError && (
                    <FormHelperText id="component-helper-text">
                      Duration must be in format hh:mm
                    </FormHelperText>
                  )}
                </FormControl>
              )}
              disableOpenPicker={true}
              views={["hours", "minutes"]}
              format="hh:mm"
            />
          </LocalizationProvider>
        );
      },
      aggregationFn: (columnName, groupedValues) => {
        //grouped values is an array that contains the row values for the aggregated groups; note that duration is in string format HH:MM:SS
        //to aggregate it we will transform it to seconds, add all numbers and transform back to the format HH:MM:SS
        let totalHours = 0;
        let totalMinutes = 0;
        groupedValues.forEach((row) => {
          let durationArray = row.original.duration.split(":");
          totalHours += +durationArray[0];
          totalMinutes += +durationArray[1];
        });

        //tranform total minutes to hours
        let additionalHours = Math.floor(totalMinutes / 60);
        let remainingMinutes = totalMinutes - additionalHours * 60;

        // add additional hourse coming from total minutes
        totalHours += additionalHours;

        return `${String(totalHours).padStart(2, "0")}:${String(
          remainingMinutes
        ).padStart(2, "0")}`;
      },
      AggregatedCell: ({ cell }) => {
        return (
          <div>
            {" "}
            <span className="aggregated-total">Total:</span> {cell.getValue()}
          </div>
        );
      },

      Cell: ({ cell }) => (
        <Box
          component="span"
          sx={(theme) => ({
            borderRadius: "0.25rem",
            maxWidth: "9ch",
            p: "0.25rem",
            fontWeight: "bold",
          })}
        >
          {cell.getValue()?.slice(0, -3)}
        </Box>
      ),
    },
    {
      header: "Comment",
      accessorKey: "comment",
      enableEditing: true,
      enableColumnActions: false,
      enableSorting: false,
      muiEditTextFieldProps: ({ cell }) => ({
        onChange: (event) => {
          console.info(event);
        },
      }),
    },
  ];

  return columnsDefinition;
};

export const useReactTable = (
  columns,
  data,
  dispatch,
  leaveRequestState,
  enableRowActions = true,
  showEditButton = true,
  showDeleteButton = true,
  showIncludeCheckbox = false,
  checkboxState = []
) => {
  //   const classes = useStyles();

  // DELETE action
  const deleteCallback = (table, row) => {
    const data = {
      timesheet_entry_id: row.original.timesheet_entry_id,
    };

    axiosInstance
      .delete(
        `/timemate/employeetimesheet/?timesheet_entry_id=${row.original.timesheet_entry_id}`,
        data
      )
      .then((response) => {
        dispatch(
          setTriggerLeaveRequestDataFetch(
            !leaveRequestState.triggerLeaveRequestDataFetch
          )
        );
      })
      .catch((error) => {
        console.error("Error deleting resource:", error);
      });
  };

  const handleIncludeCheckboxChange = (table, row, e) => {
    if (row.original.status.trim() === "Submitted") {
      dispatch(handlePendingCheckboxChange(row.original.leave_request_id));
    } else if (row.original.status.trim() === "Approved") {
      dispatch(handleApprovedCheckboxChange(row.original.leave_request_id));
    } else if (row.original.status.trim() === "Rejected") {
      dispatch(handleRejectedCheckboxChange(row.original.leave_request_id));
    }
  };

  //UPDATE action
  const handleSaveUser = ({ values, table, row }) => {
    let errorFound = false;

    // check if duration is in valid format
    if (moment(values.duration, "HH:mm:ss", true).isValid()) {
      dispatch(setDurationEditValidationError(false));
    } else {
      errorFound = true;
      dispatch(setDurationEditValidationError(true));
      return;
    }

    // check if date is in valid format
    if (moment(values.date, "DD-MM-YYYY", true).isValid()) {
      dispatch(setDateValidationError(false));
    } else {
      errorFound = true;
      dispatch(setDateValidationError(true));
      return;
    }

    if (!errorFound) {
      const newRow = {
        ...row.original,
        ...values,
        activity: {
          activity_name: values.activity_name,
          activity_type: values.activity_type,
        },
        is_edited: true,
      };
      delete newRow.activity_name;
      delete newRow.activity_type;

      axiosInstance
        .put("/timemate/employeetimesheet/", newRow)
        .then((response) => {
          console.log("Resource Updated successfully:", response.data);
          dispatch(
            setTriggerLeaveRequestDataFetch(
              !leaveRequestState.triggerLeaveRequestDataFetch
            )
          );
        })
        .catch((error) => {
          console.error("Error Updating resource:", error);
        });

      table.setEditingRow(null); //exit editing mode
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    sortingFns: {
      customDateOrdering: (rowA, rowB, columnId) => {
        let dateA = moment(rowA.original.date, "DD-MM-YYYY");
        let dateB = moment(rowB.original.date, "DD-MM-YYYY");

        //sorting docs: https://tanstack.com/table/v8/docs/api/features/sorting#sorting-functions
        return dateA < dateB ? -1 : 1;
      },
    },
    renderColumnActionsMenuItems: ({ internalColumnMenuItems, additional }) => {
      internalColumnMenuItems = internalColumnMenuItems.filter(
        (menuItemObj) => {
          return (
            !menuItemObj.props.label.includes("Sort by") &&
            !menuItemObj.props.label.includes("Hide")
          );
        }
      );

      return [...internalColumnMenuItems];
    },

    editDisplayMode: "modal",
    enableGrouping: true,
    enableColumnDragging: false,
    enableGlobalFilter: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableRowActions: enableRowActions,
    initialState: {
      sorting: [
        {
          id: "date", //sort by age by default on page load
          desc: false,
        },
        {
          id: "duration", //then sort by lastName if age is the same
          desc: true,
        },
      ],
      // TO-DO
      //   grouping: groupingState,
    },
    muiTableBodyRowProps: ({ row }) => {
      // add different background color for public holiday entries
      return {
        sx: {
          backgroundColor:
            row.original?.activity_name?.toLowerCase() === "public holiday"
              ? "rgba(0,65,112, 0.05)"
              : "white",
        },
      };
    },
    //ADD HERE GROUPING CONTROL VIA STATE
    // state: {
    //   ...(groupingState?.length > 0 && { grouping: groupingState }),
    // },
    onEditingRowSave: handleSaveUser,
    onEditingRowCancel: () => {
      // clear all validation errors when editing is cancelled
      console.log("we cancel edit");
      dispatch(setDurationEditValidationError(false));
    },

    renderRowActions: ({ row, table }) => {
      return (
        <Box sx={{ display: "flex", gap: "1rem" }}>
          {showEditButton && (
            <Tooltip title="Edit">
              <IconButton
                style={{
                  color:
                    row.original.status?.trim() === "Submitted" ||
                    row.original.status?.trim() === "Approved"
                      ? "grey"
                      : "var(--philico-blue)",
                }}
                onClick={() => table.setEditingRow(row)}
                disabled={
                  row.original.status?.trim() === "Submitted" ||
                  row.original.status?.trim() === "Approved"
                  //   row.original.status === "Pending"
                }
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {showDeleteButton && (
            <Tooltip title="Delete">
              <IconButton
                style={{
                  color:
                    row.original.status?.trim() === "Submitted" ||
                    row.original.status?.trim() === "Approved"
                      ? // row.original.status === "Pending"
                        "grey"
                      : "var(--philico-blue)",
                }}
                onClick={() => deleteCallback(table, row)}
                disabled={
                  row.original.status?.trim() === "Submitted" ||
                  row.original.status?.trim() === "Approved"
                  //   row.original.status === "Pending"
                }
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          {showIncludeCheckbox && (
            <Tooltip title="Include">
              <Checkbox
                checked={checkboxState.includes(row.original.leave_request_id)}
                onChange={(e) => handleIncludeCheckboxChange(table, row, e)}
              />
            </Tooltip>
          )}
        </Box>
      );
    },
    muiTableContainerProps: { sx: { margin: "0px", flexGrow: 1 } },
  });

  return table;
};

export function getTileClassName(timesheetData) {
  // Function to aggregate status for a date
  const getStatusForDate = (date) => {
    const formatDate = moment(date).format("DD-MM-yyyy");

    const timesheetDataForDate = timesheetData.find(
      (data) => data.date === formatDate
    );

    if (!timesheetDataForDate) {
      return false;
    }

    // Assuming status of timesheetData for a date is unique
    return timesheetDataForDate.status?.toLowerCase();
  };

  // Return tileClassName function
  return function tileClassName({ date, view }) {
    if (view === "month") {
      const status = getStatusForDate(date);
      if (status === "approved") {
        return ["approved-timesheet"];
      } else if (status === "rejected") {
        return ["rejected-timesheet"];
      } else if (status === "submitted") {
        return ["submitted-timesheet"];
      } else if (status === "pending") {
        return ["pending-timesheet"];
      }
    }
    return null;
  };
}
