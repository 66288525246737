import React, { useLayoutEffect, useState, useRef } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import binIcon from "../../../../assets/bin_icon_large_24_24.svg";
import { SavedInfoIndicator } from "../SavedItemIndicator";
import {
  saveIsItIncluded,
  saveLanguage,
  saveLanguageSkill,
} from "./saveActions";
import { LANGUAGES } from "./constants";

function Languages(props) {
  const [languageWidth, setLanguageWidth] = useState(0);
  const [languageSkillWidth, setLanguageSkillWidth] = useState(0);
  const [error, setError] = useState(false);
  const [retry, setRetry] = useState(() => {});
  const languageSkillState = useSelector((state) => state.languageskill);

  const languageRef = useRef(null);
  const languageSkillRef = useRef(null);

  // define timeout to wait until width of text fields is computed
  const timeoutWidthComputation = 300;

  const dispatch = useDispatch();

  // layout effect that listens to changes in first name in order to scale the input field accordingly
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setLanguageWidth(languageRef.current.offsetWidth + 4 + 1);
      setLanguageSkillWidth(languageSkillRef.current.offsetWidth + 4 + 1);
    }, timeoutWidthComputation);

    setLanguageWidth(languageRef.current.offsetWidth + 4 + 1); //4 is the padding-left + padding-right
    setLanguageSkillWidth(languageSkillRef.current.offsetWidth + 4 + 1);

    return () => clearTimeout(timer);
  }, [languageSkillState.languageSkills]);

  return (
    <>
      <div
        style={{
          display: "flex",
          marginTop: "8px",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            justifyItems: "space-between",
          }}
        >
          <div style={{ minWidth: "150px" }}>
            <FormControl
              sx={{ width: `${languageWidth}px`, fontFamily: "Montserrat" }}
            >
              <Select
                className="selectfield-dynamic-cv position-philico"
                //   labelId="demo-simple-select-label"
                //   id="demo-simple-select"
                // value={age}
                //   value={internalInfoState.positionWithinPhilico}
                value={props.languageObject.language}
                label="Age"
                displayEmpty={true}
                renderValue={(value) =>
                  value?.length ? (
                    Array.isArray(value) ? (
                      value.join(", ")
                    ) : (
                      value
                    )
                  ) : (
                    <span style={{ color: "rgba(95, 99, 104, 0.4)" }}>
                      Language
                    </span>
                  )
                }
                onChange={(e) => {
                  saveLanguage({
                    id: props.languageObject.id,
                    value: e.target.value,
                    savedLanguages: props.savedLanguages,
                    setSavedLanguages: props.setSavedLanguages,
                    dispatch: dispatch,
                    setError,
                    setRetry: (retryFunction) => {
                      setRetry(() => retryFunction());
                    },
                  });
                }}
              >
                {LANGUAGES.map((language) => (
                  <MenuItem key={language} value={language}>
                    {language}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl
              sx={{
                width: `${languageSkillWidth}px`,
                fontFamily: "Montserrat",
              }}
            >
              <Select
                className="selectfield-dynamic-cv position-philico"
                //   labelId="demo-simple-select-label"
                //   id="demo-simple-select"
                // value={age}
                //   value={internalInfoState.positionWithinPhilico}
                value={props.languageObject.languageSkill}
                label="Age"
                displayEmpty={true}
                renderValue={(value) =>
                  value?.length ? (
                    Array.isArray(value) ? (
                      value.join(", ")
                    ) : (
                      value
                    )
                  ) : (
                    <span style={{ color: "rgba(95, 99, 104, 0.4)" }}>
                      Skill level
                    </span>
                  )
                }
                onChange={(e) => {
                  saveLanguageSkill({
                    id: props.languageObject.id,
                    value: e.target.value,
                    savedLanguages: props.savedLanguages,
                    setSavedLanguages: props.setSavedLanguages,
                    dispatch: dispatch,
                    setError,
                    setRetry: (retryFunction) => {
                      setRetry(() => retryFunction());
                    },
                  });
                }}
              >
                <MenuItem value="Mother tongue">Mother tongue</MenuItem>
                <MenuItem value="Written and spoken fluency">
                  Written and spoken fluency
                </MenuItem>
                <MenuItem value="Working proficiency">
                  Working proficiency
                </MenuItem>
                <MenuItem value="Intermediate">Intermediate</MenuItem>
                <MenuItem value="Beginner">Beginner</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {props.savedLanguages.includes(props.languageObject.id) && (
            <SavedInfoIndicator error={error} retry={retry} />
          )}
          <div>
            <Tooltip title="Include in CV" arrow>
              <Checkbox
                checked={props.languageObject.isIncludedInCv}
                onChange={(e) => {
                  saveIsItIncluded({
                    id: props.languageObject.id,
                    checked: e.target.checked,
                    savedLanguages: props.savedLanguages,
                    setSavedLanguages: props.setSavedLanguages,
                    dispatch: dispatch,
                    setError,
                    setRetry: (retryFunction) => {
                      setRetry(() => retryFunction());
                    },
                  });
                }}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip
              title="Delete language"
              arrow
              //   open={!isToolTipDisabled && isHover}
            >
              <IconButton
                onClick={() => {
                  props.handleDelete(props.languageObject.id);
                }}
              >
                <img src={binIcon} alt="bin icon" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <div ref={languageRef} id="position-measure">
        {props.languageObject.language
          ? props.languageObject.language
          : "Language"}
      </div>
      <div ref={languageSkillRef} id="position-measure">
        {props.languageObject.languageSkill
          ? props.languageObject.languageSkill
          : "Language Skill"}
      </div>
    </>
  );
}

export default Languages;
