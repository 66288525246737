import { Checkbox, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";

const reduxMapping = (status) => {
  if ( status === "Submitted" || status === "Edited" || status === "Resubmitted") {
    return "pendingCheckedTimeSheetReq";
  } else if (status === "Approved") {
    return "approvedCheckedTimeSheetReq";
  } else if (status === "Rejected") {
    return "rejectedCheckedTimeSheetReq";
  }
};

export const TimesheetApprovalCheckBox = ({
  includeCheckboxChange,
  row,
}) => {
  const reduxKey = reduxMapping(row.original.status);
  const checkboxState = useSelector((state) => state.timesheet[reduxKey]);
  return (
    <Tooltip title="Include">
      <Checkbox
        checked={checkboxState.includes(row.original.timesheet_status_id)}
        onChange={() => includeCheckboxChange(row)}
      />
    </Tooltip>
  );
};
