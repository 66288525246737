import axiosInstance from "../../../../axios/axiosConfig";
import {
  setDurationEditValidationError,
  setDateValidationError,
  handleApprovedCheckboxChange,
  handlePendingCheckboxChange,
  handleRejectedCheckboxChange,
  setActivity,
  setActivityType,
  setActivityList,
  setComment,
  setDuration,
  setSelectedDates,
  setSite,
} from "../../../../features/timesheetSlice";
import moment from "moment";
import { parse } from "date-fns";

import {} from "../../../../features/timesheetSlice";

export const handleDeleteCallback = (updateTimeSheetPage) => (row) => {
  const entryId = row.original.timesheet_entry_id;
  axiosInstance
    .delete(`/timemate/employeetimesheet/?timesheet_entry_id=${entryId}`)
    .then(() => updateTimeSheetPage())
    .catch((error) => console.error("Error deleting resource:", error));
};

export const handleIncludeCheckboxChange = (dispatch) => (row) => {
  const entryId = row.original.timesheet_status_id;
  const statusActions = {
    Submitted: handlePendingCheckboxChange,
    Approved: handleApprovedCheckboxChange,
    Rejected: handleRejectedCheckboxChange,
  };

  const action = statusActions[row.original.status.trim()];
  if (action) dispatch(action(entryId));
};

export const handleSaveUser =
  ({ dispatch, updateTimeSheetPage }) =>
  async ({ values, table, row }) => {
    if (!moment(values.duration, "HH:mm:ss", true).isValid()) {
      dispatch(setDurationEditValidationError(true));
      return;
    }

    if (!moment(values.date, "DD-MM-YYYY", true).isValid()) {
      dispatch(setDateValidationError(true));
      return;
    }

    const updatedRow = {
      ...row.original,
      ...values,
      is_edited: true,
      activity: {
        activity_name: values.activity_name,
        activity_type: values.activity_type,
      },
    };

    try {
      await axiosInstance.put("/timemate/employeetimesheet/", updatedRow);
      updateTimeSheetPage();
    } catch (error) {
      console.error("Error updating resource:", error);
    }

    table.setEditingRow(null);
  };

export const handleCopy = (dispatch) => async (row) => {
  const { activity_type, activity_name, comment, date, duration, site } =
    row.original;
  console.log(
    "Copying row:",
    activity_type,
    activity_name,
    comment,
    date,
    duration,
    row.original
  );
  await dispatch(setActivityType(activity_type));
  await dispatch(setActivityList(activity_type));
  dispatch(setActivity(activity_name));
  dispatch(setComment(comment));
  dispatch(setSite(site));
  dispatch(
    setSelectedDates([
      parse(row.original.date, "dd-MM-yyyy", new Date()),
      parse(row.original.date, "dd-MM-yyyy", new Date()),
    ])
  );
  dispatch(setDuration(row.original.duration));
};
