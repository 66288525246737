import OutlinedInput from "@mui/material/OutlinedInput";
import { useEffect, useState } from "react";
import { SavedInfoIndicator } from "../SavedItemIndicator";

export const FieldComponent = ({
  value,
  placeHolder,
  onChange,
  onBlur,
  width,
  className = "outlinedinput-dynamic-cv header-text",
  inputRef = null,
  changeOnlyFontColor = false,
}) => {
  const [error, setError] = useState(false);
  const [retry, setRetry] = useState(() => {});
  const [isDataSaved, setIsDataSaved] = useState(false);

  useEffect(() => {
    if (isDataSaved) {
      setTimeout(() => {
        setIsDataSaved(false);
      }, 2000);
    }
  }, [isDataSaved]);

  return (
    <div className="component-and-saveing-indicator-wrapper">
      <div>
        <OutlinedInput
          className={`${className} ${
            isDataSaved && changeOnlyFontColor ? "saved-text" : ""
          }`}
          placeholder={placeHolder}
          value={value}
          onChange={(e) => onChange(e)}
          onBlur={(e) => onBlur(e, setError, setRetry, setIsDataSaved)}
          inputRef={inputRef}
          sx={{
            width: `${width}px`,
          }}
        />
      </div>
      <div style={{ width: "6ch" }}>
        {isDataSaved && !changeOnlyFontColor && (
          <SavedInfoIndicator error={error} retry={retry} />
        )}
      </div>
    </div>
  );
};
