import axiosInstance from "../../../../axios/axiosConfig";
import { initializeCvSummary } from "../../../../features/cvSummarySlice";

export const saveSummary = ({
  id,
  value,
  setIsSummarySaved,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/cv-summaries/", {
      id: id,
      summary: value,
    })
    .then((response) => {
      setError(false);
      dispatch(initializeCvSummary([response.data]));
      setIsSummarySaved(true);
    })
    .catch((error) => {
      console.log("Error updating summary:", error);
      setError(true);
      setRetry(() => () =>  {
        saveSummary({
          id: id,
          value: value,
          setIsSummarySaved,
          dispatch,
          setError,
          setRetry,
        });
      });
    });

export const saveAddSummary = ({
  setAddSummaryClicked,
  setSummary,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .post("/cvapp/cv-summaries/", {
      title: "",
      summary: "",
    })
    .then((response) => {
      setError(false);
      setAddSummaryClicked(true);
      dispatch(initializeCvSummary([response.data]));
      setSummary([response.data]);
    })
    .catch((error) => {
      console.log("Error adding summary:", error);
      setError(true);
      setRetry(() => () =>  {
        saveAddSummary({
          setAddSummaryClicked,
          setSummary,
          dispatch,
          setError,
          setRetry,
        });
      });
    });
