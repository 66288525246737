import React from "react";
import DynamicCvHeader from "./Header/DynamicCvHeader";
import DynamicCvSummary from "./Summary/DynamicCvSummary";
import DynamicCvSkills from "./Skills/DynamicCvSkills";
import DynamicCvProjectExperience from "./ProjectExperience/DynamicCvProjectExperience";
import DynamicCvEmploymentHistory from "./EmploymentHistory/DynamicCvEmploymentHistory";
import DynamicCvDegrees from "./Degrees/DynamicCvDegrees";
import DynamicCvCertificates from "./Certificates/DynamicCvCertificates";
import DynamicCvHeader2nd from "./Header/DynamicCvHeader2nd";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import { useSelector } from "react-redux";

function DynamicCv() {
  return (
    <>
      <div
        className="a4-page-dynamic-cv"
        // style={
        //   {
        // borderWidth: "5px",
        // borderStyle: "solid",
        // borderColor: internalInfoState.isGeneralCV ? "#7c6050" : "#004170",
        // backgroundColor: internalInfoState.isGeneralCV
        //   ? "rgba(124, 96, 80, 0.02)"
        //   : "rgba(0, 65, 112, 0.02)",
        // }
        // }
      >
        <div className="a4-page-content-container-dynamic-cv">
          <DynamicCvHeader2nd />
          <PersonalInfo />
          <DynamicCvSummary />
          <DynamicCvSkills />
          <DynamicCvProjectExperience />
          <DynamicCvEmploymentHistory />
          <DynamicCvDegrees />
          <DynamicCvCertificates />
        </div>
      </div>
    </>
  );
}

export default DynamicCv;
