import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { parse } from "date-fns";
import { makeStyles } from "@mui/styles";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import fr from "date-fns/locale/fr";
import moment from "moment";

import "./TimesheetCard.css";

import SelectCustomized from "../../../../components/input_components/SelectCustomized";
import AddIconButton from "../../../../components/input_components/AddIconButton";
import {
  useGetActivities,
  updateTimesheet,
  dateFormatter,
  getDatesInRange,
  isValidTimeString,
} from "./helpers";
import {
  setActivity,
  setActivityType,
  setComment,
  setDuration,
  setSite,
  setSelectedDates,
} from "../../../../features/timesheetSlice";
import { WORKSITES } from "../../constants";

const useStyles = makeStyles((theme) => ({
  customButtonOpen: {
    width: "100%",
    borderRadius: "8px 8px 0px 0px ", // Adjust the values as needed
  },
  customButtonClosed: {
    width: "100%",
    borderRadius: "8px", // Adjust the values as needed
  },
  startIcon: {
    position: "absolute",
    left: theme.spacing(2), // Adjust the left position of the icon
    top: "50%", // Center the icon vertically
    transform: "translateY(-50%)", // Adjust to center the icon vertically
  },
  disabledButton: {
    backgroundColor: "#A49D9C", // Set your desired color for the disabled state
    color: "#A49D9C", // Set your desired text color for the disabled state
  },
}));


const TimeSheetCard = ({ dates, updateTimeSheetPage, disabled }) => {
  const adminState = useSelector((state) => state.admin);
  const timesheetState = useSelector((state) => state.timesheet);
  const classes = useStyles();
  const [validDuration, setValidDuration] = useState(false);
  const [requiredComment, setRequiredComment] = useState(false);
  const [activityTypeList, setActivityTypeList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const dispatch = useDispatch();
  const timePickerInputRef = useRef(null);

  useGetActivities(dispatch);

  const cleanState = () => {
    dispatch(setActivity(""));
    dispatch(setDuration(null));
    dispatch(setComment(""));
    dispatch(setActivityType(""));
    dispatch(setSite(""));
    dispatch(setSelectedDates([null, null]));
    updateTimeSheetPage();
  };

  useEffect(() => {
    setActivityTypeList(
      timesheetState.activities.map((group) => group.activity_type)
    );
  }, [timesheetState.activities]);

  useEffect(() => {
    cleanState();
  }, [adminState.activeUser]);

  useEffect(() => {
    try {
      setActivityList(
        timesheetState.activities
          // .find((group) => group.activity_type === activityType)
          .find((group) => group.activity_type === timesheetState.activityType)
          .activities.map((activity) => activity.activity_name)
      );
    } catch {
      console.log("Error");
    }
  }, [timesheetState.activityType]);

  const handleAdd = async () => {
    let isValid = true;

    if (!timesheetState.activity) {
      alert("Please enter a valid task.");
      isValid = false;
    }

    if (!timesheetState.duration || timesheetState.duration === "00:00:00") {
      alert("Please enter a valid duration.");
      isValid = false;
    }

    if (requiredComment && timesheetState.comment === "") {
      alert("Please Enter Comment!");
      isValid = false;
    }

    if (!timesheetState.site) {
      alert("Please select work location!");
      isValid = false;
    }

    console.log("ValidEntry", isValid);
    // If all fields are valid, proceed
    if (isValid) {
      const list_of_selected_dates = getDatesInRange(
        dates,
        timesheetState.isWeekendEnabled
      );

      // const list_of_selected_dates = getDatesInRange(dates, true);
      list_of_selected_dates.forEach(async (date) => {
        let durationValue;
        // let durationValue = moment(timesheetState.duration).format("HH:mm:ss");
        if (timesheetState.duration instanceof Date) {
          durationValue = moment(timesheetState.duration).format("HH:mm:ss");
        } else {
          durationValue = timesheetState.duration;
        }
        console.log("duration value before update time sheet: ", durationValue);
        try {
          await updateTimesheet(
            adminState.activeUser,
            timesheetState.activityType,
            timesheetState.activity,
            date,
            // duration.toLocaleTimeString(),
            durationValue,
            timesheetState.comment,
            timesheetState.site
          );
          cleanState();
        } catch (error) {
          console.log(`Unable to add Timesheet for date: ${date}, ${error}`);
        }
      });
    }
  };

  const handleActivityChange = (e) => {
    const activity_new_value = e.target.value;
    dispatch(setActivity(activity_new_value));

    setRequiredComment(
      timesheetState.activities
        .find((group) => group.activity_type === timesheetState.activityType)
        .activities.find(
          (activity) => activity.activity_name === activity_new_value
        ).required_comment
    );
  };

  const handleActivityTypeChange = (e) => {
    dispatch(setActivityType(e.target.value));
    dispatch(setActivity(""));
  };

  const handleDurationChange = (newValue) => {
    let formattedDuration = moment(newValue).format("HH:mm:ss");

    if (formattedDuration !== "Invalid date") {
      dispatch(setDuration(formattedDuration));
      setValidDuration(isValidTimeString(formattedDuration));
      console.log(
        `formatted duration: ${formattedDuration}, isValidtimeString: ${isValidTimeString(
          formattedDuration
        )}`
      );
    }
  };

  const handleDurationBlur = (value) => {
    // check if value contains : . This is done because integers >= 10 entered in duration field include :
    if (value.includes(":")) {
      let splitString = value.split(":");

      //check if second part of string is empty (so that single integer was entered)
      if (splitString[1].length === 0) {
        //if integer between 24 and 0 then we transform it
        if (Number(splitString[0]) < 24 && Number(splitString[0]) > 0) {
          dispatch(setDuration(splitString[0].padStart(2, "0") + ":00:00"));
        }
      }
    } else {
      if (Number(value) < 24 && Number(value) > 0) {
        dispatch(setDuration(value.padStart(2, "0") + ":00:00"));
      }
    }
  };

  const handleCommentChange = (e) => {
    dispatch(setComment(e.target.value));
  };

  useEffect(() => {
    // if activityType is absence then location must be set to Home
    if (timesheetState.activityType === "Absence") {
      dispatch(setSite("Home"));
    }
  }, [timesheetState.activityType]);

  useEffect(() => {
    setValidDuration(isValidTimeString(timesheetState.duration));
  }, [timesheetState.duration]);

  return (
    <div className="timesheet-card">
      <div className="timesheet-card-title">
        {disabled
          ? "Selected month is already submitted"
          : dateFormatter(dates)}
      </div>
      <Grid container columnSpacing={2} direction="row" rowSpacing={1}>
        <Grid item xs={4}>
          <SelectCustomized
            label="Type"
            select_options={activityTypeList}
            values={activityTypeList}
            value={timesheetState.activityType}
            onChange={handleActivityTypeChange}
            fullWidth={true}
            letterSpacing={0}
            disabled={disabled}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
            <TimePicker
              label="Duration"
              disabled={disabled}
              inputRef={(current) => {
                if (current) {
                  timePickerInputRef.current = current;
                }
                return current?.getAttribute("value");
              }}
              value={
                timesheetState.duration
                  ? parse(timesheetState.duration, "HH:mm:ss", new Date())
                  : null
              }
              onChange={handleDurationChange}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    onBlur={() => {
                      // if date is currently not valid and value is integer between 1 to 24 we transform it to HH:00. For example 7 will be transformed to 07:00
                      if (
                        !moment(
                          timePickerInputRef.current?.getAttribute("value"),
                          "HH:mm",
                          true
                        ).isValid()
                      ) {
                        handleDurationBlur(
                          timePickerInputRef.current?.getAttribute("value")
                        );
                      }
                    }}
                  />
                );
              }}
              disableOpenPicker={true}
              views={["hours", "minutes"]}
              format="hh:mm"
            />
          </LocalizationProvider>

          <SelectCustomized
            label="Location"
            select_options={WORKSITES}
            values={WORKSITES}
            value={timesheetState.site}
            defaultValue={"Home"}
            onChange={(e) => {
              dispatch(setSite(e.target.value));
            }}
            disabled={timesheetState.activityType === "Absence" || disabled}
            fullWidth={true}
            letterSpacing={0}
            marginTop="23px" // Add this line
          />
        </Grid>

        <Grid item xs={8}>
          <SelectCustomized
            label="Activity"
            select_options={activityList}
            values={activityList}
            value={timesheetState.activity}
            onChange={handleActivityChange}
            fullWidth={true}
            letterSpacing={0}
            disabled={activityList.length === 0 || disabled}
          />
          <TextField
            id="outlined-multiline-flexible"
            label="Comment"
            disabled={disabled}
            multiline
            rows={4.44}
            fullWidth={true}
            onChange={handleCommentChange}
            value={timesheetState.comment}
            required={requiredComment}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            style={{ marginTop: "0px", marginBottom: "0px" }}
            fullWidth={true}
            type="submit"
            variant="contained"
            onClick={handleAdd}
            startIcon={<AddIconButton className={classes.startIcon} />}
            disabled={
              dates === null ||
              (!dates?.[0] && !dates?.[1]) ||
              timesheetState.activity === "" ||
              !validDuration ||
              timesheetState.site === "" ||
              (requiredComment && timesheetState.comment === "") ||
              disabled
            }
          >
            Add Entry
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default TimeSheetCard;
