import axiosInstance from "../../../../axios/axiosConfig";
import { changeEmploymentHistoryObject } from "../../../../features/employmentHistory";
import { temporarilyMarkAsSaved } from "../../utils";

export const saveStartDate = ({
  id,
  date,
  savedEmploymentHistory,
  setSavedEmploymentHistory,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/employment-history/", { id, startDate: date })
    .then((response) => {
      temporarilyMarkAsSaved(
        response.data.id,
        savedEmploymentHistory,
        setSavedEmploymentHistory
      )(null);
      dispatch(
        changeEmploymentHistoryObject({
          id: response.data.id,
          property: "startDate",
          value: response.data.startDate,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);;
      setRetry(() => () => 
        saveStartDate({
          id,
          date,
          savedEmploymentHistory,
          setSavedEmploymentHistory,
          dispatch,
          setError,
          setRetry,
        })
      );
      console.error("Error updating start date:", error);
    });

export const saveEndDate = ({
  id,
  date,
  savedEmploymentHistory,
  setSavedEmploymentHistory,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/employment-history/", { id, endDate: date })
    .then((response) => {
      temporarilyMarkAsSaved(
        response.data.id,
        savedEmploymentHistory,
        setSavedEmploymentHistory
      )(null);
      dispatch(
        changeEmploymentHistoryObject({
          id: response.data.id,
          property: "endDate",
          value: response.data.endDate,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);;
      setRetry(() => () => 
        saveEndDate({
          id,
          date,
          savedEmploymentHistory,
          setSavedEmploymentHistory,
          dispatch,
          setError,
          setRetry,
        })
      );
      console.error("Error updating end date:", error);
    });

export const saveJobTitle = ({
  id,
  value,
  savedEmploymentHistory,
  setSavedEmploymentHistory,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/employment-history/", { id, jobTitle: value })
    .then((response) => {
      temporarilyMarkAsSaved(
        response.data.id,
        savedEmploymentHistory,
        setSavedEmploymentHistory
      )(null);
      dispatch(
        changeEmploymentHistoryObject({
          id: response.data.id,
          property: "jobTitle",
          value: response.data.jobTitle,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);;
      setRetry(() => () => 
        saveJobTitle({
          id,
          value,
          savedEmploymentHistory,
          setSavedEmploymentHistory,
          dispatch,
          setError,
          setRetry,
        })
      );
      console.error("Error updating job title:", error);
    });

export const saveEmployer = ({
  id,
  value,
  savedEmploymentHistory,
  setSavedEmploymentHistory,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/employment-history/", { id, employer: value })
    .then((response) => {
      temporarilyMarkAsSaved(
        response.data.id,
        savedEmploymentHistory,
        setSavedEmploymentHistory
      )(null);
      dispatch(
        changeEmploymentHistoryObject({
          id: response.data.id,
          property: "employer",
          value: response.data.employer,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);;
      setRetry(() => () => 
        saveEmployer({
          id,
          value,
          savedEmploymentHistory,
          setSavedEmploymentHistory,
          dispatch,
          setError,
          setRetry,
        })
      );
      console.error("Error updating employer:", error);
    });

export const saveLocation = ({
  id,
  value,
  savedEmploymentHistory,
  setSavedEmploymentHistory,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/employment-history/", { id, location: value })
    .then((response) => {
      temporarilyMarkAsSaved(
        response.data.id,
        savedEmploymentHistory,
        setSavedEmploymentHistory
      )(null);
      dispatch(
        changeEmploymentHistoryObject({
          id: response.data.id,
          property: "location",
          value: response.data.location,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);;
      setRetry(() => () => 
        saveLocation({
          id,
          value,
          savedEmploymentHistory,
          setSavedEmploymentHistory,
          dispatch,
          setError,
          setRetry,
        })
      );
      console.error("Error updating location:", error);
    });

export const saveIsIncludedInCv = ({
  id,
  checked,
  savedEmploymentHistory,
  setSavedEmploymentHistory,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/employment-history/", { id, isIncludedInCv: checked })
    .then((response) => {
      temporarilyMarkAsSaved(
        response.data.id,
        savedEmploymentHistory,
        setSavedEmploymentHistory
      )(null);
      dispatch(
        changeEmploymentHistoryObject({
          id: response.data.id,
          property: "isIncludedInCv",
          value: response.data.isIncludedInCv,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);;
      setRetry(() => () => 
        saveIsIncludedInCv({
          id,
          checked,
          savedEmploymentHistory,
          setSavedEmploymentHistory,
          dispatch,
          setError,
          setRetry,
        })
      );
      console.error("Error updating isIncludedInCv:", error);
    });
