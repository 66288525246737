import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import "./TimesheetDataView.css";

import ApprovalTimeSheetTable from "./ApprovalTimeSheetTable";
import {
  initalizePendingCheckedTimeSheetReq,
  initalizeApprovedCheckedTimeSheetReq,
  initalizeRejectedCheckedTimeSheetReq,
} from "../../../../features/timesheetSlice";
import { compareArrays } from "../../helpers";
import { filterData } from "./utils";

const ApprovalTimeSheetView = ({
  timesheetData,
  employeeId,
  updateTimeSheetPage,
  disableEditing,
  enableRowActions,
  showIncludeCheckbox,
  approveCallback,
  rejectCallback,
  reopenCallback,
}) => {
  const dispatch = useDispatch();

  const timeSheetState = useSelector((state) => state.timesheet);
  const {
    pendingCheckedTimeSheetReq,
    approvedCheckedTimeSheetReq,
    rejectedCheckedTimeSheetReq,
  } = timeSheetState;

  const statusMapping = {
    Submitted: {
      currentState: pendingCheckedTimeSheetReq,
    },
    Approved: {
      currentState: approvedCheckedTimeSheetReq,
    },
    Rejected: {
      currentState: rejectedCheckedTimeSheetReq,
    },
  };

  const handleSelectAll = useCallback(
    (type) => {
      const extractedIds = type.reduce((acc, item) => {
        acc[item.status] = acc[item.status] || [];
        acc[item.status].push(item.timesheet_status_id);
        return acc;
      }, {});
      const statusMapping = {
        Submitted: {
          currentState: pendingCheckedTimeSheetReq,
          action: initalizePendingCheckedTimeSheetReq,
        },
        Approved: {
          currentState: approvedCheckedTimeSheetReq,
          action: initalizeApprovedCheckedTimeSheetReq,
        },
        Rejected: {
          currentState: rejectedCheckedTimeSheetReq,
          action: initalizeRejectedCheckedTimeSheetReq,
        },
      };
      Object.entries(statusMapping).forEach(
        ([status, { currentState, action }]) => {
          if (extractedIds[status]) {
            dispatch(
              compareArrays(currentState, extractedIds[status])
                ? action([]) // Unselect all
                : action(extractedIds[status]) // Select all
            );
          }
        }
      );
    },
    [
      dispatch,
      pendingCheckedTimeSheetReq,
      approvedCheckedTimeSheetReq,
      rejectedCheckedTimeSheetReq,
    ]
  );

  const renderTimeSheetTable = (requestState) => {
    return(
    <ApprovalTimeSheetTable
      employeeId={employeeId}
      enableEditEntry={false}
      enableCopyEntry={false}
      enableDeleteEntry={false}
      timesheetData={filterData(requestState, timesheetData)}
      updateTimeSheetPage={updateTimeSheetPage}
      disableEditing={disableEditing}
      requestState={requestState}
      enableRowActions={enableRowActions}
      showIncludeCheckbox={showIncludeCheckbox}
      handleSelectAll={handleSelectAll}
      approveCallback={approveCallback}
      rejectCallback={rejectCallback}
      reopenCallback={() => reopenCallback(requestState)}
      chekedCheckBoxes={statusMapping[requestState]?.currentState}
    />
  )};
  return (
    <div className="data-view-container">
      {renderTimeSheetTable("Pending")}
      {renderTimeSheetTable("Approved")}
      {renderTimeSheetTable("Rejected")}
    </div>
  );
};

export default ApprovalTimeSheetView;
