import React, { useRef, useState, useLayoutEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import binIcon from "../../../../assets/bin_icon_large_24_24.svg";
import { useSelector, useDispatch } from "react-redux";
import { changeCertificateObject } from "../../../../features/cvCertificates";
import { parse } from "date-fns";
import moment from "moment";
import { SavedInfoIndicator } from "../SavedItemIndicator";
import {
  saveCertificateName,
  saveDate,
  saveIsIncludedInCv,
  saveIssuingOrganization,
  saveLocation,
} from "./saveActions";

function Certificate(props) {
  const certificateState = useSelector((state) => state.certificates);

  const dispatch = useDispatch();

  const certificateRef = useRef(null);
  const issuingOrgRef = useRef(null);
  const locationRef = useRef(null);
  const endYearRef = useRef(null);

  const [certificateWidth, setCertificateWidth] = useState(0);
  const [issuingOrgWidth, setIssuingOrgWidth] = useState(0);
  const [locationWidth, setLocationWidth] = useState(0);
  const [endYearWidth, setEndYearWidth] = useState(0);
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(null);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setEndYearWidth(endYearRef.current.offsetWidth + 4 + 1);
      setCertificateWidth(certificateRef.current.offsetWidth + 4 + 1);
      setIssuingOrgWidth(issuingOrgRef.current.offsetWidth + 4 + 1);
      setLocationWidth(locationRef.current.offsetWidth + 4 + 1);

      //   setSchoolWidth(schoolRef.current.offsetWidth + 4 + 1);
      //   setDegreeWidth(degreeRef.current.offsetWidth + 4 + 1);
      //   setFieldOfStudyWidth(fieldOfStudyRef.current.offsetWidth + 4 + 1);
    }, 150);

    setEndYearWidth(endYearRef.current.offsetWidth + 4 + 1);
    setCertificateWidth(certificateRef.current.offsetWidth + 4 + 1);
    setIssuingOrgWidth(issuingOrgRef.current.offsetWidth + 4 + 1);
    setLocationWidth(locationRef.current.offsetWidth + 4 + 1);

    // setSchoolWidth(schoolRef.current.offsetWidth + 4 + 1);
    // setDegreeWidth(degreeRef.current.offsetWidth + 4 + 1);
    // setFieldOfStudyWidth(fieldOfStudyRef.current.offsetWidth + 4 + 1); //4 is the padding-left + padding-right
    return () => clearTimeout(timer);
  }, [certificateState.certificates]);

  return (
    <>
      <div className="flexbox-wrapper-project-experience">
        <div className="grid-wrapper-project-experience">
          <div className="grid-item-date-col">
            <div className="flexbox-date-col-wrapper">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  className="outlinedinput-dynamic-cv"
                  // inputFormat="dd.MM.yyyy"
                  // mask="__.__.____"
                  inputFormat="MM.yyyy"
                  mask="__.____"
                  disableMaskedInput={false}
                  value={parse(
                    props.certificateObject.endYear,
                    "MM.yyyy",
                    new Date()
                  )}
                  onChange={(newValue) => {
                    let transformedDate = moment(newValue).format("MM.YYYY");
                    if (transformedDate !== "Invalid date") {
                      saveDate({
                        id: props.certificateObject.id,
                        date: transformedDate,
                        savedCertificates: props.savedCertificates,
                        setSavedCertificates: props.setSavedCertificates,
                        dispatch: dispatch,
                        setError: setError,
                        setRetry: (retryFunction) => {
                          setRetry(() => retryFunction());
                        },
                      });
                    }
                  }}
                  disableOpenPicker
                  // helperText="dd.mm.yyyy"
                  // placeholder="dd.mm.yyyy"
                  renderInput={(params) => {
                    //add style prop to increase font-size
                    return (
                      <OutlinedInput
                        className="outlinedinput-dynamic-cv"
                        InputProps={params.InputProps}
                        {...params}
                        // label="birthdate"
                        inputRef={params.inputRef}
                        //   helperText="dd.mm.yyyy"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "mm.yyyy",
                        }}
                        onChange={(e) => {
                          console.log("e.target.value ", e.target.value);
                          console.log("e", e);
                        }}
                        sx={{ width: `${endYearWidth}px` }}
                      />
                    );
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="experience-title">
            <OutlinedInput
              placeholder="Name of certificate"
              className="outlinedinput-dynamic-cv certificate"
              sx={{ width: `${certificateWidth}px`, maxWidth: "100%" }}
              value={props.certificateObject.certificateName}
              onChange={(e) => {
                dispatch(
                  changeCertificateObject({
                    id: props.certificateObject.id,
                    property: "certificateName",
                    value: e.target.value,
                  })
                );
              }}
              onBlur={(e) => {
                saveCertificateName({
                  id: props.certificateObject.id,
                  value: e.target.value,
                  savedCertificates: props.savedCertificates,
                  setSavedCertificates: props.setSavedCertificates,
                  dispatch: dispatch,
                  setError: setError,
                  setRetry: (retryFunction) => {
                    setRetry(() => retryFunction());
                  },
                });
              }}
              multiline
            />
            {/* <span>, </span> */}
          </div>
          <div className="experience-subtitle">
            {" "}
            <OutlinedInput
              placeholder="Issuing Organization"
              className="outlinedinput-dynamic-cv"
              sx={{ width: `${issuingOrgWidth}px`, maxWidth: "100%" }}
              value={props.certificateObject.issuingOrganization}
              onChange={(e) => {
                dispatch(
                  changeCertificateObject({
                    id: props.certificateObject.id,
                    property: "issuingOrganization",
                    value: e.target.value,
                  })
                );
              }}
              onBlur={(e) => {
                saveIssuingOrganization({
                  id: props.certificateObject.id,
                  value: e.target.value,
                  savedCertificates: props.savedCertificates,
                  setSavedCertificates: props.setSavedCertificates,
                  dispatch: dispatch,
                  setError: setError,
                  setRetry: (retryFunction) => {
                    setRetry(() => retryFunction());
                  },
                });
              }}
              multiline
            />
            <span>, </span>
            <OutlinedInput
              placeholder="Location"
              className="outlinedinput-dynamic-cv"
              multiline
              sx={{ width: `${locationWidth}px`, maxWidth: "100%" }}
              value={props.certificateObject.location}
              onChange={(e) => {
                dispatch(
                  changeCertificateObject({
                    id: props.certificateObject.id,
                    property: "location",
                    value: e.target.value,
                  })
                );
              }}
              onBlur={(e) => {
                saveLocation({
                  id: props.certificateObject.id,
                  value: e.target.value,
                  savedCertificates: props.savedCertificates,
                  setSavedCertificates: props.setSavedCertificates,
                  dispatch: dispatch,
                  setError: setError,
                  setRetry: (retryFunction) => {
                    setRetry(() => retryFunction());
                  },
                });
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div>
            {props.isSaved && (
              <SavedInfoIndicator error={error} retry={retry} />
            )}
          </div>
          <div>
            <Tooltip title="Include in CV" arrow>
              <Checkbox
                checked={props.certificateObject.isIncludedInCv}
                onChange={(e) => {
                  saveIsIncludedInCv({
                    id: props.certificateObject.id,
                    value: e.target.checked,
                    savedCertificates: props.savedCertificates,
                    setSavedCertificates: props.setSavedCertificates,
                    dispatch: dispatch,
                    setError: setError,
                    setRetry: setRetry,
                  });
                }}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip
              title="Delete Certificate"
              arrow
              //   open={!isToolTipDisabled && isHover}
            >
              <IconButton
                // onMouseOver={() => setIsHover(true)}
                // onMouseLeave={() => setIsHover(false)}
                onClick={(e) => {
                  props.handleDelete(props.certificateObject.id);
                }}
                // onClick={(e) => {
                //   props.handleDelete(props.experienceObject.id);
                // }}
                // onClick={(e) => {
                //   setIsToolTipDisabled(!isToolTipDisabled);
                //   setAnchorEl(e.currentTarget);
                //   setOpen((previousState) => !previousState);
                //           }}
              >
                <img src={binIcon} alt="bin icon" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div ref={endYearRef} id="end-year-measure">
          {props.certificateObject.endYear
            ? props.certificateObject.endYear
            : "mm.yyyy"}
        </div>
        <div ref={certificateRef} id="certificate-measure">
          {props.certificateObject.certificateName
            ? props.certificateObject.certificateName
            : "Name of Certificate"}
        </div>
        <div ref={issuingOrgRef} id="issuing-org-measure">
          {props.certificateObject.issuingOrganization
            ? props.certificateObject.issuingOrganization
            : "Issuing Organization"}
        </div>

        <div ref={locationRef} id="location-measure">
          {props.certificateObject.location
            ? props.certificateObject.location
            : "Location"}
        </div>
      </div>
      {/*<div ref={schoolLocationRef} id="school-location-measure">
        {props.degreeObject.schoolLocation
          ? props.degreeObject.schoolLocation
          : "Location"}
      </div> */}
    </>
  );
}

export default Certificate;
