import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import OutlinedInput from "@mui/material/OutlinedInput";
import { parse } from "date-fns";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import { saveBirthDate } from "./saveActions";
import { SavedInfoIndicator } from "../SavedItemIndicator";

export const BirthDatePicker = ({
  AdapterDateFns,
  birthDate,
  birthDateWidth,
  className,
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [retry, setRetry] = useState(() => {});
  const [isDataSaved, setIsDataSaved] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsDataSaved(false);
    }, 2000);
  }, [isDataSaved]);

  useEffect(() => {}, [error, birthDate]);

  return (
    <div className="component-and-saveing-indicator-wrapper"
    >
      <div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            className={className}
            value={
              birthDate?.length === 10
                ? parse(birthDate, "yyyy-MM-dd", new Date())
                : null
            }
            inputFormat="dd.MM.yyyy"
            mask="__.__.____"
            disableMaskedInput={false}
            onChange={(newValue) => {
              let transformedDate = moment(newValue).format("YYYY-MM-DD");
              if (newValue && transformedDate !== "Invalid date") {
                saveBirthDate({
                  dispatch,
                  date: transformedDate,
                  setError,
                  setRetry: (retryFunction) => {
                    setRetry(() => retryFunction());
                  },
                  setIsDataSaved,
                });
              }
            }}
            disableOpenPicker
            renderInput={(params) => {
              //add style prop to increase font-size
              return (
                <OutlinedInput
                  {...params}
                  inputRef={params.inputRef}
                  //   helperText="dd.mm.yyyy"
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "dd.mm.yyyy",
                  }}
                  className={className}
                  sx={{ width: `${birthDateWidth}px` }}
                />
              );
            }}
          />
        </LocalizationProvider>
      </div>
      <div style={{ width: "6ch" }}>
        {isDataSaved && <SavedInfoIndicator error={error} retry={retry} />}
      </div>
    </div>
  );
};
