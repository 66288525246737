import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import "./TimeMatePage.css";

import Header from "../../components/Header";
import TimeSheetCard from "./components/TimesheetCard/TimesheetCard";
import CustomCalendar from "./components/Calendar/Calendar";
import EmployeeTimeSheetTable from "./components/TimesheetDataView/EmployeeTimeSheetTable";
import TimesheetHistoryCard from "./components/HistoryCard/HistoryCardNew";
import { getTimesheetData } from "./helpers";
import { formatDate } from "./helpers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TimeMatePage() {
  const [timesheetData, setTimesheetData] = useState([]);
  const [timesheetDataLoaded, setTimeSheetDataLoaded] = useState(false);
  const [monitorTimesheet, setMonitorTimesheet] = useState(false);
  const [desactivateCalendar, setDesactivateCalendar] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [openRejectionAlert, setOpenRejectionAlert] = useState(false);
  const [rejectionComment, setRejectionComment] = useState("");
  const firstRender = useRef(true);

  const adminState = useSelector((state) => state.admin);
  const timesheetState = useSelector((state) => state.timesheet);

  const dispatch = useDispatch();

  const updateTimeSheetPage = () => {
    setMonitorTimesheet(!monitorTimesheet);
  };

  useEffect(() => {
    updateTimeSheetPage();
  }, [adminState.activeUser]);

  useEffect(() => {
    const fetchTimesheetData = async () => {
      try {
        await getTimesheetData(
          setTimesheetData,
          formatDate(selectedMonth),
          adminState.activeUser
        );
        setTimeSheetDataLoaded((prev) => !prev);
      } catch (error) {
        console.error("Error fetching timesheet data:", error);
      }
    };

    if (selectedMonth && adminState.activeUser) {
      fetchTimesheetData();
    }
  }, [selectedMonth, adminState.activeUser, monitorTimesheet]);
  useEffect(() => {
    const status = timesheetData.map((timesheet, index) => timesheet.status);
    if (status.includes("Rejected")) {
      const rejection_comment = timesheetData.map(
        (timesheet, index) => timesheet.rejection_comment
      );
      setRejectionComment(rejection_comment[0]);
      if (firstRender.current) {
        setOpenRejectionAlert(true);
        firstRender.current = false;
      }
    }
    const calendarState =
      status.includes("Approved") || status.includes("Submitted");
    setDesactivateCalendar(calendarState);
  }, [timesheetDataLoaded, timesheetData, monitorTimesheet]);

  const handleClose = () => {
    setOpenRejectionAlert(false);
  };
  const rejectedDates = timesheetData
    .filter((timesheet) => timesheet.status === "Rejected")
    .map((timesheet) => timesheet.date);
  return (
    <>
      <div className="outer-div-content-wrapper">
        <div className="back-button-navigation-grid-item-1"></div>
        <div className="main-content-wrapper">
          <Header />
          <Dialog
            open={openRejectionAlert}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>Your Timesheet has been rejected!</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {rejectionComment}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button type="submit" variant="contained" onClick={handleClose}>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <div className="timemate-block-container">
            <div className="timemate-upper-block-container">
              <div className="timemate-entry-columns">
                <div className="timemate-history-column">
                  <TimesheetHistoryCard
                    selectedMonth={selectedMonth}
                    setSelectedMonth={setSelectedMonth}
                    updateTimeSheetPage={updateTimeSheetPage}
                    monitorTimesheet={monitorTimesheet}
                  />
                </div>
                <div className="timemate-calendar-column">
                  <CustomCalendar
                    selectedDates={timesheetState.selectedDates}
                    dispatch={dispatch}
                    timesheetData={timesheetData}
                    selectedMonth={selectedMonth}
                    setSelectedMonth={setSelectedMonth}
                    desactivateCalendar={desactivateCalendar}
                    rejectedDates={rejectedDates}
                  />
                </div>
                <div className="timemate-entry-card-column">
                  <TimeSheetCard
                    dates={timesheetState.selectedDates}
                    updateTimeSheetPage={updateTimeSheetPage}
                    isThereAnyRejectedEntries={desactivateCalendar || rejectedDates.length === 0}
                  />
                </div>
              </div>
            </div>
            <div className="timemate-lower-block-container">
              <EmployeeTimeSheetTable
                timesheetData={timesheetData}
                monitorTimesheet={monitorTimesheet}
                updateTimeSheetPage={updateTimeSheetPage}
                enableRowActions={true}
                showIncludeCheckbox={false}
                disableEditing={desactivateCalendar}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TimeMatePage;
