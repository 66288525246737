export const filterData = (key, timesheetData) => {
  const availableOptiopns = ["Pending", "Approved", "Rejected"];
  if (availableOptiopns.includes(key)) {
    return timesheetData.filter((item) => {
      if (key === "Pending") {
        return item.status === "Submitted";
      }  else {
        return item.status === key;
      }
    });
  } else {
    console.error(`Invalid key ${key}. Valid options ${availableOptiopns}`);
    return [];
  }
};
