import React, { useLayoutEffect, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SelectedExperience from "./SelectedExperience";
import {
  handleAddExperience,
  handleDragEnd,
  handleTextChange,
  initializeSelectedExperience,
  removeSelectedExperience,
} from "../../../../features/selectedExperiences";
import {
  handleAddOtherExperience,
  handleDragEndOtherExperiences,
  initializeOtherExperience,
  removeOtherExperience,
} from "../../../../features/otherExperience";
import Button from "@mui/material/Button";
import AddIconButton from "../../../../components/input_components/AddIconButton";
import OtherExperience from "./OtherExperience";
import axiosInstance from "../../../../axios/axiosConfig";
import Languages from "./Languages";
import {
  addLanguageSkill,
  handleDragEndLanguageSkills,
  initalizeLanguageSkills,
} from "../../../../features/languageSkillSlice";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { SavedInfoIndicator } from "../SavedItemIndicator";
import { set } from "date-fns";
import { temporarilyMarkAsSaved, deleteSelectedItem } from "../../utils";
import {
  addSaveKeySkill,
  saveAddOtherSkill,
  saveAddLanguage,
} from "./saveActions";

function DynamicCvSkills() {
  const selectedExperienceState = useSelector(
    (state) => state.selectedexperience
  );

  const otherExperienceState = useSelector((state) => state.otherexperience);
  const languageSkillState = useSelector((state) => state.languageskill);
  const [keyExperiences, setKeyEcxperiences] = useState([]);
  const [savedSkills, setSavedSkills] = useState([]);
  const [savedLanguages, setSavedLanguages] = useState([]);

  const dispatch = useDispatch();

  const handleSelectedExperienceDelete = (id) => {
    axiosInstance
      .delete("/cvapp/skills/", {
        data: {
          id: id,
          type: "Key",
        },
      })
      .then((response) => {
        dispatch(initializeSelectedExperience(response.data));
        setKeyEcxperiences(deleteSelectedItem(id, keyExperiences));
      })
      .catch((error) => {
        console.log("error deleting other experience");
      });
  };

  const handleTextChangeSelectedExperience = (id, text) => {
    dispatch(handleTextChange({ id: id, text: text }));
  };

  const handleMoveToOtherExperience = (id, experienceObject) => {
    // seq nr will be length of current array (remember arrays are zero index)
    let seqNr = otherExperienceState.otherExperiences.length;
    axiosInstance
      .put("/cvapp/skills/", { id: id, skillType: "Other", seqNr: seqNr })
      .then((response) => {
        dispatch(removeSelectedExperience(id));
        dispatch(handleAddOtherExperience(response.data));
        temporarilyMarkAsSaved(response.data.id, savedSkills, setSavedSkills)(null)
      });
  };

  const handleMoveToSelectedExperience = (id, experienceObject) => {
    let seqNr = selectedExperienceState.selectedExperiences.length;
    axiosInstance
      .put("/cvapp/skills/", { id: id, skillType: "Key", seqNr: seqNr })
      .then((response) => {
        dispatch(removeOtherExperience(id));
        dispatch(handleAddExperience(response.data));
        temporarilyMarkAsSaved(
          response.data.id,
          keyExperiences,
          setKeyEcxperiences
        );
      });
  };

  const handleOtherExperienceDelete = (id) => {
    axiosInstance
      .delete("/cvapp/skills/", {
        data: {
          id: id,
          type: "Other",
        },
      })
      .then((response) => {
        dispatch(initializeOtherExperience(response.data));
        setSavedSkills(deleteSelectedItem(id, savedSkills));
      })
      .catch((error) => {
        console.log("error deleting other experience");
      });
  };

  const handleLanguageDelete = (id) => {
    axiosInstance
      .delete("/cvapp/language-skills/", {
        data: {
          id: id,
          // type: "Key",
        },
      })
      .then((response) => {
        dispatch(initalizeLanguageSkills(response.data));
        setSavedLanguages(deleteSelectedItem(id, savedLanguages));
      })
      .catch((error) => {
        console.log("error deleting other experience");
      });
  };

  useLayoutEffect(() => {
    axiosInstance.get("/cvapp/skills/").then((response) => {
      let keySkills = [];
      let otherSkills = [];

      response.data.map((skill) => {
        if (skill.skillType === "Key") {
          keySkills.push(skill);
        } else if (skill.skillType === "Other") {
          otherSkills.push(skill);
        }
      });

      dispatch(initializeOtherExperience(otherSkills));
      dispatch(initializeSelectedExperience(keySkills));
    });

    axiosInstance.get("/cvapp/language-skills").then((response) => {
      dispatch(initalizeLanguageSkills(response.data));
    });
  }, []);

  const handleDragEndKeyExperiences = (results) => {
    const { source, destination } = results;

    // if element is dragged outside droppable destination will be null --> in that case nothing will be updated
    if (!destination) return;

    // if source and destination are the same nothing neeeds to be updated
    if (
      source.droppableId === destination.droppableId &&
      source.index == destination.index
    )
      return;

    // check if drag end event comes from key experiences or skills
    if (results.type === "key-experiences") {
      // first make copy of current state
      const reorderedState = [...selectedExperienceState.selectedExperiences];

      // remove source element from array (source element corresponds to old position in list)
      const [sourceElement] = reorderedState.splice(source.index, 1);

      // add the removed source element back to the array at the new destination
      reorderedState.splice(destination.index, 0, sourceElement);

      dispatch(handleDragEnd(reorderedState));

      // update the sequence number in the database
      reorderedState.map((experience, index) => {
        axiosInstance.put("/cvapp/skills/", {
          id: experience.id,
          seqNr: index,
        });
      });
    } else if (results.type === "other-experiences") {
      // make copy of other experiences
      const reorderedState = [...otherExperienceState.otherExperiences];

      // remove source element from array (source element corresponds to old position in list)
      const [sourceElement] = reorderedState.splice(source.index, 1);

      // add the removed source element back to the array at the new destination
      reorderedState.splice(destination.index, 0, sourceElement);

      dispatch(handleDragEndOtherExperiences(reorderedState));

      // update the sequence number in the database
      reorderedState.map((experience, index) => {
        axiosInstance.put("/cvapp/skills/", {
          id: experience.id,
          seqNr: index,
        });
      });
    } else if (results.type === "language-skills") {
      console.log("we are in language skills results type");
      // make copy of current language skill state
      const reorderedState = [...languageSkillState.languageSkills];

      // remove source element from array (source element corresponds to old position in list)
      const [sourceElement] = reorderedState.splice(source.index, 1);

      // add the removed source element back to the array at the new destination
      reorderedState.splice(destination.index, 0, sourceElement);

      dispatch(handleDragEndLanguageSkills(reorderedState));

      // update the sequence number in the database
      reorderedState.map((language, index) => {
        axiosInstance.put("/cvapp/language-skills/", {
          id: language.id,
          seqNr: index,
        });
      });
    }
  };

  return (
    <div className="bounding-box-cv-summary-section">
      <div className="cv-skills-section-grid-wrapper">
        <div className="left-col-section-title">SKILLS & EXPERIENCE</div>
        <DragDropContext onDragEnd={handleDragEndKeyExperiences}>
          <div className="right-col-key-title">
            KEY EXPERIENCES
            <Droppable
              droppableId="key-experiences-droppable"
              type="key-experiences"
            >
              {(provided) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {selectedExperienceState.selectedExperiences.map(
                      (experience, index) => {
                        return (
                          <Draggable
                            draggableId={experience.id.toString()}
                            key={experience.id}
                            index={index}
                            disableInteractiveElementBlocking={
                              selectedExperienceState.textAreaDraggable
                            }
                          >
                            {(provided) => {
                              return (
                                <div
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                >
                                  <>
                                    <SelectedExperience
                                      key={experience.id}
                                      handleDelete={
                                        handleSelectedExperienceDelete
                                      }
                                      handleMove={handleMoveToOtherExperience}
                                      handleChange={
                                        handleTextChangeSelectedExperience
                                      }
                                      experienceObject={experience}
                                      setKeyEcxperiences={setKeyEcxperiences}
                                      keyExperiences={keyExperiences}
                                      isSaved={keyExperiences.includes(
                                        experience.id
                                      )}
                                    />
                                  </>
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      }
                    )}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
            <div>
              <Button
                variant="contained"
                className="dynamic-cv"
                startIcon={<AddIconButton />}
                onClick={(e) => {
                  addSaveKeySkill({
                    selectedExperienceState,
                    keyExperiences,
                    setKeyEcxperiences,
                    dispatch,
                  });
                }}
                style={{ marginTop: "16px" }}
              >
                Key Experience
              </Button>
            </div>{" "}
          </div>
          <div className="right-col-other-title">
            SKILLS{" "}
            <Droppable
              droppableId="other-experiences-droppable"
              type="other-experiences"
            >
              {(provided) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {otherExperienceState.otherExperiences.map(
                      (experience, index) => {
                        return (
                          <Draggable
                            draggableId={experience.id.toString()}
                            key={experience.id}
                            index={index}
                            disableInteractiveElementBlocking={
                              otherExperienceState.textAreaDraggable
                            }
                          >
                            {(provided) => {
                              return (
                                <div
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                >
                                  <OtherExperience
                                    key={experience.id}
                                    handleDelete={handleOtherExperienceDelete}
                                    experienceObject={experience}
                                    handleMove={handleMoveToSelectedExperience}
                                    setSavedSkills={setSavedSkills}
                                    savedSkills={savedSkills}
                                  />
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      }
                    )}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
            <div>
              <Button
                variant="contained"
                startIcon={<AddIconButton />}
                className="dynamic-cv"
                onClick={(e) => {
                  saveAddOtherSkill({
                    otherExperienceState,
                    savedSkills,
                    setSavedSkills,
                    dispatch,
                  });
                }}
                style={{ marginTop: "16px" }}
              >
                Skill
              </Button>
            </div>
          </div>
          <div className="right-col-language-skills">
            LANGUAGES{" "}
            <Droppable
              droppableId="language-skills-droppable"
              type="language-skills"
            >
              {(provided) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {languageSkillState.languageSkills.map(
                      (language, index) => {
                        return (
                          <Draggable
                            draggableId={language.id.toString()}
                            key={language.id}
                            index={index}
                            disableInteractiveElementBlocking={
                              languageSkillState.textAreaDraggable
                            }
                          >
                            {(provided) => {
                              return (
                                <div
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                >
                                  <Languages
                                    key={language.id}
                                    handleDelete={handleLanguageDelete}
                                    setSavedLanguages={setSavedLanguages}
                                    savedLanguages={savedLanguages}
                                    // handleMove={handleMoveToOtherExperience}
                                    // handleChange={
                                    //   handleTextChangeSelectedExperience
                                    // }
                                    languageObject={language}
                                  />
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      }
                    )}
                    {provided.placeholder}
                  </div>
                );
              }}
              {/* {languageSkillState.languageSkills.map((language) => {
                return (
                  <Languages
                    key={language.id}
                    languageObject={language}
                    handleDelete={handleLanguageDelete}
                  />
                );
              })} */}
            </Droppable>
            <div>
              <Button
                variant="contained"
                startIcon={<AddIconButton />}
                className="dynamic-cv"
                onClick={(e) => {
                  saveAddLanguage({
                    languageSkillState,
                    dispatch,
                    savedLanguages,
                    setSavedLanguages,
                  });
                }}
                style={{ marginTop: "16px" }}
              >
                LANGUAGE
              </Button>
            </div>
          </div>
        </DragDropContext>
      </div>
    </div>
  );
}

export default DynamicCvSkills;
