import axiosInstance from "../../../../axios/axiosConfig";
import { changeDegreeObject } from "../../../../features/cvDegrees";
import { temporarilyMarkAsSaved } from "../../utils";

export const saveDate = ({
  id,
  date,
  savedDegrees,
  setSavedDegrees,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/degrees/", { id, gradDate: date })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedDegrees,
        setSavedDegrees
      )(null);
      dispatch(
        changeDegreeObject({
          id: response.data.id,
          property: "gradDate",
          value: response.data.gradDate,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveDate({
            id,
            date,
            savedDegrees,
            setSavedDegrees,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error updating grad date:", error);
    });

export const saveDegree = ({
  id,
  value,
  savedDegrees,
  setSavedDegrees,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/degrees/", { id, degree: value })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedDegrees,
        setSavedDegrees
      )(null);
      dispatch(
        changeDegreeObject({
          id: response.data.id,
          property: "degree",
          value: response.data.degree,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveDegree({
            id,
            value,
            savedDegrees,
            setSavedDegrees,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error updating degree:", error);
    });

export const saveFieldOfStudy = ({
  id,
  value,
  savedDegrees,
  setSavedDegrees,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/degrees/", { id, fieldOfStudy: value })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedDegrees,
        setSavedDegrees
      )(null);
      dispatch(
        changeDegreeObject({
          id: response.data.id,
          property: "fieldOfStudy",
          value: response.data.fieldOfStudy,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveFieldOfStudy({
            id,
            value,
            savedDegrees,
            setSavedDegrees,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error updating field of study:", error);
    });

export const saveSchool = ({
  id,
  value,
  savedDegrees,
  setSavedDegrees,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/degrees/", { id, school: value })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedDegrees,
        setSavedDegrees
      )(null);
      dispatch(
        changeDegreeObject({
          id: response.data.id,
          property: "school",
          value: response.data.school,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveSchool({
            id,
            value,
            savedDegrees,
            setSavedDegrees,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error updating school:", error);
    });

export const saveIsIncludedInCv = ({
  id,
  value,
  savedDegrees,
  setSavedDegrees,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/degrees/", { id, isIncludedInCv: value })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedDegrees,
        setSavedDegrees
      )(null);
      dispatch(
        changeDegreeObject({
          id: response.data.id,
          property: "isIncludedInCv",
          value: response.data.isIncludedInCv,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveIsIncludedInCv({
            id,
            value,
            savedDegrees,
            setSavedDegrees,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error updating isIncludedInCv:", error);
    });
