import axiosInstance from "../../../../axios/axiosConfig";
import { handlePositionChange } from "../../../../features/internalinfoSlice";
import {
  changeAddressComponent,
  changeContactDetails,
  changeLinkedinUrl,
  changeNationality,
  syncCompleteAddress,
} from "../../../../features/profileinfoSlice";

export const saveFirstName = ({
  dispatch,
  firstName,
  setError,
  setRetry,
  setIsDataSaved,
}) =>
  axiosInstance
    .put("/cvapp/employee-profile/", {
      firstName: firstName,
    })
    .then((response) => {
      setIsDataSaved(true);
      setError(false);
      dispatch(
        changeContactDetails({
          property: "firstName",
          value: response.data.firstName,
        })
      );
    })
    .catch((error) => {
      console.log("error updating first name contact details: ", error);
      setError(true);
      setRetry(() => () => {
        saveFirstName({
          dispatch,
          firstName,
          setError,
          setRetry,
        });
      });
    });

export const saveLastName = ({
  dispatch,
  lastName,
  setError,
  setRetry,
  setIsDataSaved,
}) =>
  axiosInstance
    .put("/cvapp/employee-profile/", {
      lastName: lastName,
    })
    .then((response) => {
      setIsDataSaved(true);
      setError(false);
      dispatch(
        changeContactDetails({
          property: "lastName",
          value: response.data.lastName,
        })
      );
    })
    .catch((error) => {
      setError(true);
      setRetry(() => () => {
        saveLastName({
          dispatch,
          lastName,
          setError,
          setRetry,
        });
      });
      console.log("error updating first name contact details: ", error);
    });

export const savePositionWithinPhilico = ({
  dispatch,
  positionWithinPhilico,
  setError,
  setRetry,
  setIsDataSaved,
}) =>
  axiosInstance
    .put("/cvapp/employee-profile/", {
      positionWithinPhilico: positionWithinPhilico,
    })
    .then((response) => {
      setIsDataSaved(true);
      setError(false);
      dispatch(handlePositionChange(response.data.positionWithinPhilico));
    })
    .catch((error) => {
      console.log("error put marital status request: ", error);
      setError(true);
      setRetry(() => () => {
        savePositionWithinPhilico({
          dispatch,
          positionWithinPhilico,
          setError,
          setRetry,
        });
      });
    });

export const saveNationality = ({
  dispatch,
  value,
  setError,
  setRetry,
  setIsDataSaved,
}) =>
  axiosInstance
    .put("/cvapp/employee-profile/", {
      nationality: value, //we need to transform javascript date object to date string, as Django expects the date in a string format
    })
    .then((response) => {
      setIsDataSaved(true);
      setError(false);
      dispatch(changeNationality(response.data.nationality));
    })
    .catch((error) => {
      console.log("error change nationality: ", error);
      setError(true);
      setRetry(() => () => {
        saveNationality({
          dispatch,
          value,
          setError,
          setRetry,
        });
      });
    });

export const saveBirthDate = ({
  dispatch,
  date,
  setError,
  setRetry,
  setIsDataSaved,
}) =>
  axiosInstance
    .put("/cvapp/employee-profile/", {
      birthDate: date,
    })
    .then((response) => {
      setIsDataSaved(true);
      setError(false);
      dispatch(
        changeContactDetails({
          property: "birthDate",
          value: response.data.birthDate,
        })
      );
    })
    .catch((error) => {
      console.log("error updating birth date contact details: ", error);
      setError(true);
      setRetry(() => () => {
        saveBirthDate({
          dispatch,
          date,
          setError,
          setRetry,
          setIsDataSaved,
        });
      });
    });

export const savePhoneNumber = ({
  dispatch,
  phone,
  countryCode,
  setError,
  setRetry,
  setIsDataSaved,
}) =>
  axiosInstance
    .put("/cvapp/employee-profile/", {
      phone: phone,
      countryCode: countryCode,
    })
    .then((response) => {
      setIsDataSaved(true);
      setError(false);
      dispatch(
        changeContactDetails({
          property: "phone",
          value: response.data.phone,
        })
      );
      dispatch(
        changeContactDetails({
          property: "countryCode",
          value: response.data.countryCode,
        })
      );
    })
    .catch((error) => {
      console.log("error updating first name contact details: ", error);
      setError(true);
      setRetry(() => () => {
        savePhoneNumber({
          dispatch,
          phone,
          countryCode,
          setError,
          setRetry,
        });
      });
    });

export const saveEmail = ({
  dispatch,
  email,
  setError,
  setRetry,
  setIsDataSaved,
}) =>
  axiosInstance
    .put("/cvapp/employee-profile/", {
      email: email,
    })
    .then((response) => {
      setIsDataSaved(true);
      setError(false);
      dispatch(
        changeContactDetails({
          property: "email",
          value: response.data.email,
        })
      );
    })
    .catch((error) => {
      console.log("error updating first name contact details: ", error);
      setError(true);
      setRetry(() => () => {
        saveEmail({
          dispatch,
          email,
          setError,
          setRetry,
        });
      });
    });

export const saveStreet = ({
  dispatch,
  street,
  setError,
  setRetry,
  setIsDataSaved,
}) =>
  axiosInstance
    .put("/cvapp/employee-profile/", {
      street: street,
    })
    .then((response) => {
      setIsDataSaved(true);
      setError(false);
      dispatch(
        changeAddressComponent({
          property: "street",
          value: street,
        })
      );
    })
    .catch((error) => {
      console.log("error updating street: ", error);
      setError(true);
      setRetry(() => () => {
        saveStreet({
          dispatch,
          street,
          setError,
          setRetry,
        });
      });
    });

export const changeStreetNumber = ({
  dispatch,
  streetNumber,
  setError,
  setRetry,
  setIsDataSaved,
}) =>
  axiosInstance
    .put("/cvapp/employee-profile/", {
      streetNr: streetNumber,
    })
    .then((response) => {
      setIsDataSaved(true);
      setError(false);
      dispatch(
        changeAddressComponent({
          property: "streetNr",
          value: streetNumber,
        })
      );
    })
    .catch((error) => {
      console.log("error updating street nr: ", error);
      setError(true);
      setRetry(() => () => {
        changeStreetNumber({
          dispatch,
          streetNumber,
          setError,
          setRetry,
        });
      });
    });

export const saveZipCode = ({
  dispatch,
  zipCode,
  setError,
  setRetry,
  setIsDataSaved,
}) =>
  axiosInstance
    .put("/cvapp/employee-profile/", {
      zip: zipCode,
    })
    .then((response) => {
      setIsDataSaved(true);
      setError(false);
      dispatch(
        changeAddressComponent({
          property: "zip",
          value: zipCode,
        })
      );
    })
    .catch((error) => {
      console.log("error updating zip: ", error);
      setError(true);
      setRetry(() => () => {
        saveZipCode({
          dispatch,
          zipCode,
          setError,
          setRetry,
        });
      });
    });

export const saveCity = ({
  dispatch,
  city,
  setError,
  setRetry,
  setIsDataSaved,
}) =>
  axiosInstance
    .put("/cvapp/employee-profile/", {
      city: city,
    })
    .then((response) => {
      setIsDataSaved(true);
      setError(false);
      dispatch(
        changeAddressComponent({
          property: "city",
          value: city,
        })
      );
    })
    .catch((error) => {
      console.log("error updating city: ", error);
      setError(true);
      setRetry(() => () => {
        saveCity({
          dispatch,
          city,
          setError,
          setRetry,
        });
      });
    });

export const saveCountry = ({
  dispatch,
  country,
  setError,
  setRetry,
  setIsDataSaved,
}) =>
  axiosInstance
    .put("/cvapp/employee-profile/", {
      country: country,
    })
    .then((response) => {
      setIsDataSaved(true);
      setError(false);
      dispatch(
        changeAddressComponent({
          property: "country",
          value: country,
        })
      );
    })
    .catch((error) => {
      console.log("error updating country: ", error);
      setError(true);
      setRetry(() => () => {
        saveCountry({
          dispatch,
          country,
          setError,
          setRetry,
        });
      });
    });

export const saveLinkedinUrl = ({
  dispatch,
  linkedinUrl,
  setError,
  setRetry,
  setIsDataSaved,
}) =>
  axiosInstance
    .put("/cvapp/employee-profile/", {
      linkedinUrl: linkedinUrl, //we need to transform javascript date object to date string, as Django expects the date in a string format
    })
    .then((response) => {
      setIsDataSaved(true);
      setError(false);
      dispatch(changeLinkedinUrl(response.data.linkedinUrl));
    })
    .catch((error) => {
      console.log("error change linkedInUrl: ", error);
      setError(true);
      setRetry(() => () => {
        saveLinkedinUrl({
          dispatch,
          linkedinUrl,
          setError,
          setRetry,
        });
      });
    });

export const saveAddress = ({
  dispatch,
  street,
  streetNr,
  city,
  country,
  addressComplete,
  setError,
  setRetry,
  setIsDataSaved
}) => {
  try {
    setError(false);
    setTimeout(() => {
      // we set timeout in order to give google places time to update;
      // in case that the entered address and individual fields are not the same it means they are out of sync; in that case we reset them
      if (street) {
        const constructedAddress = [
          street.trim(),
          streetNr,
          city,
          country,
        ].join(", ");
        setIsDataSaved(true);
        if (constructedAddress !== addressComplete) {
          console.log("we reset address:");
          dispatch(syncCompleteAddress("syncing"));
        }
      }
    }, 300);
  } catch (e) {
    setError(true);
    setRetry(() => () => {
      saveAddress({
        dispatch,
        street,
        streetNr,
        city,
        country,
        addressComplete,
        setError,
        setRetry,
      });
    });
  }
};
