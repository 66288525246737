import React, { useState, useLayoutEffect, useEffect } from "react";
import Button from "@mui/material/Button";
import AddIconButton from "../../../../components/input_components/AddIconButton";
import { useSelector, useDispatch } from "react-redux";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import binIcon from "../../../../assets/bin_icon_large_24_24.svg";
import {
  changeCvSummary,
  initializeCvSummary,
} from "../../../../features/cvSummarySlice";
import axiosInstance from "../../../../axios/axiosConfig";
import { SavedInfoIndicator } from "../SavedItemIndicator";
import { saveAddSummary, saveSummary } from "./saveActions";

function DynamicCvSummary() {
  const internalInfoState = useSelector((state) => state.internalinfo);
  const cvSummaryState = useSelector((state) => state.cvsummary);
  const dispatch = useDispatch();

  const [isSummarySaved, setIsSummarySaved] = useState(false);
  const [summary, setSummary] = useState([]);
  const [addSummaryClicked, setAddSummaryClicked] = useState(false);
  const [error, setError] = useState(false);
  const [retry, setRetry] = useState(() => {});
  
  useEffect(() => {
    setTimeout(() => {
      setIsSummarySaved(false);
    }, 2000);
  }, [isSummarySaved]);

  useLayoutEffect(() => {
    localStorage.setItem(
      "cv-type",
      internalInfoState.isGeneralCV ? "General" : "Avaloq"
    );

    axiosInstance
      .get("/cvapp/cv-summaries/")
      .then((response) => {
        dispatch(initializeCvSummary(response.data));
      })
      .catch((error) => console.log("Error fetching CV summaries:", error));
  }, []);

  useEffect(() => {
    setSummary(
      cvSummaryState.cvSummary.length > 0 ? cvSummaryState.cvSummary : []
    );
  }, [cvSummaryState]);

  return (
    <div className="bounding-box-cv-summary-section">
      <div className="cv-summary-section-grid-wrapper">
        <div className="left-col-section-title">SUMMARY</div>
        <div>
          {summary.length > 0 || addSummaryClicked ? (
            <div className="cv-summary-content-flexbox-wrapper">
              <OutlinedInput
                className="outlinedinput-dynamic-cv flex-item-1"
                placeholder="Enter your CV summary here ..."
                multiline
                value={summary.length > 0 ? summary[0].summary : ""}
                onChange={(e) => {
                  dispatch(changeCvSummary(e.target.value));
                }}
                onBlur={(e) => {
                  if (summary.length > 0) {
                    saveSummary({
                      id: summary[0].id,
                      value: e.target.value,
                      setIsSummarySaved,
                      dispatch,
                      setError,
                      setRetry
                    });
                  }
                }}
                onFocus={(e) => {
                  console.log("Focused on summary field");
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
              {isSummarySaved && <SavedInfoIndicator error={error} retry={retry} />}
              <Tooltip title="Delete Summary" arrow>
                <IconButton
                  style={{ maxHeight: "42px", marginTop: "-8px" }}
                  onClick={() => {
                    if (summary.length > 0) {
                      axiosInstance
                        .delete("/cvapp/cv-summaries/", {
                          data: { id: summary[0].id },
                        })
                        .then(() => {
                          dispatch(initializeCvSummary([]));
                          setSummary([]);
                          setAddSummaryClicked(false);
                        })
                        .catch((error) =>
                          console.log("Error deleting summary:", error)
                        );
                    }
                  }}
                >
                  <img src={binIcon} alt="Delete" />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <Button
              variant="contained"
              startIcon={<AddIconButton />}
              className="dynamic-cv"
              onClick={() => {
                saveAddSummary({
                  setAddSummaryClicked,
                  setSummary,
                  dispatch,
                  setError,
                  setRetry
                });
              }}
            >
              Add Summary
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default DynamicCvSummary;
