import React, { useLayoutEffect, useState } from "react";
import Button from "@mui/material/Button";
import AddIconButton from "../../../../components/input_components/AddIconButton";
import ProjectExperience from "./ProjectExperience";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../../../axios/axiosConfig";
import {
  initalizeExperiences,
  addExperience,
} from "../../../../features/cvProjectExperience";
import { deleteSelectedItem } from "../../utils";
import { saveAddExperience } from "./saveActions";

function DynamicCvProjectExperience() {
  const projectExperienceState = useSelector(
    (state) => state.projectExperience
  );

  // create dispatch variable to access state reducers later
  const dispatch = useDispatch();
  const [savedProjectExperiencesIds, setSavedProjectExperiencesIds] = useState(
    []
  );
  useLayoutEffect(() => {
    axiosInstance.get("/cvapp/experiences/").then((response) => {
      dispatch(initalizeExperiences(response.data));
    });
  }, []);

  const handleDelete = (id) => {
    axiosInstance
      .delete("/cvapp/experiences/", {
        data: {
          id: id,
        },
      })
      .then((response) => {
        dispatch(initalizeExperiences(response.data));
        setSavedProjectExperiencesIds(
          deleteSelectedItem(id, savedProjectExperiencesIds)
        );
      })
      .catch((error) => {
        console.log("error deleting experience");
      });
  };

  return (
    <div className="bounding-box-cv-summary-section">
      <div className="cv-project-experience-section-grid-wrapper">
        <div className="left-col-section-title">PROJECT EXPERIENCE</div>
        <div>
          {projectExperienceState.experiences.map((experience, index) => {
            return (
              <ProjectExperience
                key={experience.id}
                index={index}
                // projectTitle={experience.projectTitle}
                projectObject={experience}
                handleDelete={handleDelete}
                experienceObject={experience}
                setSavedProjectExperiencesIds={setSavedProjectExperiencesIds}
                savedProjectExperiencesIds={savedProjectExperiencesIds}
                isSaved={savedProjectExperiencesIds.includes(experience.id)}
              />
            );
          })}
          <div>
            <Button
              variant="contained"
              startIcon={<AddIconButton />}
              className="dynamic-cv"
              onClick={(e) => {
                saveAddExperience({
                  addExperience,
                  dispatch,
                  savedProjectExperiencesIds,
                  setSavedProjectExperiencesIds,
                });
              }}
            >
              Experience
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DynamicCvProjectExperience;
