import React, { useRef, useState, useLayoutEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import binIcon from "../../../../assets/bin_icon_large_24_24.svg";
import { useSelector, useDispatch } from "react-redux";
import { changeDegreeObject } from "../../../../features/cvDegrees";
import { parse } from "date-fns";
import moment from "moment";
import { SavedInfoIndicator } from "../SavedItemIndicator";
import {
  saveDate,
  saveDegree,
  saveFieldOfStudy,
  saveIsIncludedInCv,
  saveSchool,
} from "./saveActions";

function Degree(props) {
  const degreesState = useSelector((state) => state.degrees);

  const dispatch = useDispatch();

  const degreeRef = useRef(null);
  const fieldOfStudyRef = useRef(null);
  const schoolRef = useRef(null);
  const schoolLocationRef = useRef(null);
  const gradDateRef = useRef(null);

  const [degreeWidth, setDegreeWidth] = useState(0);
  const [fieldOfStudyWidth, setFieldOfStudyWidth] = useState(0);
  const [schoolWidth, setSchoolWidth] = useState(0);
  const [schoolLocationWidth, setSchoolLocationWidth] = useState(0);
  const [gradDateWidth, setGradDateWidth] = useState(0);
  const [error, setError] = useState(false);
  const [retry, setRetry] = useState(() => () => {});
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setGradDateWidth(gradDateRef.current.offsetWidth + 4 + 1);
      setSchoolLocationWidth(schoolLocationRef.current.offsetWidth + 4 + 1);
      setSchoolWidth(schoolRef.current.offsetWidth + 4 + 1);
      setDegreeWidth(degreeRef.current.offsetWidth + 4 + 1);
      setFieldOfStudyWidth(fieldOfStudyRef.current.offsetWidth + 4 + 1);
    }, 150);

    setGradDateWidth(gradDateRef.current.offsetWidth + 4 + 1);
    setSchoolLocationWidth(schoolLocationRef.current.offsetWidth + 4 + 1);
    setSchoolWidth(schoolRef.current.offsetWidth + 4 + 1);
    setDegreeWidth(degreeRef.current.offsetWidth + 4 + 1);
    setFieldOfStudyWidth(fieldOfStudyRef.current.offsetWidth + 4 + 1); //4 is the padding-left + padding-right
    return () => clearTimeout(timer);
  }, [degreesState.degrees]);

  return (
    <>
      <div className="flexbox-wrapper-project-experience">
        <div className="grid-wrapper-project-experience">
          <div className="grid-item-date-col">
            <div className="flexbox-date-col-wrapper">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  className="outlinedinput-dynamic-cv"
                  inputFormat="MM.yyyy"
                  mask="__.____"
                  disableMaskedInput={false}
                  value={parse(
                    props.degreeObject.gradDate,
                    "MM.yyyy",
                    new Date()
                  )}
                  onChange={(newValue) => {
                    let transformedDate = moment(newValue).format("MM.YYYY");
                    if (transformedDate !== "Invalid date") {
                      saveDate({
                        id: props.degreeObject.id,
                        date: transformedDate,
                        savedDegrees: props.savedDegrees,
                        setSavedDegrees: props.setSavedDegrees,
                        dispatch: dispatch,
                        setError: setError,
                        setRetry: (retryFunction) => {
                          setRetry(() => retryFunction());
                        },
                      });
                    }
                  }}
                  disableOpenPicker
                  // helperText="dd.mm.yyyy"
                  // placeholder="dd.mm.yyyy"
                  renderInput={(params) => {
                    //add style prop to increase font-size
                    return (
                      <OutlinedInput
                        className="outlinedinput-dynamic-cv"
                        InputProps={params.InputProps}
                        // onFocus={(e) => {
                        //   e.preventDefault();
                        // }}
                        {...params}
                        // label="birthdate"
                        inputRef={params.inputRef}
                        //   helperText="dd.mm.yyyy"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "mm.yyyy",
                        }}
                        onChange={(e) => {
                          console.log("e.target.value ", e.target.value);
                          console.log("e", e);
                        }}
                        sx={{ width: `${gradDateWidth}px` }}
                      />
                    );
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="experience-title">
            <OutlinedInput
              placeholder="Degree"
              className="outlinedinput-dynamic-cv"
              sx={{ width: `${degreeWidth}px`, maxWidth: "100%" }}
              value={props.degreeObject.degree}
              onChange={(e) => {
                dispatch(
                  changeDegreeObject({
                    id: props.degreeObject.id,
                    property: "degree",
                    value: e.target.value,
                  })
                );
              }}
              onBlur={(e) => {
                saveDegree({
                  id: props.degreeObject.id,
                  value: e.target.value,
                  savedDegrees: props.savedDegrees,
                  setSavedDegrees: props.setSavedDegrees,
                  dispatch: dispatch,
                  setError: setError,
                  setRetry: (retryFunction) => {
                    setRetry(() => retryFunction());
                  },
                });
              }}
              multiline
            />
            <span>, </span>
            <OutlinedInput
              placeholder="Field of Study"
              className="outlinedinput-dynamic-cv"
              sx={{ width: `${fieldOfStudyWidth}px`, maxWidth: "100%" }}
              value={props.degreeObject.fieldOfStudy}
              onChange={(e) => {
                dispatch(
                  changeDegreeObject({
                    id: props.degreeObject.id,
                    property: "fieldOfStudy",
                    value: e.target.value,
                  })
                );
              }}
              onBlur={(e) => {
                saveFieldOfStudy({
                  id: props.degreeObject.id,
                  value: e.target.value,
                  savedDegrees: props.savedDegrees,
                  setSavedDegrees: props.setSavedDegrees,
                  dispatch: dispatch,
                  setError: setError,
                  setRetry: (retryFunction) => {
                    setRetry(() => retryFunction());
                  },
                });
              }}
              multiline
            />
          </div>
          <div className="experience-subtitle">
            <OutlinedInput
              placeholder="School"
              className="outlinedinput-dynamic-cv"
              sx={{ width: `${schoolWidth}px`, maxWidth: "100%" }}
              value={props.degreeObject.school}
              onChange={(e) => {
                dispatch(
                  changeDegreeObject({
                    id: props.degreeObject.id,
                    property: "school",
                    value: e.target.value,
                  })
                );
              }}
              onBlur={(e) => {
                saveSchool({
                  id: props.degreeObject.id,
                  value: e.target.value,
                  savedDegrees: props.savedDegrees,
                  setSavedDegrees: props.setSavedDegrees,
                  dispatch: dispatch,
                  setError: setError,
                  setRetry: (retryFunction) => {
                    setRetry(() => retryFunction());
                  },
                });
              }}
              multiline
            />
            <span>, </span>
            <OutlinedInput
              placeholder="Location"
              className="outlinedinput-dynamic-cv"
              multiline
              sx={{ width: `${schoolLocationWidth}px`, maxWidth: "100%" }}
              value={props.degreeObject.schoolLocation}
              onChange={(e) => {
                dispatch(
                  changeDegreeObject({
                    id: props.degreeObject.id,
                    property: "schoolLocation",
                    value: e.target.value,
                  })
                );
              }}
              onBlur={(e) => {
                saveSchool({
                  id: props.degreeObject.id,
                  value: e.target.value,
                  savedDegrees: props.savedDegrees,
                  setSavedDegrees: props.setSavedDegrees,
                  dispatch: dispatch,
                  setError: setError,
                  setRetry: (retryFunction) => {
                    setRetry(() => retryFunction());
                  },
                });
              }}
            />
          </div>
          {/* <div className="experience-description">
            <OutlinedInput
              placeholder="Project description"
              className="outlinedinput-dynamic-cv"
              multiline
              //   value={lastName}
              sx={{ width: "100%" }}
              //   onChange={(e) => {
              //     setLastName(e.target.value);
              //   }}
            />
          </div> */}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div>
              {props.isSaved && (
                <SavedInfoIndicator error={error} retry={retry} />
              )}
            </div>
            <div>
              <Tooltip
                title="Include in CV"
                arrow
                //   open={!isToolTipDisabled && isHover}
              >
                <Checkbox
                  checked={props.degreeObject.isIncludedInCv}
                  onChange={(e) => {
                    saveIsIncludedInCv({
                      id: props.degreeObject.id,
                      value: e.target.checked,
                      savedDegrees: props.savedDegrees,
                      setSavedDegrees: props.setSavedDegrees,
                      dispatch: dispatch,
                      setError: setError,
                      setRetry: (retryFunction) => {
                        setRetry(() => retryFunction());
                      },
                    });
                  }}
                />
              </Tooltip>
            </div>
            <div>
              <Tooltip
                title="Delete Degree"
                arrow
                //   open={!isToolTipDisabled && isHover}
              >
                <IconButton
                  onClick={(e) => {
                    props.handleDelete(props.degreeObject.id);
                  }}
                >
                  <img src={binIcon} alt="bin icon" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <div ref={gradDateRef} id="grad-date-measure">
        {props.degreeObject.gradDate ? props.degreeObject.gradDate : "mm.yyyy"}
      </div>
      <div ref={degreeRef} id="degree-measure">
        {props.degreeObject.degree ? props.degreeObject.degree : "Degree"}
      </div>
      <div ref={fieldOfStudyRef} id="field-of-study-measure">
        {props.degreeObject.fieldOfStudy
          ? props.degreeObject.fieldOfStudy
          : "Field of Study"}
      </div>
      <div ref={schoolRef} id="school-measure">
        {props.degreeObject.school ? props.degreeObject.school : "School"}
      </div>
      <div ref={schoolLocationRef} id="school-location-measure">
        {props.degreeObject.schoolLocation
          ? props.degreeObject.schoolLocation
          : "Location"}
      </div>
    </>
  );
}

export default Degree;
