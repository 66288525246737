import CheckIcon from "@mui/icons-material/Check";
import ReplayIcon from "@mui/icons-material/Replay";

export const SavedInfoIndicator = ({ error, retry }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        fontSize: "10px",
      }}
    >
      {error && retry ? (
        <div
          style={{
            color: "red",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
          }}
          onClick={() => {
            retry();
          }}
        >
          <ReplayIcon style={{ height: "0.5em", width: "0.5em" }} />
          Retry?
        </div>
      ) : (
        <div
          style={{
            color: "green",
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
          }}
        >
          <CheckIcon style={{ height: "0.5em", width: "0.5em" }} />
          Saved
        </div>
      )}
    </div>
  );
};
