// To be done: Date is stored in wrong format in the backend, need to understand why
import moment from "moment";

export const selectClassName = (status) => {
  const statusLower = status?.toLowerCase();
  if (statusLower === "approved") {
    return ["approved-timesheet"];
  } else if (statusLower === "rejected") {
    return ["rejected-timesheet"];
  } else if (statusLower === "submitted") {
    return ["submitted-timesheet"];
  } else if (statusLower === "pending") {
    return ["pending-timesheet"];
  } else if (statusLower === "edited") {
    return ["edited-timesheet"];
  }
  return null;
};

const selectPreferedStatus = (statuses) => {
  if (statuses.includes("Pending")) {
    return "pending";
  }
  if (statuses.includes("Edited")) {
    return "edited";
  } else if (statuses.includes("Rejected")) {
    return "rejected";
  } else if (statuses.includes("Approved")) {
    return "approved";
  } else if (statuses.includes("Submitted")) {
    return "submitted";
  }
};

export function getTileClassNameFunction(
  timesheetData,
  monthStatus = "Pending"
) {
  // Function to aggregate status for a date
  const getStatusForDate = (date) => {
    const formatDate = moment(date).format("DD-MM-yyyy");

    if (!date) {
      return null;
    }

    const statuses = timesheetData
      .map((data) => {
        if (data.date === formatDate) {
          if (data) {
            return data.status;
          }
        }
      })
      .filter((item) => item !== undefined);

    return selectPreferedStatus(statuses);
  };

  return function tileClassName({ date, view }) {
    if (view === "month") {
      const status = getStatusForDate(date);
      return selectClassName(status);
    }
    return null;
  };
}
