import { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";

import EventBusyIcon from "@mui/icons-material/EventBusy";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { getTileClassNameFunction } from "./tileColorSelection";
import { Grid, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";

import "react-calendar/dist/Calendar.css";
import "./Calendar.css";

import { formatDate } from "../../helpers";
import {
  setIsWeekendEnabled,
  setSelectedDates,
} from "../../../../features/timesheetSlice";

import { getMonthStatus } from "../../helpers";

function addMonths(date, months) {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + months);
  return newDate;
}

function CustomCalendar({
  selectedDates,
  dispatch,
  timesheetData,
  selectedMonth,
  setSelectedMonth,
  desactivateCalendar,
  rejectedDates,
}) {
  const [monthStatus, setMonthStatus] = useState("");
  const timesheetState = useSelector((state) => state.timesheet);
  const selectRange = true;
  const isItWeekend = (date) => [0, 6].includes(date.getDay());
  const handleDateChange = (newDate) => {
    if (selectRange) {
      dispatch(setSelectedDates(newDate));
    } else {
      dispatch(setSelectedDates([newDate, newDate]));
    }
  };

  useEffect(() => {
    if (selectedMonth instanceof Date) {
      getMonthStatus({
        month: selectedMonth.getMonth() + 1, // Convert zero-based month to one-based
        year: selectedMonth.getFullYear(),
      })
        .then((response) => {
          setMonthStatus(response.month_status);
        })
        .catch((error) => {
          console.error("Error fetching month status:", error);
        });
    } else {
      console.error("selectedMonth is not a valid Date object");
    }
  }, [selectedMonth, desactivateCalendar]);

  const myCalendarRef = useRef();

  const nextMonthCallback = () => {
    setSelectedMonth(addMonths(selectedMonth, 1));
  };

  const prevMonthCallback = () => {
    setSelectedMonth(addMonths(selectedMonth, -1));
  };

  const handleWeekendActivation = (value, event) => {
    dispatch(setIsWeekendEnabled(!timesheetState.isWeekendEnabled));
  };

  return (
    <div className="calendar-container">
      <Grid container columnSpacing={0} rowSpacing={1} alignItems="center">
        <Grid item xs={2}>
          <IconButton onClick={prevMonthCallback}>
            <NavigateBeforeIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <div className="react-calendar-date-holder">
            {formatDate(selectedMonth)}
          </div>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={nextMonthCallback}>
            <NavigateNextIcon />
          </IconButton>
        </Grid>
        <Grid item xs={2}>
          <Tooltip
            title={
              timesheetState.isWeekendEnabled
                ? "Disable Weekend entries"
                : "Enable Weekend entries"
            }
            arrow
            placement="top"
          >
            <IconButton
              onClick={handleWeekendActivation}
              className="icon-button-blue"
            >
              {timesheetState.isWeekendEnabled ? (
                <EventAvailableIcon />
              ) : (
                <EventBusyIcon />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Calendar
        onChange={handleDateChange}
        value={selectedDates}
        selectRange={selectRange}
        allowPartialRange={true}
        showFixedNumberOfWeeks={false}
        maxDetail={"month"}
        inputRef={myCalendarRef}
        tileClassName={getTileClassNameFunction(timesheetData, monthStatus)}
        tileDisabled={({ date }) => {
          const formattedDate = new Intl.DateTimeFormat("en-GB")
            .format(date)
            .replace(/\//g, "-");
          if (!timesheetState.isWeekendEnabled) {
            if (isItWeekend(date)) {
              return true;
            }
            if (rejectedDates.includes(formattedDate)) {
              return false;
            }
          }
          return desactivateCalendar;
        }}
        showNeighboringMonth={false}
        activeStartDate={selectedMonth}
      />
    </div>
  );
}

export default CustomCalendar;
