import React, { useLayoutEffect } from "react";
import Button from "@mui/material/Button";
import AddIconButton from "../../../../components/input_components/AddIconButton";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../../../axios/axiosConfig";
import Certificate from "./Certificate";
import {
  addCertificate,
  initalizeCertificates,
} from "../../../../features/cvCertificates";
import { deleteSelectedItem } from "../../utils";
import { saveAddCertification } from "./saveActions";

function DynamicCvCertificates() {
  const certificatesState = useSelector((state) => state.certificates);
  const [savedCertificates, setSavedCertificates] = React.useState([]);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    axiosInstance.get("/cvapp/certificates/").then((response) => {
      dispatch(initalizeCertificates(response.data));
    });
  }, []);

  const handleDelete = (id) => {
    axiosInstance
      .delete("/cvapp/certificates/", {
        data: {
          id: id,
        },
      })
      .then((response) => {
        dispatch(initalizeCertificates(response.data));
        setSavedCertificates(deleteSelectedItem(savedCertificates, id));
      })
      .catch((error) => {
        console.log("error deleting experience");
      });
  };

  return (
    <div className="bounding-box-certificate-section">
      <div className="cv-project-experience-section-grid-wrapper">
        {" "}
        <div className="left-col-section-title">CERTIFICATIONS</div>
        <div>
          {/* <Certificate /> */}
          {certificatesState.certificates.map((certificate) => {
            return (
              <Certificate
                key={certificate.id}
                certificateObject={certificate}
                handleDelete={handleDelete}
                savedCertificates={savedCertificates}
                setSavedCertificates={setSavedCertificates}
                isSaved={savedCertificates.includes(certificate.id)}
              />
            );
          })}
          <div>
            <Button
              variant="contained"
              startIcon={<AddIconButton />}
              className="dynamic-cv"
              onClick={(e) => {
                saveAddCertification({
                  addCertificate,
                  dispatch,
                  savedCertificates,
                  setSavedCertificates,
                });
              }}
            >
              CERTIFICATION
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DynamicCvCertificates;
