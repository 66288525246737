import React, { useState } from "react";
import binIcon from "../../../../assets/bin_icon_large_24_24.svg";
import arrowDownIcon from "../../../../assets/arrow_down_icon.svg";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import bulletPoint from "../../../../assets/bullet_point_cv.svg";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";
import {
  changeTextAreaFocused,
  handleKeySkillObjectChange,
} from "../../../../features/selectedExperiences";
import axiosInstance from "../../../../axios/axiosConfig";
import { SavedInfoIndicator } from "../SavedItemIndicator";
import { temporarilyMarkAsSaved } from "../../utils";
import { saveIsKeySkillIncluded, saveKeyExperience } from "./saveActions";

function SelectedExperience(props) {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [retry, setRetry] = useState(null);
  return (
    <div style={{display: "flex", justifyContent:"center", alignItems: "center", marginTop: "8px"}}>
      <img
        src={bulletPoint}
        alt="bullet point"
        style={{
          scale: "0.8",
          marginLeft: "5px",
          marginRight: "12px",
          alignSelf: "flex-start",
          marginTop: "7px",
        }}
      />
      <OutlinedInput
        className="outlinedinput-dynamic-cv"
        value={props.experienceObject.skill}
        onClick={(e) => {
          dispatch(changeTextAreaFocused(false));
          const { target } = e;
          target.focus();
        }}
        onChange={(e) => {
          dispatch(
            handleKeySkillObjectChange({
              id: props.experienceObject.id,
              property: "skill",
              value: e.target.value,
            })
          );
        }}
        onBlur={(e) => {
          dispatch(changeTextAreaFocused(true));
          saveKeyExperience({
            id: props.experienceObject.id,
            value: e.target.value,
            keyExperiences: props.keyExperiences,
            setKeyEcxperiences: props.setKeyEcxperiences,
            dispatch,
            setError,
            setRetry,
          });
        }}
        sx={{ width: "90%" }}
        placeholder="write your key experience here ..."
        multiline
      />
      <div>
        {props.isSaved && <SavedInfoIndicator error={error} retry={retry} />}
      </div>
      <Tooltip title="Move to Skills" arrow>
        <IconButton
          style={{ marginTop: "-8px", maxHeight: "42px" }}
          onClick={() => {
            props.handleMove(props.experienceObject.id, props.experienceObject);
          }}
        >
          <img src={arrowDownIcon} alt="arrow down icon" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Include in CV" arrow>
        <Checkbox
          checked={props.experienceObject.isIncludedInCv}
          onChange={(e) => {
            saveIsKeySkillIncluded({
              id: props.experienceObject.id,
              checked: e.target.checked,
              keyExperiences: props.keyExperiences,
              setKeyEcxperiences: props.setKeyEcxperiences,
              dispatch,
              setError,
              setRetry: (retryFunction) => {
                setRetry(() => retryFunction());
              },
            });
          }}
          style={{ maxHeight: "42px", marginTop: "-8px" }}
        />
      </Tooltip>
      <Tooltip
        title="Delete key experience"
        arrow
        //   open={!isToolTipDisabled && isHover}
      >
        <IconButton
          style={{ marginTop: "-8px", maxHeight: "42px" }}
          onClick={() => {
            props.handleDelete(props.experienceObject.id);
          }}
        >
          <img src={binIcon} alt="bin icon" />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default SelectedExperience;
