import axiosInstance from "../../../../axios/axiosConfig";
import {
  addLanguageSkill,
  changeLanguageObject,
} from "../../../../features/languageSkillSlice";
import {
  changeOtherExperienceObject,
  handleAddOtherExperience,
} from "../../../../features/otherExperience";
import {
  handleAddExperience,
  handleKeySkillObjectChange,
} from "../../../../features/selectedExperiences";
import { temporarilyMarkAsSaved } from "../../utils";

export const addSaveKeySkill = ({
  selectedExperienceState,
  keyExperiences,
  setKeyEcxperiences,
  dispatch,
  setError=() => {},
  setRetry=() => {},
}) => {
  setError(false);
  axiosInstance
    .post("/cvapp/skills/", {
      skill: "",
      skillType: "Key",
      seqNr: selectedExperienceState.selectedExperiences.length || 0,
    })
    .then((response) => {
      setError(false);
      dispatch(handleAddExperience(response.data));
      temporarilyMarkAsSaved(
        response.data.id,
        keyExperiences,
        setKeyEcxperiences
      )(null);
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          addSaveKeySkill({
            selectedExperienceState,
            keyExperiences,
            setKeyEcxperiences,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error adding key skill:", error);
    });
};

export const saveKeyExperience = ({
  id,
  value,
  keyExperiences,
  setKeyEcxperiences,
  dispatch,
  setError,
  setRetry,
}) => {
  setError(false);
  axiosInstance
    .put("/cvapp/skills/", { id, skill: value })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        keyExperiences,
        setKeyEcxperiences
      )(null);
      dispatch(
        handleKeySkillObjectChange({
          id,
          property: "skill",
          value: response.data.skill,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveKeyExperience({
            id,
            value,
            keyExperiences,
            setKeyEcxperiences,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error updating key experience:", error);
    });
};

export const saveIsKeySkillIncluded = ({
  id,
  checked,
  keyExperiences,
  setKeyEcxperiences,
  dispatch,
  setError,
  setRetry,
}) => {
  setError(false);
  axiosInstance
    .put("/cvapp/skills/", { id, isIncludedInCv: checked })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        keyExperiences,
        setKeyEcxperiences
      )(null);
      dispatch(
        handleKeySkillObjectChange({
          id: response.data.id,
          property: "isIncludedInCv",
          value: response.data.isIncludedInCv,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveIsKeySkillIncluded({
            id,
            checked,
            keyExperiences,
            setKeyEcxperiences,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error updating isIncludedInCv for key skill:", error);
    });
};

export const saveAddOtherSkill = ({
  otherExperienceState,
  savedSkills,
  setSavedSkills,
  dispatch,
  setError=() => {},
  setRetry=() => {},
}) => {
  setError(false);
  axiosInstance
    .post("/cvapp/skills/", {
      skill: "",
      skillType: "Other",
      seqNr: otherExperienceState.otherExperiences.length || 0,
    })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(response.data.id, savedSkills, setSavedSkills)(null);
      dispatch(handleAddOtherExperience(response.data));
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveAddOtherSkill({
            otherExperienceState,
            savedSkills,
            setSavedSkills,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error adding other experience:", error);
    });
};

export const saveChangeOtherSkill = ({
  id,
  value,
  dispatch,
  savedSkills,
  setSavedSkills,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/skills/", { id, skill: value })
    .then((response) => {
      setError(false);
      console.log("response.data", response.data, id, value, savedSkills);
      temporarilyMarkAsSaved(response.data.id, savedSkills, setSavedSkills)(null);
      dispatch(
        changeOtherExperienceObject({
          id,
          property: "skill",
          value: response.data.skill,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveChangeOtherSkill({
            id,
            value,
            dispatch,
            savedSkills,
            setSavedSkills,
            setError,
            setRetry,
          })
      );
      console.error("Error updating other skill:", error);
    });

export const saveIsOtherSkillIncluded = ({
  id,
  checked,
  savedSkills,
  setSavedSkills,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/skills/", { id, isIncludedInCv: checked })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(response.data.id, savedSkills, setSavedSkills)(null);
      dispatch(
        changeOtherExperienceObject({
          id: response.data.id,
          property: "isIncludedInCv",
          value: response.data.isIncludedInCv,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveIsOtherSkillIncluded({
            id,
            checked,
            savedSkills,
            setSavedSkills,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error updating isIncludedInCv for other skill:", error);
    });

export const saveAddLanguage = ({
  languageSkillState,
  dispatch,
  savedLanguages,
  setSavedLanguages,
  setError=() => {},
  setRetry=() => {},
}) =>
  axiosInstance
    .post("/cvapp/language-skills/", {
      language: "",
      languageSkill: "",
      seqNr: languageSkillState.languageSkills.length || 0,
    })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedLanguages,
        setSavedLanguages
      )(null);
      dispatch(addLanguageSkill(response.data));
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveAddLanguage({
            languageSkillState,
            dispatch,
            savedLanguages,
            setSavedLanguages,
            setError,
            setRetry,
          })
      );
      console.error("Error adding language skill:", error);
    });

export const saveLanguage = ({
  id,
  value,
  savedLanguages,
  setSavedLanguages,
  dispatch,
  setError,
  setRetry,
}) => {
  setError(false);
  axiosInstance
    .put("/cvapp/language-skills/", { id, language: value })
    .then((response) => {
      setError(false);
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedLanguages,
        setSavedLanguages
      )(null);
      dispatch(
        changeLanguageObject({
          id: response.data.id,
          property: "language",
          value: response.data.language,
        })
      );
    })
    .catch((error) => {
      setError(true);
      console.log("error updating language: ", error);

      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveLanguage({
            id,
            value,
            savedLanguages,
            setSavedLanguages,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error updating language:", error);
    });
};

export const saveLanguageSkill = ({
  id,
  value,
  savedLanguages,
  setSavedLanguages,
  dispatch,
  setError,
  setRetry,
}) => {
  setError(false);
  axiosInstance
    .put("/cvapp/language-skills/", { id, languageSkill: value })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedLanguages,
        setSavedLanguages
      )(null);
      dispatch(
        changeLanguageObject({
          id: response.data.id,
          property: "languageSkill",
          value: response.data.languageSkill,
        })
      );
    })
    .catch((error) => {
      setError(true);
      console.log("error updating language skill ww: ", error);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveLanguageSkill({
            id,
            value,
            savedLanguages,
            setSavedLanguages,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error updating language skill oo:", error);
    });
};

export const saveIsItIncluded = ({
  id,
  checked,
  savedLanguages,
  setSavedLanguages,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/language-skills/", { id, isIncludedInCv: checked })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedLanguages,
        setSavedLanguages
      )(null);
      dispatch(
        changeLanguageObject({
          id: response.data.id,
          property: "isIncludedInCv",
          value: response.data.isIncludedInCv,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveIsItIncluded({
            id,
            checked,
            savedLanguages,
            setSavedLanguages,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error updating isIncludedInCv for language skill:", error);
    });
