import React, { useState, useRef, useLayoutEffect } from "react";
import "../common.css";
import AvatarCvPic from "../../../../assets/avatar_cv_pic.svg";
import CircularProgress from "@mui/material/CircularProgress";
import philicoLogoWhite from "../../../../assets/PhilicoLogoWhite.svg";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import axiosInstance from "../../../../axios/axiosConfig";
import {
  setAlertMessage,
  setIsFailAlertOpen,
  setIsSuccessAlertOpen,
} from "../../../../features/alertMessageSlice";
import {
  changeImagePath,
  changeContactDetails,
  changeNationality,
  initalizeContactDetails,
} from "../../../../features/profileinfoSlice";
import { FieldComponent } from "./FieldComponent";
import { saveFirstName, saveLastName } from "./saveActions";
import { PositionAtPhilicoSelect } from "./PositionAtPhilicoSelect";
import {
  imageSuccessAlert,
  imageFailAlert,
} from "../../../../components/AlertMessages";

function DynamicCvHeader2nd() {
  const profileInfoState = useSelector((state) => state.profileinfo);
  const internalInfoState = useSelector((state) => state.internalinfo);
  const dispatch = useDispatch();

  const [isImageUploading, setIsImageUploading] = useState(false);
  const [fieldWidths, setFieldWidths] = useState({
    firstName: 0,
    lastName: 0,
    position: 0,
  });

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const positionRef = useRef(null);

  const timeoutWidthComputation = 100;

  const handleImageChange = (e) => {
    setIsImageUploading(true);

    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "cv-app-profile-images");

    axios
      .post("https://api.cloudinary.com/v1_1/dnuaxknhc/image/upload", formData)
      .then((responseCloudinary) => {
        axiosInstance
          .put("/cvapp/employee-profile/", {
            imageURL: responseCloudinary.data.url,
          })
          .then((responseDjango) => {
            dispatch(setIsSuccessAlertOpen(true));
            dispatch(setAlertMessage(imageSuccessAlert));
            dispatch(changeImagePath(responseDjango.data.imageURL));
          })
          .catch((error) => {
            console.error("Error saving image URL in DB:", error);
            dispatch(setIsFailAlertOpen(true));
            dispatch(
              setAlertMessage(
                `${imageFailAlert} Error: ${error.response?.status} ${error.response?.statusText}`
              )
            );
          })
          .finally(() => setIsImageUploading(false));
      })
      .catch((error) => {
        console.error("Error uploading image to Cloudinary:", error);
        setIsImageUploading(false);
      });
  };

  const updateFieldWidth = (ref, field) => {
    if (ref.current) {
      setFieldWidths((prev) => ({
        ...prev,
        [field]: ref.current.offsetWidth + 5, // Includes padding adjustment
      }));
    }
  };

  useLayoutEffect(() => {
    const timer = setTimeout(
      () => updateFieldWidth(firstNameRef, "firstName"),
      timeoutWidthComputation
    );
    return () => clearTimeout(timer);
  }, [profileInfoState.contactDetails.firstName]);

  useLayoutEffect(() => {
    const timer = setTimeout(
      () => updateFieldWidth(lastNameRef, "lastName"),
      timeoutWidthComputation
    );
    return () => clearTimeout(timer);
  }, [profileInfoState.contactDetails.lastName]);

  useLayoutEffect(() => {
    const timer = setTimeout(
      () => updateFieldWidth(positionRef, "position"),
      timeoutWidthComputation
    );
    return () => clearTimeout(timer);
  }, [internalInfoState.positionWithinPhilico]);

  useLayoutEffect(() => {
    axiosInstance
      .get("/cvapp/employee-profile/")
      .then(({ data }) => {
        const {
          firstName,
          lastName,
          email,
          phone,
          countryCode,
          birthDate,
          nationality,
        } = data;
        dispatch(
          initalizeContactDetails({
            firstName,
            lastName,
            email,
            phone,
            countryCode,
            birthDate,
          })
        );
        dispatch(changeNationality(nationality));
      })
      .catch((error) =>
        console.error("Error fetching contact details:", error)
      );
  }, []);

  return (
    <div className="cv-header-flexbox-outer-wrapper">
      <label htmlFor="contained-button-file">
        <input
          accept="image/png, image/jpeg"
          id="contained-button-file"
          type="file"
          onChange={handleImageChange}
          style={{ display: "none" }}
        />
        {isImageUploading ? (
          <div className="cv-image-upload-container">
            <CircularProgress style={{ width: "70px", height: "70px" }} />
          </div>
        ) : (
          <img
            className="cv-pic-2nd"
            src={profileInfoState.image || AvatarCvPic}
            alt="cv pic"
            onError={(e) => (e.target.src = AvatarCvPic)}
          />
        )}
      </label>
      <div className="cv-header-flexbox-blue-banner">
        <div className="cv-header-flexbox-name-and-role">
          <div
            className="cv-name-inputs"
            style={{ display: "flex", flexDirection: "row", color: "green" }}
          >
            <FieldComponent
              placeHolder="First name"
              changeOnlyFontColor={true}
              className="outlinedinput-dynamic-cv header-first-name-2nd"
              value={profileInfoState.contactDetails.firstName?.toUpperCase()}
              onChange={(e) =>
                dispatch(
                  changeContactDetails({
                    property: "firstName",
                    value: e.target.value,
                  })
                )
              }
              onBlur={(e, setError, setRetry, setIsDataSaved) => {
                saveFirstName({
                  dispatch,
                  firstName: e.target.value,
                  setError,
                  setRetry,
                  setIsDataSaved,
                });
              }}
              width={fieldWidths.firstName}
            />
            <FieldComponent
              placeHolder="Last name"
              changeOnlyFontColor={true}
              className="outlinedinput-dynamic-cv header-first-name-2nd"
              value={profileInfoState.contactDetails.lastName?.toUpperCase()}
              onChange={(e) =>
                dispatch(
                  changeContactDetails({
                    property: "lastName",
                    value: e.target.value,
                  })
                )
              }
              onBlur={(e, setError, setRetry, setIsDataSaved) => {
                saveLastName({
                  dispatch,
                  lastName: e.target.value,
                  setError,
                  setRetry,
                  setIsDataSaved,
                });
              }}
              width={fieldWidths.lastName}
            />
          </div>
          <PositionAtPhilicoSelect
            changeOnlyFontColor={true}
            positionWidth={fieldWidths.position}
            positionWithinPhilico={internalInfoState.positionWithinPhilico}
          />
        </div>
        <img
          src={philicoLogoWhite}
          alt="Philico logo"
          className="Philico-logo-cv-header-2nd"
        />
      </div>
      <div ref={firstNameRef} id="first-name-measure">
        {profileInfoState.contactDetails.firstName?.toUpperCase() ||
          "First Name"}
      </div>
      <div ref={lastNameRef} id="last-name-measure">
        {profileInfoState.contactDetails.lastName?.toUpperCase() || "Last Name"}
      </div>
      <div ref={positionRef} id="position-measure">
        {internalInfoState.positionWithinPhilico || "Position within Philico"}
      </div>
    </div>
  );
}

export default DynamicCvHeader2nd;
