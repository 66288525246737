export const LANGUAGES = [
  "English",
  "Chinese",
  "Hindi",
  "Spanish",
  "French",
  "Arabic",
  "Bengali",
  "Portuguese",
  "Russian",
  "Urdu",
  "Indonesian",
  "Swahili",
  "Turkish",
  "Korean",
  "Vietnamese",
  "Tamil",
  "Persian (Farsi)",
  "Hausa",
  "Punjabi",
  "Romanian",
  "Ukrainian",
  "Tagalog (Filipino)",
  "Greek",
  "Polish",
  "Hebrew",
  "Malay",
  "Thai",
  "Hungarian",
  "Czech",
  "Swedish",
  "Finnish",
  "Danish",
  "Slovak",
  "Bulgarian",
  "Norwegian",
  "Serbian",
  "Croatian",
  "Lithuanian",
  "Slovenian",
  "Latvian",
  "Estonian",
  "Italian",
  "German",
  "Dutch",
  "Romansh"
].sort((a, b) => a.localeCompare(b));
