import axiosInstance from "../../../../axios/axiosConfig";
import {
  addCertificate,
  changeCertificateObject,
} from "../../../../features/cvCertificates";
import { temporarilyMarkAsSaved } from "../../utils";

export const saveDate = ({
  id,
  date,
  savedCertificates,
  setSavedCertificates,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/certificates/", { id, endYear: date })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedCertificates,
        setSavedCertificates
      )(null);
      dispatch(
        changeCertificateObject({
          id: response.data.id,
          property: "endYear",
          value: response.data.endYear,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveDate({
            id,
            date,
            savedCertificates,
            setSavedCertificates,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error updating endYear:", error);
    });

export const saveCertificateName = ({
  id,
  value,
  savedCertificates,
  setSavedCertificates,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/certificates/", { id, certificateName: value })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedCertificates,
        setSavedCertificates
      )(null);
      dispatch(
        changeCertificateObject({
          id: response.data.id,
          property: "certificateName",
          value: response.data.certificateName,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveCertificateName({
            id,
            value,
            savedCertificates,
            setSavedCertificates,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error updating certificateName:", error);
    });

export const saveIssuingOrganization = ({
  id,
  value,
  savedCertificates,
  setSavedCertificates,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/certificates/", { id, issuingOrganization: value })
    .then((response) => {
      setError(false);
      dispatch(
        changeCertificateObject({
          id: response.data.id,
          property: "issuingOrganization",
          value: response.data.issuingOrganization,
        })
      );
      temporarilyMarkAsSaved(
        response.data.id,
        savedCertificates,
        setSavedCertificates
      )(null);
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveIssuingOrganization({
            id,
            value,
            savedCertificates,
            setSavedCertificates,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error updating issuingOrganization:", error);
    });

export const saveLocation = ({
  id,
  value,
  savedCertificates,
  setSavedCertificates,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/certificates/", { id, location: value })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedCertificates,
        setSavedCertificates
      )(null);
      dispatch(
        changeCertificateObject({
          id: response.data.id,
          property: "location",
          value: response.data.location,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveLocation({
            id,
            value,
            savedCertificates,
            setSavedCertificates,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error updating location:", error);
    });

export const saveIsIncludedInCv = ({
  id,
  value,
  savedCertificates,
  setSavedCertificates,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/certificates/", { id, isIncludedInCv: value })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedCertificates,
        setSavedCertificates
      )(null);
      dispatch(
        changeCertificateObject({
          id: response.data.id,
          property: "isIncludedInCv",
          value: response.data.isIncludedInCv,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveIsIncludedInCv({
            id,
            value,
            savedCertificates,
            setSavedCertificates,
            dispatch,
            setError,
            setRetry,
          })
      );
      console.error("Error updating isIncludedInCv:", error);
    });

export const saveAddCertification = ({
  setSavedCertificates,
  savedCertificates,
  dispatch,
  setError=() => {},
  setRetry=() => {},
}) =>
  axiosInstance
    .post("/cvapp/certificates/", {
      certificateName: "",
      issuingOrganization: "",
      location: "",
    })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedCertificates,
        setSavedCertificates
      )(null);
      dispatch(addCertificate(response.data));
    })
    .catch((error) => {
      console.log("error adding degree");
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(
        () => () =>
          saveAddCertification({
            setSavedCertificates,
            savedCertificates,
            dispatch,
            setError,
            setRetry,
          })
      );
    });
