import { createSlice } from "@reduxjs/toolkit";
import { resetReduxState } from "./resetState";

const initialState = {
  cvAppUserRole: "Employee",

  // loggedInUser is the primary key of table base_user of the user that is currently logged in
  loggedInUser: JSON.parse(localStorage.getItem("loggedInUser")) || null,

  // activeUser is the primary key of table base_user of the user that is currently active/selected via manager functionality
  activeUser: JSON.parse(localStorage.getItem("activeUser")) || null,

  //  array of users object that has the name property and the id (primary key of the base_user table) of the user; example: usersComplete = [{"id":3, "name": "Patrick Amstad"}]
  usersComplete: [],

  // array of users name
  usersName: [],

  // array of users id
  usersId: [],

  // array of user name + their user roles
  userRoles: [],

  // activeUsers array that contains all users that are set to active
  activeUsers: [],

  // inactiveUsers array contains all users that are set to inactive
  inactiveUsers: [],

  // this array includes all users with Manager user role (used for Coaches drop down list)
  managerUsers: [],

  // checked users is need for the transfer list of activating and deactivating users
  timeMateRestirctedUsers: [],

  // active user select is for the select field
  activeUserSelected: 0,

  pageLocationString: "",
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  // extraReducers: (builder) =>
  //   builder.addCase(resetReduxState, () => initialState),
  reducers: {
    initalizeAdmin: (state, action) => {
      state.cvAppUserRole = action.payload.cvAppUserRole;
      state.loggedInUser = action.payload.id;
      state.activeUser = action.payload.id;
      state.timeMateRestirctedUsers = [10, 11, 12, 25, 32];
      const currentLoggedInUser = localStorage.getItem("loggedInUser");
      const currentActiveUser = localStorage.getItem("activeUser");
      !currentLoggedInUser &&
        localStorage.setItem("activeUser", JSON.stringify(action.payload.id));
      !currentActiveUser &&
        localStorage.setItem("loggedInUser", JSON.stringify(action.payload.id));
      // localStorage.setItem("cvAppUserRole", action.payload.cvAppUserRole);
      // localStorage.setItem("loggedInUser", action.payload.id);
      // localStorage.setItem("activeUser", action.payload.id);
    },
    changeUserRole: (state, action) => {
      state.cvAppUserRole = action.payload;
    },
    initalizeManagerUsers: (state, action) => {
      state.managerUsers = action.payload;
    },
    setPageLocationString: (state, action) => {
      state.pageLocationString = action.payload;
    },
    initalizeUsersComplete: (state, action) => {
      state.usersComplete = action.payload;
    },
    initalizeUsersName: (state, action) => {
      state.usersName = action.payload;
    },
    initalizeActiveUsers: (state, action) => {
      state.activeUsers = action.payload;
    },
    initalizeInactiveUsers: (state, action) => {
      state.inactiveUsers = action.payload;
    },
    initalizeUsersId: (state, action) => {
      state.usersId = action.payload;
    },

    changeActiveUser: (state, action) => {
      state.activeUser = action.payload;
      localStorage.setItem("activeUser", JSON.stringify(action.payload));
    },
    initalizeUserRoles: (state, action) => {
      state.userRoles = action.payload;
    },
    handleUserRoleChange: (state, action) => {
      // check if checkbox was checked (true value), or unchecked (false value)

      let index = action.payload.userRoles.findIndex((user) => {
        return user.id === action.payload.newObj.id;
      });

      state.userRoles[index] = action.payload.newObj;
    },
    setActiveUserSelected: (state, action) => {
      state.activeUserSelected = action.payload;
    },
  },
});

export const {
  initalizeAdmin,
  initalizeUsersComplete,
  initalizeUsersName,
  initalizeUsersId,
  setActiveUserSelected,
  changeActiveUser,
  initalizeUserRoles,
  initalizeManagerUsers,
  handleUserRoleChange,
  changeUserRole,
  initalizeActiveUsers,
  initalizeInactiveUsers,
  setPageLocationString,
} = adminSlice.actions;

export default adminSlice.reducer;
