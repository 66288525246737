import { MuiTelInput } from "mui-tel-input";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeContactDetails } from "../../../../features/profileinfoSlice";
import { savePhoneNumber } from "./saveActions";
import { SavedInfoIndicator } from "../SavedItemIndicator";

export const MobilePhoneInput = ({ phone, countryCode, phoneWidth }) => {
  const [error, setError] = useState(false);
  const [retry, setRetry] = useState(() => {});
  const [isDataSaved, setIsDataSaved] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setIsDataSaved(false);
    }, 2000);
  }, [isDataSaved]);

  return (
    <div className="component-and-saveing-indicator-wrapper" >
      <div>
        <MuiTelInput
          className="textfield-dynamic-cv mobile-phone-input"
          flagSize="small"
          sx={{ width: `${phoneWidth}px` }}
          placeholder="79 793 07 63" //careful with changing this placeholder; css style depends on it input[placeholder="79 793 07 63"]
          forceCallingCode
          preferredCountries={["CH", "DE", "FR"]}
          value={phone}
          onChange={(newValue, info) => {
            dispatch(
              changeContactDetails({
                property: "phone",
                value: newValue,
              })
            );
            dispatch(
              changeContactDetails({
                property: "countryCode",
                value: info.countryCode,
              })
            );
          }}
          variant="outlined"
          defaultCountry="CH"
          onBlur={(e) => {
            savePhoneNumber({
              dispatch,
              phone: phone,
              countryCode: countryCode,
              setError,
              setRetry: (retryFunction) => {
                setRetry(() => retryFunction());
              },
              setIsDataSaved,
            });
          }}
        />
      </div>
      <div style={{ width: "6ch" }}>
        {isDataSaved && <SavedInfoIndicator error={error} retry={retry} />}
      </div>
    </div>
  );
};
