import axiosInstance from "../../../../axios/axiosConfig";
import { changeProjectObject } from "../../../../features/cvProjectExperience";
import { temporarilyMarkAsSaved } from "../../utils";

export const saveStartDate = ({
  id,
  date,
  savedProjectExperiencesIds,
  setSavedProjectExperiencesIds,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/experiences/", { id, startDate: date })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedProjectExperiencesIds,
        setSavedProjectExperiencesIds
      )(null);
      dispatch(
        changeProjectObject({
          id: response.data.id,
          property: "startDate",
          value: response.data.startDate,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(() => () => 
        saveStartDate({
          id,
          date,
          savedProjectExperiencesIds,
          setSavedProjectExperiencesIds,
          dispatch,
          setError,
          setRetry,
        })
      );
      console.error("Error updating startDate:", error);
    });

export const saveEndDate = ({
  id,
  date,
  savedProjectExperiencesIds,
  setSavedProjectExperiencesIds,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/experiences/", {
      id,
      endDate: date !== "Invalid date" ? date : null,
    })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedProjectExperiencesIds,
        setSavedProjectExperiencesIds
      )(null);
      dispatch(
        changeProjectObject({
          id: response.data.id,
          property: "endDate",
          value: response.data.endDate,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(() => () => 
        saveEndDate({
          id,
          date,
          savedProjectExperiencesIds,
          setSavedProjectExperiencesIds,
          dispatch,
          setError,
          setRetry,
        })
      );
      console.error("Error updating endDate:", error);
    });

export const saveProjectTitle = ({
  id,
  value,
  dispatch,
  savedProjectExperiencesIds,
  setSavedProjectExperiencesIds,
  setError,
  setRetry,
}) =>
{console.log(value)
  axiosInstance
  .put("/cvapp/experiences/", { id, title: value })
  .then((response) => {
    setError(false);
    temporarilyMarkAsSaved(
      response.data.id,
      savedProjectExperiencesIds,
      setSavedProjectExperiencesIds
    )(null);
    dispatch(
      changeProjectObject({
        id: response.data.id,
        property: "projectTitle",
        value: response.data.title,
      })
    );
  })
  .catch((error) => {
    setError(true);
    temporarilyMarkAsSaved()(error);
    setRetry(() => () => 
      saveProjectTitle({
        id,
        value,
        dispatch,
        savedProjectExperiencesIds,
        setSavedProjectExperiencesIds,
        setError,
        setRetry,
      })
    );
    console.error("Error updating projectTitle:", error);
  });}

export const saveProjectType = ({
  id,
  value,
  dispatch,
  savedProjectExperiencesIds,
  setSavedProjectExperiencesIds,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/experiences/", { id, projectType: value })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedProjectExperiencesIds,
        setSavedProjectExperiencesIds
      )(null);
      dispatch(
        changeProjectObject({
          id: response.data.id,
          property: "projectType",
          value: response.data.projectType,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(() => () => 
        saveProjectType({
          id,
          value,
          dispatch,
          savedProjectExperiencesIds,
          setSavedProjectExperiencesIds,
          setError,
          setRetry,
        })
      );
      console.error("Error updating projectType:", error);
    });

export const saveCompany = ({
  id,
  value,
  dispatch,
  savedProjectExperiencesIds,
  setSavedProjectExperiencesIds,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/experiences/", { id, company: value })
    .then((response) => {
      setError(false);
      dispatch(
        changeProjectObject({
          id: response.data.id,
          property: "company",
          value: response.data.company,
        })
      );
      temporarilyMarkAsSaved(
        response.data.id,
        savedProjectExperiencesIds,
        setSavedProjectExperiencesIds
      )(null);
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(() => () => 
        saveCompany({
          id,
          value,
          dispatch,
          savedProjectExperiencesIds,
          setSavedProjectExperiencesIds,
          setError,
          setRetry,
        })
      );
      console.error("Error updating company:", error);
    });

export const saveClientType = ({
  id,
  value,
  dispatch,
  savedProjectExperiencesIds,
  setSavedProjectExperiencesIds,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/experiences/", { id, clientType: value })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedProjectExperiencesIds,
        setSavedProjectExperiencesIds
      )(null);
      dispatch(
        changeProjectObject({
          id: response.data.id,
          property: "clientType",
          value: response.data.clientType,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(() => () => 
        saveClientType({
          id,
          value,
          dispatch,
          savedProjectExperiencesIds,
          setSavedProjectExperiencesIds,
          setError,
          setRetry,
        })
      );
      console.error("Error updating clientType:", error);
    });

export const saveCustomClientType = ({
  id,
  clientType,
  savedProjectExperiencesIds,
  setSavedProjectExperiencesIds,
  dispatch,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/experiences/", {
      id: id,
      clientType: clientType,
    })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedProjectExperiencesIds,
        setSavedProjectExperiencesIds
      )(null);
      dispatch(
        changeProjectObject({
          id: response.data.id,
          property: "clientType",
          value: response.data.clientType,
        })
      );
    })
    .catch((error) => {
      console.log("error updating company: ", error);
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(() => () => 
        saveCustomClientType({
          id,
          clientType,
          savedProjectExperiencesIds,
          setSavedProjectExperiencesIds,
          dispatch,
          setError,
          setRetry,
        })
      );
    });

export const saveAddExperience = ({
  addExperience,
  dispatch,
  savedProjectExperiencesIds,
  setSavedProjectExperiencesIds,
  setError=() => {},
  setRetry=() => {},
}) =>
  axiosInstance
    .post("/cvapp/experiences/", {
      position: "",
      title: "",
      company: "",
      location: "",
      description: "",
      projectType: "",
      // startDate: null,
      // endDate: null,
    })
    .then((response) => {
      setError(false);
      dispatch(addExperience(response.data));
      temporarilyMarkAsSaved(
        response.data.id,
        savedProjectExperiencesIds,
        setSavedProjectExperiencesIds
      )(null);
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(() => () => 
        saveAddExperience({
          addExperience,
          dispatch,
          savedProjectExperiencesIds,
          setSavedProjectExperiencesIds,
          setError,
          setRetry,
        })
      );
      console.log("error adding experience");
    });

export const saveLocation = ({
  id,
  value,
  dispatch,
  savedProjectExperiencesIds,
  setSavedProjectExperiencesIds,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/experiences/", { id, location: value })
    .then((response) => {
      setError(false);
      dispatch(
        changeProjectObject({
          id: response.data.id,
          property: "location",
          value: response.data.location,
        })
      );
      temporarilyMarkAsSaved(
        response.data.id,
        savedProjectExperiencesIds,
        setSavedProjectExperiencesIds
      )(null);
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(() => () => 
        saveLocation({
          id,
          value,
          dispatch,
          savedProjectExperiencesIds,
          setSavedProjectExperiencesIds,
          setError,
          setRetry,
        })
      );
      console.error("Error updating location:", error);
    });

export const saveIsItIncluded = ({
  id,
  checked,
  dispatch,
  savedProjectExperiencesIds,
  setSavedProjectExperiencesIds,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/experiences/", { id, isIncludedInCv: checked })
    .then((response) => {
      setError(false);
      temporarilyMarkAsSaved(
        response.data.id,
        savedProjectExperiencesIds,
        setSavedProjectExperiencesIds
      )(null);
      dispatch(
        changeProjectObject({
          id: response.data.id,
          property: "isIncludedInCv",
          value: response.data.isIncludedInCv,
        })
      );
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(() => () => 
        saveIsItIncluded({
          id,
          checked,
          dispatch,
          savedProjectExperiencesIds,
          setSavedProjectExperiencesIds,
          setError,
          setRetry,
        })
      );
      console.error("Error updating isIncludedInCv:", error);
    });

export const saveChangeProjectDescription = ({
  id,
  description,
  stateStringified,
  dispatch,
  savedProjectExperiencesIds,
  setSavedProjectExperiencesIds,
  setError,
  setRetry,
}) =>
  axiosInstance
    .put("/cvapp/experiences/", {
      id: id,
      description: description,
      descriptionStringified: stateStringified,
    })
    .then((response) => {
      setError(false);
      dispatch(
        changeProjectObject({
          id: response.data.id,
          property: "description",
          value: response.data.description,
        })
      );
      dispatch(
        changeProjectObject({
          id: response.data.id,
          property: "descriptionStringified",
          value: response.data.descriptionStringified,
        })
      );
      temporarilyMarkAsSaved(
        id,
        savedProjectExperiencesIds,
        setSavedProjectExperiencesIds
      )(null);
    })
    .catch((error) => {
      setRetry(() => () => 
        saveChangeProjectDescription({
          id,
          description,
          stateStringified,
          dispatch,
          savedProjectExperiencesIds,
          setSavedProjectExperiencesIds,
          setError,
          setRetry,
        })
      );
      setError(true);
      temporarilyMarkAsSaved()(error);
      console.log("error updating position: ", error);
    });

export const saveAddEmploymentHistory = ({
  dispatch,
  addEmploymentHistory,
  savedEmploymentHistory,
  setSavedEmploymentHistory,
  setError=() => {},
  setRetry=() => {},
}) =>
  axiosInstance
    .post("/cvapp/employment-history/", {
      jobTitle: "",
      employer: "",
      location: "",
    })
    .then((response) => {
      setError(false);
      dispatch(addEmploymentHistory(response.data));
      temporarilyMarkAsSaved(
        response.data.id,
        savedEmploymentHistory,
        setSavedEmploymentHistory
      )(null);
    })
    .catch((error) => {
      setError(true);
      temporarilyMarkAsSaved()(error);
      setRetry(() => () => 
        saveAddEmploymentHistory({
          dispatch,
          addEmploymentHistory,
          savedEmploymentHistory,
          setSavedEmploymentHistory,
          setError,
          setRetry,
        })
      );
      console.log("error adding employment history");
    });
