import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { savePositionWithinPhilico } from "./saveActions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SavedInfoIndicator } from "../SavedItemIndicator";

const MenuItemsValues = [
  { value: "Junior Consultant", name: "Junior Consultant" },
  { value: "Consultant", name: "Consultant" },
  { value: "Senior Consultant", name: "Senior Consultant" },
  { value: "Data & AI Consultant", name: "Data & AI Consultant" },
  { value: "Full Stack Developer", name: "Full Stack Developer" },
  { value: "Manager", name: "Manager" },
  { value: "Partner", name: "Partner" },
  { value: "Other", name: "Other" },
];

export const PositionAtPhilicoSelect = ({
  positionWidth,
  positionWithinPhilico,
  changeOnlyFontColor = false,
}) => {
  const [error, setError] = useState(false);
  const [retry, setRetry] = useState(() => {});
  const [isDataSaved, setIsDataSaved] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setIsDataSaved(false);
    }, 2000);
  }, [isDataSaved]);

  return (
    <div className="component-and-saveing-indicator-wrapper">
      <FormControl sx={{ width: `${positionWidth}px` }}>
        <Select
          className={`selectfield-dynamic-cv position-philico-2nd ${
            isDataSaved && changeOnlyFontColor ? "saved-text" : ""
          }`}
          value={positionWithinPhilico}
          label="Age"
          displayEmpty={true}
          renderValue={(value) =>
            value?.length ? (
              Array.isArray(value) ? (
                value.join(", ")
              ) : (
                value
              )
            ) : (
              <span style={{ color: "white" }}>Position within Philico</span>
            )
          }
          onChange={(e) => {
            savePositionWithinPhilico({
              dispatch,
              positionWithinPhilico: e.target.value,
              setError,
              setRetry: (retryFunction) => {
                setRetry(() => retryFunction());
              },
              setIsDataSaved,
            });
          }}
        >
          {MenuItemsValues.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isDataSaved && !changeOnlyFontColor && (
        <SavedInfoIndicator error={error} retry={retry} />
      )}
    </div>
  );
};
