export const deleteSelectedItem = (id, ids) => {
  return ids.filter((item) => item !== id);
};

export const temporarilyMarkAsSaved =
  (newItem = "", items = [], setSavedIds = () => {}) =>
  (error) => {
    setSavedIds((prev) => [...prev, newItem]);
    if (error) {
      return items;
    } else {
      setTimeout(() => {
        setSavedIds((prev) => prev.filter((savedId) => savedId !== newItem));
      }, 2000);
    }
    return items;
  };
