import { useMaterialReactTable } from "material-react-table";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ContentCopy as ContentCopyIcon,
} from "@mui/icons-material";
import {
  setDurationEditValidationError,
  setDensityState,
} from "../../../../features/timesheetSlice";
import {
  handleCopy,
  handleDeleteCallback,
  handleIncludeCheckboxChange,
  handleSaveUser,
} from "./handlers";
import { TimesheetApprovalCheckBox } from "./TimesheetApprovalCheckBox";

export const useTable = ({
  columns,
  data,
  updateTimeSheetPage,
  dispatch,
  groupingState,
  timesheetState,
  enableRowActions = true,
  showIncludeCheckbox = true,
  enableEditEntry = true,
  enableCopyEntry = true,
  enableDeleteEntry = true,
  enableDensityToggle = true,
  enableGrouping = true,
  enableColumnDragging = false,
  enableGlobalFilter = false,
  enableColumnFilters = false,
  enablePagination = false,
}) => {
  const deleteCallback = handleDeleteCallback(updateTimeSheetPage);
  const includeCheckboxChange = handleIncludeCheckboxChange(dispatch);
  const saveUser = handleSaveUser({ dispatch, updateTimeSheetPage });
  const copy = handleCopy(dispatch);
  const isEditingDisabled = (row) =>
    !(
      row.original.status === "Rejected" ||
      row.original.status === "Pending" ||
      row.original.status === "Edited" 
    );

  const rowActions = ({ table, row }) => {
    return (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        {showIncludeCheckbox && (
          <TimesheetApprovalCheckBox
            includeCheckboxChange={includeCheckboxChange}
            row={row}
          />
        )}
        {enableEditEntry && (
          <Tooltip title="Edit">
            <IconButton
              style={{
                color: isEditingDisabled(row) ? "grey" : "var(--philico-blue)",
              }}
              onClick={() => table.setEditingRow(row)}
              disabled={isEditingDisabled(row)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        {enableCopyEntry && (
          <Tooltip title="Copy Entry">
            <IconButton
              style={{
                color: isEditingDisabled(row) ? "grey" : "var(--philico-blue)",
              }}
              onClick={async () => await copy(row)}
              disabled={isEditingDisabled(row)}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        )}
        {enableDeleteEntry && (
          <Tooltip title="Delete">
            <IconButton
              style={{
                color: isEditingDisabled(row) ? "grey" : "var(--philico-blue)",
              }}
              onClick={() => {
                deleteCallback(row);
              }}
              disabled={isEditingDisabled(row)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  };

  const table = useMaterialReactTable({
    columns,
    data,
    editDisplayMode: "modal",
    enableDensityToggle: enableDensityToggle,
    enableGrouping: enableGrouping,
    enableColumnDragging: enableColumnDragging,
    enableGlobalFilter: enableGlobalFilter,
    enableColumnFilters: enableColumnFilters,
    enablePagination: enablePagination,
    enableRowActions: enableRowActions,
    onDensityChange: (density) => dispatch(setDensityState(density)),
    state: { density: timesheetState.densityState },
    initialState: {
      sorting: [
        { id: "date", desc: false },
        { id: "duration", desc: true },
      ],
      density: timesheetState.densityState,
      grouping: groupingState,
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor:
          row.original?.activity_name?.toLowerCase() === "public holiday"
            ? "rgba(0,65,112, 0.05)"
            : "white",
      },
    }),
    onEditingRowSave: (row) => {
      saveUser(row);
    },
    onEditingRowCancel: () => dispatch(setDurationEditValidationError(false)),
    renderRowActions: ({ table, row }) => rowActions({ table, row }),
    muiTableContainerProps: { sx: { margin: "0px", flexGrow: 1 } },
  });

  return table;
};
