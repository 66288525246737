import { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import PhilicoLogo from "../../../../assets/philico_logo_initials.png";
import ApprovalTimeSheetView from "../TimesheetDataView/ApprovalTimeSheetView";
import RejectDialogWindow from "./RejectDialogWindow";
import TimesheetOverviewCard from "./TimesheetOverviewCard";
import FilterCalendar from "./FilterCalendar";
import "./TimesheetOverviewCard.css";
import { updateTimeSheetEntriesStatus } from "../../helpers";
import {
  initalizePendingCheckedTimeSheetReq,
  initalizeApprovedCheckedTimeSheetReq,
  initalizeRejectedCheckedTimeSheetReq,
} from "../../../../features/timesheetSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ApprovalDialogWindow = ({
  employeeFullName,
  totalHours,
  openDialog,
  closeDialogCallback,
  timesheetData,
  setTimesheetData,
  employeeId,
  monthYear,
}) => {
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const dispatch = useDispatch();

  const timesheetState = useSelector((state) => state.timesheet);

  const selectedPendingEntries = useSelector(
    (state) => state.timesheet.pendingCheckedTimeSheetReq
  );
  const selectedApprovedEntries = useSelector(
    (state) => state.timesheet.approvedCheckedTimeSheetReq
  );
  const selectedRejectedEntries = useSelector(
    (state) => state.timesheet.rejectedCheckedTimeSheetReq
  );

  const resetAllCheckedEntries = () => {
    dispatch(initalizePendingCheckedTimeSheetReq([]));
    dispatch(initalizeApprovedCheckedTimeSheetReq([]));
    dispatch(initalizeRejectedCheckedTimeSheetReq([]));
  };

  useEffect(() => {
    resetAllCheckedEntries();
  }, [openDialog]);

  const rejectCallback = async (rejectionComment) => {
    try {
      await updateTimeSheetEntriesStatus({
        timesheetStatusIds: selectedPendingEntries,
        newStatus: "Rejected",
        employeeId: employeeId,
        comment: rejectionComment,
      });
      setOpenRejectDialog(false);
      closeDialogCallback();
    } catch (error) {
      console.error("Error rejecting timesheet:", error);
    }
  };

  const approveCallback = async () => {
    try {
      await updateTimeSheetEntriesStatus({
        timesheetStatusIds: selectedPendingEntries,
        newStatus: "Approved",
        employeeId: employeeId,
      });
      setOpenRejectDialog(false);
      closeDialogCallback();
    } catch (error) {
      console.error("Error approving timesheet:", error);
    }
  };

  const reopenCallback = async (requestState) => {
    const selectedEntries =
      requestState === "Approved"
        ? selectedApprovedEntries
        : selectedRejectedEntries;
    await updateTimeSheetEntriesStatus({
      timesheetStatusIds: selectedEntries,
      newStatus: "Submitted",
      employeeId: employeeId,
      reopen: true,
    });
    setOpenRejectDialog(false);
    closeDialogCallback();
  };

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={closeDialogCallback}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "sticky", backgroundColor: "white" }}>
        <Toolbar sx={{ height: "100px" }}>
          <img
            src={PhilicoLogo}
            alt="Philico Logo"
            style={{ marginLeft: "25px", width: "64px", height: "64px" }}
          />
          <Typography
            sx={{ ml: 2, flex: 1, color: "#004170", fontWeight: "bold" }}
            variant="h5"
          >
            {`Approval - ${employeeFullName}`}
          </Typography>
          <Typography
            sx={{ ml: 2, color: "#004170", fontWeight: "bold" }}
            variant="h5"
          >
            {`Total Hours: ${totalHours}`}
          </Typography>
          <IconButton onClick={closeDialogCallback} sx={{ mr: 0 }}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div className="flexbox-wrapper-timesheet-overview">
        <FilterCalendar
          monthYear={monthYear}
          setTimesheetData={setTimesheetData}
          employeeId={employeeId}
        />
        <TimesheetOverviewCard employeeId={employeeId} monthYear={monthYear} />
      </div>

      <ApprovalTimeSheetView
        timesheetData={timesheetData}
        approvedData={timesheetState.approvedTimeSheetData}
        rejectedData={timesheetState.rejectedTimeSheetData}
        submittedData={timesheetState.submittedTimeSheetData}
        employeeId={employeeId}
        showIncludeCheckbox={true}
        enableRowActions={true}
        approveCallback={approveCallback}
        rejectCallback={() => setOpenRejectDialog(true)}
        reopenCallback={reopenCallback}
        updateTimeSheetPage={() => {}}
      />

      <RejectDialogWindow
        open={openRejectDialog}
        setOpen={setOpenRejectDialog}
        rejectCallback={rejectCallback}
        rejectedEntries={selectedPendingEntries}
      />
    </Dialog>
  );
};

export default ApprovalDialogWindow;
