// Helper function to sum durations
const sumDurations = (durations) => {
  // Assuming durations are in the format "hh:mm:ss"
  const totalSeconds = durations.reduce((total, duration) => {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return total + hours * 3600 + minutes * 60 + seconds;
  }, 0);

  // Convert total seconds back to "hh:mm:ss" format
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export const groupAndAggregateByDate = (data) => {
  const groupedData = data.reduce((result, entry) => {
    const date = entry.date;

    if (!result[date]) {
      result[date] = {
        date: date,
        activity: "", //new Set(),
        activity_type: "", //new Set(),
        billable: "", //new Set(),
        comment: "", //ew Set(),
        duration: [],
        subRows: [],
      };
    }
    result[date].duration.push(entry.duration);
    const entryWithoutDate = Object.entries(entry)
      .filter(([key]) => key !== "date")
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    result[date].subRows.push(entryWithoutDate);
    return result;
  }, {});

  // Convert the grouped data back to an array
  const resultArray = Object.values(groupedData);

  // Calculate sum of durations and convert sets to comma-separated strings
  resultArray.forEach((entry) => {
    entry.duration = sumDurations(entry.duration);
    // entry.activity = Array.from(entry.activity).join(', ');
    // entry.activity_type = Array.from(entry.activity_type).join(', ');
    entry.billable = ""; //Array.from(entry.billable).every(Boolean);
    // entry.comment = Array.from(entry.comment).join(', ');
    // entry.status = Array.from(entry.status).join(', ');
  });

  return resultArray;
};

export const selectClassName = (status) => {
  const statusLower = status?.toLowerCase();
  if (statusLower === "approved") {
    return "approved-status";
  } else if (statusLower === "rejected") {
    return "rejected-status";
  } else if (statusLower === "edited") {
    return "edited";
  } else if (statusLower === "submitted") {
    return "submitted-status";
  } else if (statusLower === "submitted") {
    return ["submitted-status"];
  } else if (statusLower === "submitted") {
    return "submitted-status";
  } else if (statusLower === "pending") {
    return "pending-status";
  }
  return null;
};
