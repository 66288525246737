export function getCurrentAndPastThreeMonths() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const months = [];

  // Add current month and year
  months.push({ month: currentMonth + 1, year: currentYear }); // Adding 1 to month to make it 1-indexed

  // Add past three months and years
  for (let i = 1; i <= 3; i++) {
    const pastDate = new Date(currentYear, currentMonth - i);
    months.push({
      month: pastDate.getMonth() + 1,
      year: pastDate.getFullYear(),
    });
  }

  return months;
}

export function getMonthName(monthNumber) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return months[monthNumber - 1];
}

function getMonthNumber(monthName) {
  // Define a mapping of month names to their respective numbers
  const monthMap = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  // Return the month number corresponding to the month name
  return monthMap[monthName];
}

export default function parseDate(dateString) {
  const [month, year] = dateString.split(" ");
  return { month: getMonthNumber(month), year: parseInt(year) };
}

export function getMonthsDifference(date1, date2) {
  const parsedDate1 = parseDate(date1);
  const monthDifference = date2.month - parsedDate1.month;
  const yearDifference = date2.year - parsedDate1.year;

  const totalMonthsDifference = yearDifference * 12 + monthDifference;

  return totalMonthsDifference;
}

export function navigateCalendar(
  monthDifference,
  positiveFunction,
  negativeFunction
) {
  if (monthDifference > 0) {
    for (let i = 0; i < monthDifference; i++) {
      positiveFunction();
    }
  } else if (monthDifference < 0) {
    for (let i = 0; i < -monthDifference; i++) {
      negativeFunction();
    }
  }
}

export const getClass = (classes) => (yearMonthStatus) => {
  switch (yearMonthStatus) {
    case "Pending":
      return classes.pendingButton;
    case "Rejected":
      return classes.rejectedButton;
    case "Approved":
      return classes.approvedButton;
    case "Submitted":
      return classes.submittedButton;
    case "Edited":
      return classes.editedButton;
  }
};
