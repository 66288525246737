import React, { useRef, useState, useLayoutEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import binIcon from "../../../../assets/bin_icon_large_24_24.svg";
import { useSelector, useDispatch } from "react-redux";
import { changeEmploymentHistoryObject } from "../../../../features/employmentHistory";
import { parse } from "date-fns";
import moment from "moment";
import { SavedInfoIndicator } from "../SavedItemIndicator";
import {
  saveStartDate,
  saveEndDate,
  saveJobTitle,
  saveEmployer,
  saveLocation,
  saveIsIncludedInCv,
} from "./saveActions";

function Employer(props) {
  const employmentHistoryState = useSelector(
    (state) => state.employmenthistory
  );

  const employerRef = useRef(null);
  const locationRef = useRef(null);
  const startYearRef = useRef(null);
  const endYearRef = useRef(null);

  const [employerWidth, setEmployerWidth] = useState(0);
  const [locationWidth, setLocationWidth] = useState(0);
  const [startYearWidth, setStartYearWidth] = useState(0);
  const [endYearWidth, setEndYearWidth] = useState(0);
  const [error, setError] = useState(false);
  const [retry, setRetry] = useState(() => () => {});

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setEndYearWidth(endYearRef.current.offsetWidth + 1);
      setStartYearWidth(startYearRef.current.offsetWidth + 1);
      setLocationWidth(locationRef.current.offsetWidth + 4 + 1);
      setEmployerWidth(employerRef.current.offsetWidth + 4 + 1);
    }, 150);

    setEndYearWidth(endYearRef.current.offsetWidth + 1);
    setStartYearWidth(startYearRef.current.offsetWidth + 1);
    setLocationWidth(locationRef.current.offsetWidth + 4 + 1);
    setEmployerWidth(employerRef.current.offsetWidth + 4 + 1); //4 is the padding-left + padding-right
    return () => clearTimeout(timer);
  }, [employmentHistoryState.employmentHistory]);

  return (
    <>
      <div className="flexbox-wrapper-project-experience">
        <div className="grid-wrapper-project-experience">
          <div className="grid-item-date-col">
            <div className="flexbox-date-col-wrapper">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  className="outlinedinput-dynamic-cv"
                  // inputFormat="dd.MM.yyyy"
                  // mask="__.__.____"
                  inputFormat="MM.yyyy"
                  mask="__.____"
                  disableMaskedInput={false}
                  value={parse(
                    props.historyObject.startDate,
                    "MM.yyyy",
                    new Date()
                  )}
                  onChange={(newValue) => {
                    let transformedDate = moment(newValue).format("MM.YYYY");
                    if (transformedDate !== "Invalid date") {
                      saveStartDate({
                        id: props.historyObject.id,
                        date: transformedDate,
                        savedEmploymentHistory: props.savedEmploymentHistory,
                        setSavedEmploymentHistory:
                          props.setSavedEmploymentHistory,
                        dispatch: dispatch,
                        setError: setError,
                        setRetry: (retryFunction) => {
                          setRetry(() => retryFunction());
                        },
                      });
                    }
                  }}
                  disableOpenPicker
                  renderInput={(params) => {
                    //add style prop to increase font-size
                    return (
                      <OutlinedInput
                        className="outlinedinput-dynamic-cv"
                        InputProps={params.InputProps}
                        {...params}
                        // label="birthdate"
                        inputRef={params.inputRef}
                        //   helperText="dd.mm.yyyy"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "mm.yyyy",
                        }}
                        onChange={(e) => {
                          console.log("e.target.value ", e.target.value);
                          console.log("e", e);
                        }}
                        sx={{ width: `${startYearWidth}px` }}
                      />
                    );
                  }}
                />
              </LocalizationProvider>
              <span
                style={{
                  paddingLeft: "5px",
                  paddingRight: "7px",
                  //   marginBottom: "-10px",
                  alignSelf: "center",
                  //   fontSize: "2.2rem",
                  fontWeight: "700",
                  marginBottom: "3px",
                }}
              >
                -
              </span>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  className="outlinedinput-dynamic-cv"
                  // inputFormat="dd.MM.yyyy"
                  // mask="__.__.____"
                  inputFormat="MM.yyyy"
                  mask="__.____"
                  disableMaskedInput={false}
                  value={parse(
                    props.historyObject.endDate,
                    "MM.yyyy",
                    new Date()
                  )}
                  onChange={(newValue) => {
                    let transformedDate = moment(newValue).format("MM.YYYY");
                    if (
                      transformedDate !== "Invalid date" ||
                      newValue === null
                    ) {
                      if (newValue === null) {
                        transformedDate = newValue;
                      }
                      saveEndDate({
                        id: props.historyObject.id,
                        date: transformedDate,
                        savedEmploymentHistory: props.savedEmploymentHistory,
                        setSavedEmploymentHistory:
                          props.setSavedEmploymentHistory,
                        dispatch: dispatch,
                        setError: setError,
                        setRetry: (retryFunction) => {
                          setRetry(() => retryFunction());
                        },
                      });
                    }
                  }}
                  disableOpenPicker
                  // helperText="dd.mm.yyyy"
                  // placeholder="dd.mm.yyyy"
                  renderInput={(params) => {
                    //add style prop to increase font-size
                    return (
                      <Tooltip title="empty value results in 'today'" arrow>
                        <OutlinedInput
                          className="outlinedinput-dynamic-cv"
                          InputProps={params.InputProps}
                          {...params}
                          // label="birthdate"
                          inputRef={params.inputRef}
                          //   helperText="dd.mm.yyyy"
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "mm.yyyy",
                          }}
                          onChange={(e) => {
                            console.log("e.target.value ", e.target.value);
                            console.log("e", e);
                          }}
                          sx={{ width: `${endYearWidth}px` }}
                        />
                      </Tooltip>
                    );
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="experience-title">
            <OutlinedInput
              placeholder="Job role"
              className="outlinedinput-dynamic-cv"
              sx={{ width: "100%" }}
              value={props.historyObject.jobTitle}
              onChange={(e) => {
                dispatch(
                  changeEmploymentHistoryObject({
                    id: props.historyObject.id,
                    property: "jobTitle",
                    value: e.target.value,
                  })
                );
              }}
              onBlur={(e) => {
                saveJobTitle({
                  id: props.historyObject.id,
                  value: e.target.value,
                  savedEmploymentHistory: props.savedEmploymentHistory,
                  setSavedEmploymentHistory: props.setSavedEmploymentHistory,
                  dispatch: dispatch,
                  setError: setError,
                  setRetry: (retryFunction) => {
                    setRetry(() => retryFunction());
                  },
                });
              }}
              multiline
            />
          </div>
          <div className="experience-subtitle">
            <OutlinedInput
              placeholder="Employer"
              className="outlinedinput-dynamic-cv"
              multiline
              value={props.historyObject.employer}
              sx={{ width: `${employerWidth}px`, maxWidth: "100%" }}
              onChange={(e) => {
                dispatch(
                  changeEmploymentHistoryObject({
                    id: props.historyObject.id,
                    property: "employer",
                    value: e.target.value,
                  })
                );
              }}
              onBlur={(e) => {
                saveEmployer({
                  id: props.historyObject.id,
                  value: e.target.value,
                  savedEmploymentHistory: props.savedEmploymentHistory,
                  setSavedEmploymentHistory: props.setSavedEmploymentHistory,
                  dispatch: dispatch,
                  setError: setError,
                  setRetry: (retryFunction) => {
                    setRetry(() => retryFunction());
                  },
                });
              }}
            />
            <span>, </span>
            <OutlinedInput
              placeholder="Location"
              className="outlinedinput-dynamic-cv"
              multiline
              value={props.historyObject.location}
              sx={{ width: `${locationWidth}px`, maxWidth: "100%" }}
              onChange={(e) => {
                dispatch(
                  changeEmploymentHistoryObject({
                    id: props.historyObject.id,
                    property: "location",
                    value: e.target.value,
                  })
                );
              }}
              onBlur={(e) => {
                saveLocation({
                  id: props.historyObject.id,
                  value: e.target.value,
                  savedEmploymentHistory: props.savedEmploymentHistory,
                  setSavedEmploymentHistory: props.setSavedEmploymentHistory,
                  dispatch: dispatch,
                  setError: setError,
                  setRetry: (retryFunction) => {
                    setRetry(() => retryFunction());
                  },
                });
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div>
            {props.isSaved && (
              <SavedInfoIndicator error={error} retry={retry} />
            )}
          </div>
          <div>
            <Tooltip title="Include in CV" arrow>
              <Checkbox
                checked={props.historyObject.isIncludedInCv}
                onChange={(e) => {
                  saveIsIncludedInCv({
                    id: props.historyObject.id,
                    checked: e.target.checked,
                    savedEmploymentHistory: props.savedEmploymentHistory,
                    setSavedEmploymentHistory: props.setSavedEmploymentHistory,
                    dispatch: dispatch,
                    setError: setError,
                    setRetry: (retryFunction) => {
                      setRetry(() => retryFunction());
                    },
                  });
                }}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Delete Summary" arrow>
              <IconButton
                onClick={(e) => {
                  props.handleDelete(props.historyObject.id);
                }}
              >
                <img src={binIcon} alt="bin icon" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <div ref={startYearRef} id="start-year-measure">
        {props.historyObject.startYear
          ? props.historyObject.startYear
          : "mm.yyyy"}
      </div>
      <div ref={endYearRef} id="end-year-measure">
        {props.historyObject.endYear ? props.historyObject.endYear : "mm.yyyy"}
      </div>
      <div ref={employerRef} id="employer-measure">
        {props.historyObject.employer
          ? props.historyObject.employer
          : "Employer"}
      </div>
      <div ref={locationRef} id="location-measure">
        {props.historyObject.location
          ? props.historyObject.location
          : "Location"}
      </div>
    </>
  );
}

export default Employer;
