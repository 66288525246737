import { Box } from "@mui/material";
import React from "react";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";

import moment from "moment";
import "./TimesheetDataView.css";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import LensIcon from "@mui/icons-material/Lens";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import {
  setDateValidationError,
  setDurationEditValidationError,
} from "../../../../features/timesheetSlice";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import fr from "date-fns/locale/fr";
import { parse } from "date-fns";
import { mappedWorksites } from "../../constants";
import { selectClassName } from "./helpers";
import StatusChip from "../LeaveRequest/StatusChip";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00-00-0000"
      inputRef={ref}
      onAccept={(value) => {
        onChange({ target: { name: props.name, value } });
      }}
      onBlur={() => {}}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const monthOverviewColumns = (
  data,
  time,
  setTime,
  dispatch,
  durationValidationError,
  dateValidationError
) => {
  const aggregateHours = () => {
    let totalHours = 0;
    let totalMinutes = 0;
    data.forEach((row) => {
      let durationArray = row.duration.split(":");
      totalHours += +durationArray[0];
      totalMinutes += +durationArray[1];
    });

    //tranform total minutes to hours
    let additionalHours = Math.floor(totalMinutes / 60);
    let remainingMinutes = totalMinutes - additionalHours * 60;

    // add additional hourse coming from total minutes
    totalHours += additionalHours;

    return `${String(totalHours).padStart(2, "0")}:${String(
      remainingMinutes
    ).padStart(2, "0")}`;
  };

  const aggregateOnsite = () => {
    let nrOfOnSites = 0;
    let totalEntries = data.length;
    data.forEach((row) => {
      if (row.site !== "Home") {
        nrOfOnSites += 1;
      }
    });
    return `${nrOfOnSites} / ${totalEntries}`;
  };

  const columnsDefinition = [
    {
      header: "Date",
      accessorKey: "date",
      size: 20,
      Edit: ({ cell, column, row, table }) => {
        const handleChange = (e) => {
          // check if error has already been set on date field
          if (dateValidationError) {
            // if date is now valid reset error
            if (moment(e.target.value, "DD-MM-YYYY", true).isValid()) {
              dispatch(setDateValidationError(false));
            }
          }
          row._valuesCache[column.id] = e.target.value;
        };

        return (
          <FormControl variant="standard">
            <InputLabel htmlFor="component-helper">Date</InputLabel>
            <Input
              value={row._valuesCache.date}
              error={dateValidationError}
              onBlur={(e) => {}}
              onChange={handleChange}
              name="textmask"
              id="formatted-text-mask-input"
              inputComponent={TextMaskCustom}
            />
            {dateValidationError && (
              <FormHelperText id="component-helper-text">
                Date must be in format dd-mm-yyyy
              </FormHelperText>
            )}
          </FormControl>
        );
      },
    },
    {
      header: "Status",
      accessorKey: "status",
      size: 20,
      enableEditing: false,
      Cell: ({ cell }) => {
        const is_edited=cell.row.original.is_edited
        const is_resubmitted=cell.row.original.is_resubmitted
        const rejection_comment = cell.row.original.status_comment
        return (
        <StatusChip
          status={cell.getValue()}
          className={`${selectClassName(cell.getValue())}`}
          is_edited={is_edited}
          is_resubmitted={is_resubmitted}
          rejection_comment={rejection_comment}
        />
      )},
    },
    {
      header: "Type",
      accessorKey: "activity_type",
      size: 20,
      enableEditing: true,
    },
    {
      header: "Activity",
      accessorKey: "activity_name",
      size: 50,
      enableEditing: true,
    },
    {
      header: "Duration",
      accessorKey: "duration",
      enableEditing: true,
      size: 50,
      Edit: ({ cell, column, row, table }) => {
        const handleDurationChange = (newValue) => {
          let formattedDuration = moment(newValue).format("HH:mm:ss");

          // check if error has already been set on duration field
          if (durationValidationError) {
            // if date is now valid reset error
            if (moment(formattedDuration, "HH:mm:ss", true).isValid()) {
              dispatch(setDurationEditValidationError(false));
            }
          }
          if (formattedDuration !== "Invalid date") {
            // if (newValue) {
            row._valuesCache = {
              ...row._valuesCache,
              duration: formattedDuration,
            };
          } else {
            row._valuesCache = {
              ...row._valuesCache,
              duration: null,
            };
          }
        };

        return (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
            <TimePicker
              label="Duration"
              value={
                row._valuesCache.duration
                  ? parse(row._valuesCache.duration, "HH:mm:ss", new Date())
                  : null
              }
              onChange={handleDurationChange}
              renderInput={(params) => (
                <FormControl variant="standard" {...params}>
                  <InputLabel htmlFor="component-helper">Duration</InputLabel>
                  <Input {...params} error={durationValidationError} />
                  {durationValidationError && (
                    <FormHelperText id="component-helper-text">
                      Duration must be in format hh:mm
                    </FormHelperText>
                  )}
                </FormControl>
              )}
              disableOpenPicker={true}
              views={["hours", "minutes"]}
              format="hh:mm"
            />
          </LocalizationProvider>
        );
      },
      aggregationFn: (columnName, groupedValues) => {
        //grouped values is an array that contains the row values for the aggregated groups; note that duration is in string format HH:MM:SS
        //to aggregate it we will transform it to seconds, add all numbers and transform back to the format HH:MM:SS
        let totalHours = 0;
        let totalMinutes = 0;
        groupedValues.forEach((row) => {
          let durationArray = row.original.duration.split(":");
          totalHours += +durationArray[0];
          totalMinutes += +durationArray[1];
        });

        //tranform total minutes to hours
        let additionalHours = Math.floor(totalMinutes / 60);
        let remainingMinutes = totalMinutes - additionalHours * 60;

        // add additional hourse coming from total minutes
        totalHours += additionalHours;

        return `${String(totalHours).padStart(2, "0")}:${String(
          remainingMinutes
        ).padStart(2, "0")}`;
      },
      AggregatedCell: ({ cell }) => {
        return (
          <div>
            {" "}
            <span className="aggregated-total">Total:</span> {cell.getValue()}
          </div>
        );
      },

      Cell: ({ cell }) => (
        <Box
          component="span"
          sx={(theme) => ({
            // color:
            //   compareTimeDeltas(cell.getValue(), "08:00:00") >= 0
            //     ? "#1B7656"
            //     : compareTimeDeltas(cell.getValue(), "04:00:00") >= 0 &&
            //       compareTimeDeltas(cell.getValue(), "046:00:00") <= 0
            //     ? "#FBBC04"
            //     : "#EA4335",
            borderRadius: "0.25rem",
            // color: '#fff',
            maxWidth: "9ch",
            p: "0.25rem",
            fontWeight: "bold",
          })}
        >
          {cell.getValue()?.slice(0, -3)}
        </Box>
      ),
      Footer: () => (
        <div className="footer-total">
          {" "}
          {/* <span className="aggregated-total footer-total">Total:</span>{" "} */}
          {aggregateHours()}
        </div>
      ),
    },
    {
      header: "Location",
      accessorKey: "site",
      enableEditing: true,
      size: 20,
      editVariant: "select",
      editSelectOptions: mappedWorksites,
    },

    {
      header: "On-Site",
      accessorKey: "billable",
      type: "boolean",
      enableColumnActions: true,

      enableSorting: false,
      enableEditing: false,

      editVariant: "select",
      size: 30,
      editSelectOptions: [
        { label: "True", value: true },
        { label: "False", value: false },
      ],
      aggregationFn: (columnName, groupedValues) => {
        let nrOfOnSites = 0;
        let totalEntries = groupedValues.length;
        groupedValues.forEach((row) => {
          if (row.original.site !== "Home") {
            nrOfOnSites += 1;
          }
        });
        return `${nrOfOnSites}/${totalEntries}`;
      },
      AggregatedCell: ({ cell }) => {
        return (
          <div>
            <span className="aggregated-total">On-site:</span> {cell.getValue()}
          </div>
        );
      },
      Cell: ({ cell, row }) => {
        return row._valuesCache["site"] !== "Home" ? (
          <LensIcon
            style={{ color: "var(--philico-blue)", marginLeft: "6px" }}
          />
        ) : (
          <PanoramaFishEyeIcon
            style={{ color: "var(--philico-blue)", marginLeft: "6px" }}
          />
        );
      },

      Footer: () => <div className="footer-total">{aggregateOnsite()}</div>,
    },
    {
      header: "Comment",
      accessorKey: "comment",
      enableEditing: true,
      enableColumnActions: false,
      enableSorting: false,
      muiEditTextFieldProps: ({ cell }) => ({
        onChange: (event) => {
          console.info(event);
        },
      }),
    },
  ];

  return columnsDefinition;
};
