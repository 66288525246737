import { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MaterialReactTable } from "material-react-table";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import "./TimesheetDataView.css";
import "./TimesheetDataView.css";

import { monthOverviewColumns } from "./ColumnsViews";
import { useTable } from "./tableUtils";
import { setInitialGrouping } from "../../../../features/timesheetSlice";

const ApprovalTimeSheetTable = ({
  employeeId,
  enableEditEntry,
  enableCopyEntry,
  enableDeleteEntry,
  timesheetData,
  monitorTimesheet,
  disableEditing,
  requestState,
  enableRowActions,
  handleSelectAll,
  chekedCheckBoxes,
  approveCallback,
  rejectCallback,
  reopenCallback,
  showIncludeCheckbox,
}) => {
  const [loading, setLoading] = useState(true);
  const [time, setTime] = useState("");
  const adminState = useSelector((state) => state.admin);
  const timesheetState = useSelector((state) => state.timesheet);

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(!loading);
  }, [timesheetData]);

  const data = useMemo(() => {
    return timesheetData;
  }, [loading]);

  const columns = useMemo(
    () =>
      monthOverviewColumns(
        data,
        time,
        setTime,
        dispatch,
        timesheetState.durationEditValidationError,
        timesheetState.dateEditValidationError
      ),
    [
      data,
      time,
      timesheetState.durationEditValidationError,
      timesheetState.dateEditValidationError,
      dispatch,
    ]
  );
  const isLoggedInEmployeeOwnerOfTimeSheet =
    adminState.loggedInUser === employeeId;

  const table = useTable({
    columns,
    data,
    monitorTimesheet,
    disableEditing,
    dispatch,
    groupingState: timesheetState.initialGrouping,
    timesheetState,
    showIncludeCheckbox: isLoggedInEmployeeOwnerOfTimeSheet
      ? false
      : showIncludeCheckbox,
    enableEditEntry,
    enableCopyEntry,
    enableDeleteEntry,
    enableRowActions: isLoggedInEmployeeOwnerOfTimeSheet
      ? false
      : enableRowActions,
  });

  useEffect(() => {
    // store grouping options in cache, so user does not have to reselect grouping every time
    dispatch(setInitialGrouping(table.options?.state?.grouping));
  }, [table.options?.state?.grouping]);
  return (
    <div style={{ width: "98%", margin: "0 auto" }}>
      <div className="leave-request-data-container">
        <div className="data-view-header">
          <div style={{ marginLeft: "12px" }}>
            <div className="table-title-leave-requests">{requestState}</div>
            {!isLoggedInEmployeeOwnerOfTimeSheet && (
              <FormControlLabel
                control={
                  <Checkbox
                    className="checkbox-admin-leave-request"
                    onChange={(e) => {
                      handleSelectAll(data);
                    }}
                    disabled={timesheetData && timesheetData.length === 0}
                    checked={
                      data &&
                      chekedCheckBoxes &&
                      data.length > 0 &&
                      data.length === chekedCheckBoxes.length
                    }
                  />
                }
                label="Select all"
              />
            )}
          </div>
          {!isLoggedInEmployeeOwnerOfTimeSheet && (
            <div
              className="approve-reject-button-group"
              style={{ marginRight: "12px" }}
            >
              {requestState === "Pending" && (
                <>
                  <Button onClick={() => approveCallback()}>Approve</Button>
                  <Button onClick={() => rejectCallback()}>Reject</Button>
                </>
              )}
              {(requestState === "Approved" || requestState === "Rejected") && (
                <Button onClick={() => reopenCallback()}>Reopen</Button>
              )}
            </div>
          )}
        </div>
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
};

export default ApprovalTimeSheetTable;
