import { useMemo, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { useSelector, useDispatch } from "react-redux";
import { monthOverviewColumns } from "./ColumnsViews";
import { useTable } from "./tableUtils";
import { setInitialGrouping } from "../../../../features/timesheetSlice";
import "./TimesheetDataView.css";

const EmployeeTimeSheetTable = ({
  timesheetData,
  updateTimeSheetPage,
  enableRowActions,
  showIncludeCheckbox,
  disableEditing,
  monitorTimesheet
}) => {
  const timesheetState = useSelector((state) => state.timesheet);
  const dispatch = useDispatch();

  const columns = useMemo(() => {
    return monthOverviewColumns(
      timesheetData,
      timesheetState.timeEditValue,
      (time) => dispatch({ type: "SET_TIME", payload: time }),
      dispatch,
      timesheetState.durationEditValidationError,
      timesheetState.dateEditValidationError
    );
  }, [
    timesheetData,
    timesheetState.timeEditValue,
    timesheetState.durationEditValidationError,
    timesheetState.dateEditValidationError,
    dispatch,
  ]);

  const table = useTable({
    columns,
    data: timesheetData,
    updateTimeSheetPage,
    disableEditing,
    dispatch,
    groupingState: timesheetState.initialGrouping,
    timesheetState,
    enableRowActions,
    showIncludeCheckbox,
  });

  useEffect(() => {
    const currentGrouping = table.options?.state?.grouping;
    if (currentGrouping !== timesheetState.initialGrouping) {
      dispatch(setInitialGrouping(currentGrouping));
    }
  }, [
    table.options?.state?.grouping,
    timesheetState.initialGrouping,
    monitorTimesheet,
    dispatch,
  ]);

  return (
    <div style={{ width: "98%", margin: "0 auto" }}>
      <div className="leave-request-data-container">
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
};

export default EmployeeTimeSheetTable;
