import React from "react";
import Button from "@mui/material/Button";
import GoogleLogo from "../../assets/google_logo_no_background.png";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { changeImagePath } from "../../features/profileinfoSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changePage } from "../../features/navigationSlice";
import axiosInstance from "../../axios/axiosConfig";
import axiosInstanceGoogleAuth from "../../axios/axiosConfigGoogleAuth";
import { persistor } from "../../app/store";
import { initalizeLayout as initializeLayoutPage1 } from "../../features/profileinfoSlice";
import { initializeLayout as initializeLayoutpage2 } from "../../features/personalinfoSlice";
import { initializeLayout as initializeLayoutPage3 } from "../../features/internalinfoSlice";
import { initalizeLayout as initializeLayoutpage4 } from "../../features/educationSlice";
import { initalizeLayout as initializeLayoutpage5 } from "../../features/experienceSlice";
import { initializeLayout as initializeLayoutpage6 } from "../../features/descriptionSlice";
import { initializeCardLayouts } from "../../components/CardsLayoutsHelperFunctions";
import {
  setAlertMessage,
  setIsFailAlertOpen,
} from "../../features/alertMessageSlice";
import { loginFail } from "../../components/AlertMessages";
import { resetReduxState } from "../../features/resetState";
import {
  initalizeActiveUsers,
  initalizeAdmin,
  initalizeInactiveUsers,
  initalizeManagerUsers,
  initalizeUserRoles,
  initalizeUsersComplete,
  initalizeUsersId,
  initalizeUsersName,
  changeActiveUser
} from "../../features/adminSlice";
const drfClientId = process.env.REACT_APP_CLIENT_ID;
const drfClientSecret = process.env.REACT_APP_CLIENT_SECRET;

function GoogleLogin(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      // reset redux toolkit state
      dispatch(resetReduxState());

      // open the backdrop that indicates loading
      props.handleBackdropOpen();
      // start persisting the redux store if not already done
      if (!localStorage.getItem("persist:root")) {
        persistor.persist();
      }

      // first we remove the old access_tokens + active user
      localStorage.removeItem("access_token");
      localStorage.removeItem("activeUser");

      var loginSuccess = false;

      try {
        // make request to the authentication endpoint
        var djangoResponse = await axiosInstanceGoogleAuth.post(
          "/auth/convert-token/",
          {
            token: response.access_token,
            backend: "google-oauth2",
            grant_type: "convert_token",
            client_id: drfClientId,
            client_secret: drfClientSecret,
          }
        );

        // set starttime to measure when token expires
        localStorage.setItem("startTime", new Date());

        // store time (in seconds), when token expires
        localStorage.setItem(
          "token_expiration",
          djangoResponse.data.expires_in
        );

        // store django access token in local stoage for future requests
        localStorage.setItem(
          "refresh_token",
          djangoResponse.data.refresh_token
        );

        // store django access token in local stoage for future requests
        localStorage.setItem("access_token", djangoResponse.data.access_token);
        loginSuccess = true;

        // create employee profile by sending first PUT request
        var employeeCreationResponse = await axiosInstance.put(
          "/cvapp/employee-profile/",
          {
            isActive: true,
          }
        );
      } catch (err) {
        console.log("error access token update. ", err);

        // open error message
        dispatch(setIsFailAlertOpen(true));

        // set error message
        dispatch(setAlertMessage(loginFail));

        // close the backdrop if the request above failed
        props.handleBackdropClose();
        loginSuccess = false;
        console.log("error in authentication and profile creation: ", err);
      }

      // try to get first user information from google profile
      try {
        // create config header to make request to google endpoint
        const configHeader = {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        };

        var responseGoogle = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          configHeader
        );

        // initalize object to store body for possible requests
        let employeeInfo = {};

        // make request to employee table to see if information is already sent (we do not want to override set information with google profile information)
        var responseDjango = await axiosInstance.get(
          "/cvapp/employee-profile/"
        );
        axiosInstance.get("/base/users").then((response) => {
          // dispatch(initalizeAdmin(response.data));
          localStorage.setItem("loggedInUser", response.data.id);
          dispatch(changeActiveUser(response.data.id));
        });

        // get active and inactive users
        await axiosInstance
          .get("/cvapp/active-users/", {
            params: {
              userType: "active",
            },
          })
          .then((response) => {
            dispatch(initalizeActiveUsers(response.data));
          })
          .catch((error) => {
            console.log("error GET /cvapp/active-users/: ", error);
          });

        await axiosInstance
          .get("/cvapp/active-users/", {
            params: {
              userType: "inactive",
            },
          })
          .then((response) => {
            dispatch(initalizeInactiveUsers(response.data));
          })
          .catch((error) => {
            console.log("error GET /cvapp/active-users/: ", error);
          });



        // check if first name is already present in db
        if (!responseDjango.data.firstName) {
          // check if google response has first name set
          if (responseGoogle.data.hasOwnProperty("given_name")) {
            // check if given_name property is not empty string, undefined, ...
            if (responseGoogle.data.given_name) {
              employeeInfo["firstName"] = responseGoogle.data.given_name;
            }
          }
        }

        // check if last name is already present in db
        if (!responseDjango.data.lastName) {
          if (responseGoogle.data.hasOwnProperty("family_name")) {
            // check if family name is not null
            if (responseGoogle.data.family_name) {
              employeeInfo["lastName"] = responseGoogle.data.family_name;
            }
          }
        }

        // check if email is already present in db
        if (!responseDjango.data.email) {
          // check if email is present on google profile
          if (responseGoogle.data.hasOwnProperty("email")) {
            // check if family name is not null
            if (responseGoogle.data.email !== "") {
              employeeInfo["email"] = responseGoogle.data.email;
            }
          }
        }

        // check if image is already present in db
        if (!responseDjango.data.imageURL) {
          // check if picture property is set on google response
          if (responseGoogle.data.hasOwnProperty("picture")) {
            // check if family name is not null
            if (responseGoogle.data.picture !== "") {
              dispatch(changeImagePath(responseGoogle.data.picture));

              employeeInfo["imageURL"] = responseGoogle.data.picture;
            }
          }
        } else {
          dispatch(changeImagePath(responseDjango.data.imageURL));
        }

        // check if object is empty; this can happen if all information is already present; if not we will send a put request
        if (Object.keys(employeeInfo).length !== 0) {
          axiosInstance
            .put("/cvapp/employee-profile/", employeeInfo)
            .then((response) => {
              // close the backdrop if the request above failed
              props.handleBackdropClose();
              // change navigation state to 0 (corresponds to profile page)
              dispatch(changePage(0));
              // navigate to profile page
              navigate("/timemate");
            })
            .catch((error) => {
              console.log("error profile update. ", error);

              // open error message
              dispatch(setIsFailAlertOpen(true));

              // set error message
              dispatch(setAlertMessage(loginFail));

              console.log("error in writing employeeinfo: ", error);
            });
        } else if (loginSuccess) {
          // close the backdrop if the request above failed
          props.handleBackdropClose();
          // change navigation state to 0 (corresponds to profile page)
          dispatch(changePage(0));
          // navigate to profile page
          navigate("/timemate");
        }
      } catch (err) {
        console.log("error in google profile request: ", err);
      }

      try {
        // get card layouts from db
        var responseCardLayouts = await axiosInstance.get(
          "/cvapp/cards-layout/"
        );

        let extractedLayoutPage1 = initializeCardLayouts(
          responseCardLayouts.data,
          ["contactCard", "addressCard"]
        );

        let extractedLayoutPage2 = initializeCardLayouts(
          responseCardLayouts.data,
          ["relationshipCard", "languageSkillsCard"]
        );

        let extractedLayoutPage3 = initializeCardLayouts(
          responseCardLayouts.data,
          [
            "idDocumentCard",
            "bankInfoCard",
            "relationshipCard",
            "contractInfoCard",
          ]
        );

        let extractedLayoutPage4 = initializeCardLayouts(
          responseCardLayouts.data,
          ["educationCard"]
        );

        let extractedLayoutPage5 = initializeCardLayouts(
          responseCardLayouts.data,
          ["experienceCard"]
        );

        let extractedLayoutPage6 = initializeCardLayouts(
          responseCardLayouts.data,
          ["descriptionCard"]
        );

        dispatch(initializeLayoutPage1(extractedLayoutPage1));
        dispatch(initializeLayoutpage2(extractedLayoutPage2));
        dispatch(initializeLayoutPage3(extractedLayoutPage3));
        dispatch(initializeLayoutpage4(extractedLayoutPage4));
        dispatch(initializeLayoutpage5(extractedLayoutPage5));
        dispatch(initializeLayoutpage6(extractedLayoutPage6));
      } catch (err) {
        console.log("error in fetching card layouts");
      }

      // fetch all user roles and access data
      try {
        axiosInstance.get("/base/users").then((response) => {
          dispatch(initalizeAdmin(response.data));
        });

        axiosInstance.get("/cvapp/all-employee-names").then((response) => {
          const userNames = [];
          const userIds = [];

          dispatch(initalizeUsersComplete(response.data));

          response.data.map((user) => {
            userNames.push(user.name);
            userIds.push(user.id);
          });

          dispatch(initalizeUsersName(userNames));
          dispatch(initalizeUsersId(userIds));
        });

        // get employees and their user roles
        axiosInstance
          .get("/cvapp/user-roles/")
          .then((response) => {
            dispatch(initalizeUserRoles(response.data));

            var managers = [];

            // iterate through data to find manager user roles
            response.data.map((user) => {
              if (user.userRole.includes("Manager")) {
                managers.push({ id: user.id, name: user.name });
              }
            });

            dispatch(initalizeManagerUsers(managers));
          })
          .catch((error) => {
            console.log("error GET /cvapp/user-roles/: ", error);
          });
      } catch (err) {
        console.log("error fetch user data login: ", err);
      }
    },
    onError: (error) => {
      console.log("error onError: ", error);

      // open error message
      dispatch(setIsFailAlertOpen(true));

      // set error message
      dispatch(setAlertMessage(loginFail));

      // close the backdrop if the request above failed
      // props.handleBackdropClose();
      console.log("login failed");
    },
  });

  return (
    <div>
      <div style={{ marginTop: "30px" }}>
        <Button
          variant="contained"
          onClick={login}
          startIcon={
            <img
              src={GoogleLogo}
              alt="Google Logo"
              className="google-login-button-logo"
            />
          }
          style={{ fontFamily: "Montserrat" }}>
          Sign in with Google
        </Button>
      </div>
    </div>
  );
}

export default GoogleLogin;
