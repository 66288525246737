import { useEffect, useState, useCallback, useMemo } from "react";
import { ButtonGroup, Button, Grid, Tooltip } from "@mui/material";
import ForwardIcon from "@mui/icons-material/Forward";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";

import "./HistoryCard.css";

import { setSelectedDates } from "../../../../features/timesheetSlice";
import { getCurrentAndPastThreeMonths, getMonthName } from "./helpers";
import { getMonthStatus, updateMonthStatus } from "../../helpers";
import SubmitDialog from "./SubmitDialog";

const useStyles = makeStyles(() => ({
  buttonBase: {
    fontWeight: "bold",
    color: "white",
  },
  pendingButton: {
    backgroundColor: "var(--pending-color) !important",
  },
  submittedButton: {
    backgroundColor: "var(--submitted-color) !important",
  },
  approvedButton: {
    backgroundColor: "var(--approved-color) !important",
  },
  rejectedButton: {
    backgroundColor: "var(--rejected-color) !important",
  },
  editedButton: {
    backgroundColor: "var(--edited-color) !important",
  },
}));

const TimesheetHistoryCard = ({
  selectedMonth,
  setSelectedMonth,
  updateTimeSheetPage,
  monitorTimesheet,
}) => {
  const adminState = useSelector((state) => state.admin);
  const [yearMonthStatus, setYearMonthStatus] = useState([]);
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const [selectedMonthToSubmit, setSelectedMonthToSubmit] = useState(null);

  const dispatch = useDispatch();
  const classes = useStyles();

  const yearAndMonths = useMemo(() => getCurrentAndPastThreeMonths(), []);

  const fetchStatuses = useCallback(async () => {
    try {
      const results = await Promise.all(
        yearAndMonths.map(async ({ month, year }) => {
          try {
            const response = await getMonthStatus({ month, year });
            return { month, year, status: response?.month_status || null };
          } catch {
            return { month, year, status: null, error: true };
          }
        })
      );
      setYearMonthStatus(results);
    } catch (error) {
      console.error("Error fetching month statuses:", error);
      setYearMonthStatus([]);
    }
  }, [yearAndMonths]);

  useEffect(() => {
    dispatch(setSelectedDates([null, null]));
  }, [selectedMonth, dispatch]);

  useEffect(() => {
    fetchStatuses();
  }, [adminState.activeUser, fetchStatuses, monitorTimesheet]);

  const submitCallback = useCallback(async () => {
    if (!selectedMonthToSubmit) return;

    try {
      await updateMonthStatus({
        year: selectedMonthToSubmit.year,
        month: selectedMonthToSubmit.month,
        status: "Submitted",
        employeeId: adminState.activeUser,
        updateTimeSheetPage,
      });

      setOpenSubmitDialog(false);
      fetchStatuses();
    } catch (error) {
      console.error("Error submitting timesheet:", error);
    }
  }, [
    selectedMonthToSubmit,
    adminState.activeUser,
    updateTimeSheetPage,
    fetchStatuses,
  ]);

  const navigateToMonth = useCallback(
    (month, year) => {
      setSelectedMonth(new Date(year, month - 1, 1));
    },
    [setSelectedMonth]
  );

  const getButtonClass = useCallback(
    (status) => {
      const statusClasses = {
        Pending: classes.pendingButton,
        Submitted: classes.submittedButton,
        Approved: classes.approvedButton,
        Rejected: classes.rejectedButton,
        Edited: classes.editedButton,
      };
      return `${classes.buttonBase} ${statusClasses[status] || ""}`;
    },
    [classes]
  );

  return (
    <div className="timesheet-history-card">
      <div className="timesheet-history-card-title">History</div>
      <Grid container columnSpacing={2} rowSpacing={4}>
        {yearMonthStatus.map(({ month, year, status }, index) => (
          <Grid item xs={12} key={index}>
            <ButtonGroup fullWidth variant="contained">
              <Button
                className={getButtonClass(status)}
                sx={{ flex: 1 }}
                onClick={() => navigateToMonth(month, year)}
              >
                {getMonthName(month)}
              </Button>
              {(status === "Pending" ||
                status === "Rejected" ||
                status === "Edited") && (
                <Tooltip
                  title="Submit"
                  PopperProps={{
                    modifiers: [
                      { name: "offset", options: { offset: [60, -50] } },
                    ],
                  }}
                >
                  <Button
                    className={getButtonClass(status)}
                    sx={{ width: "25%" }}
                    onClick={() => {
                      setOpenSubmitDialog(true);
                      setSelectedMonthToSubmit({ month, year });
                    }}
                  >
                    <ForwardIcon />
                  </Button>
                </Tooltip>
              )}
            </ButtonGroup>
          </Grid>
        ))}
      </Grid>
      <SubmitDialog
        open={openSubmitDialog}
        handleClose={() => setOpenSubmitDialog(false)}
        title="Submit Timesheet?"
        body="All your time entries, including any rejected ones, will be submitted. 
        Please ensure all necessary changes have been made before proceeding. 
        Once submitted, your approver will be notified, and you will no longer be able to make any adjustments."
        handleSubmit={submitCallback}
      />
    </div>
  );
};

export default TimesheetHistoryCard;
