import React, { useLayoutEffect, useState } from "react";
import Button from "@mui/material/Button";
import AddIconButton from "../../../../components/input_components/AddIconButton";
import { useSelector, useDispatch } from "react-redux";
import axiosInstance from "../../../../axios/axiosConfig";
import Degree from "./Degree";
import { addDegree, initalizeDegrees } from "../../../../features/cvDegrees";
import { temporarilyMarkAsSaved } from "../../utils";

function DynamicCvDegrees() {
  const degreesState = useSelector((state) => state.degrees);
const [savedDegrees, setSavedDegrees] = useState([]);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    axiosInstance.get("/cvapp/degrees/").then((response) => {
      dispatch(initalizeDegrees(response.data));
    });
  }, []);

  const handleDelete = (id) => {
    axiosInstance
      .delete("/cvapp/degrees/", {
        data: {
          id: id,
        },
      })
      .then((response) => {
        dispatch(initalizeDegrees(response.data));
      })
      .catch((error) => {
        console.log("error deleting experience");
      });
  };

  return (
    <div className="bounding-box-degree-section">
      <div className="cv-project-experience-section-grid-wrapper">
        <div className="left-col-section-title">DEGREES</div>
        <div>
          {degreesState.degrees.map((degree) => {
            return (
              <Degree
                key={degree.id}
                degreeObject={degree}
                handleDelete={handleDelete}
                savedDegrees={savedDegrees}
                setSavedDegrees={setSavedDegrees}
                isSaved={savedDegrees.includes(degree.id)}
              />
            );
          })}
          <div>
            <Button
              variant="contained"
              startIcon={<AddIconButton />}
              className="dynamic-cv"
              onClick={(e) => {
                axiosInstance
                  .post("/cvapp/degrees/", {
                    school: "",
                    schoolLocation: "",
                    degree: "",
                    fieldOfStudy: "",
                    // startDate: null,
                    // endDate: null,
                  })
                  .then((response) => {
                    console.log("response post request: ", response.data);
                    dispatch(addDegree(response.data));
                    temporarilyMarkAsSaved(response.data.id, savedDegrees, setSavedDegrees)(null)
                  })
                  .catch((error) => {
                    console.log("error adding degree");
                  });
              }}>
              degree
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DynamicCvDegrees;
