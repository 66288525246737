import React from "react";
import Dialog from "@mui/material/Dialog";
import { Button } from "@mui/material";

function SubmitDialog(props) {
  const { open, handleClose, title, body, handleSubmit, month, year } = props;
  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="dialog-reset-layout-wrapper">
        <div className="dialog-reset-layouts-title">{title}</div>
        <div className="dialog-reset-layouts-subtitle">{body}</div>
        <div
          style={{
            marginTop: "12px",
            float: "right",
            display: "flex",
            gap: "1rem",
          }}
        >
          <Button onClick={() => handleClose()} variant="contained">
            CANCEL
          </Button>
          <Button
            onClick={() => handleSubmit({ month, year })}
            variant="contained"
          >
            SUBMIT
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default SubmitDialog;
